import React from "react";

import {
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    Button,
} from '@mui/material';

export interface messageDialogProps {
    props: {
        open: boolean,
        handleModal: any,
        body: string,
        hasButton: boolean,
        buttonBody: string,
    }
}


const MessageDialog: React.FC<messageDialogProps> = ({ props }) => {
    const [open, setOpen] = React.useState(false);
    const [hasDisplay, setHasDisplay] = React.useState('none');

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
        }
    }, [props.open])

    React.useEffect(() => {
        if (props.hasButton) {
            setHasDisplay('block');
        } else {
            setHasDisplay('none');
        }
    }, [props.hasButton])

    const handleClose = () => {
        setOpen(false)
        props.handleModal()
    }

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" sx={{ whiteSpace: 'pre-wrap' }}>{props.body}</DialogContentText>
                    <Box sx={{ display: hasDisplay, textAlign: 'center', margin: '20px 10px' }}>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={handleClose}
                        >{props.buttonBody}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default MessageDialog;


