import * as React from 'react';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { apiRequest } from '../../../api/ApiCall';
import { userLogging } from "../../../utility/firestore";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { LOCAL_CONSTANT } from '../../Const';
import MessageDialog from '../../components/MessageDialog';
import { setOtherDocumentPdfPath } from '../../../features/longPageSlice';
import { setOtherDocumentPdfPreview } from '../../../features/otherDocumentSlice';
import {
    deleteUploadFile,
    getFileMetadata,
    uploadFile,
    getFileData
} from '../../../utility/storage';
import PdfViewer from '../../../utility/PdfViewer';
import { useUtilityContext } from '../../../utility-provider';
import {
    uploadFileJsonPathFunc,
    upsertLocationBaseFileParam,
    upsertLocationBaseDeleteFileParam
} from '../../components/LocationBaseFunc';
import { useUserContext } from '../../../user-provider';
import { DeleteConfirmationModal } from '../../ResearchReport/DeleteConfirmationModal';
import { staffName } from '../../Common';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DeficiencyCommentRegistModal from '../../components/DeficiencyCommentRegistModal';
import {
    additionalResearchStringTypeList,
    initAdditionalResearchCommentTextFieldObj
} from '../../../data/type';
import { setPermissionFunc } from '../../components/PermissionFunc';
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    iconHover
} from '../../components/ChatBubbleAndEditIconStyle';
import dayjs from 'dayjs';

interface OtherPdfProps {
    props: {
    }
};

const headerCell = {
    backgroundColor: '#D9D9D9',
    fontSize: '12px',
    color: 'black',
    borderBottom: '1px solid black',
    fontWeight: 'Bold'
};


const OtherPdf: React.FC<OtherPdfProps> = ({ props }) => {

    //候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //調査書基本を取得
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    const dispatch = useAppDispatch();

    const utilityCtx = useUtilityContext();

    //ファイルアップロード
    const [message, setMessage] = useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);
    const [hasButton, setHasButton] = useState<boolean>(false);

    //ユーザーIDを取得
    const [userId, setUserId] = useState<string>("");
    //ユーザー種別を取得
    const [userType, setUserType] = useState<string>("");
    const user = useUserContext();

    //プレビュー
    const previewPdfPath = useAppSelector((state) => state.otherDocument.pdfPath);

    //モーダル開閉フラグ(削除確認)
    const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
    const [path, setPath] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const [date, setDate] = useState<string>("");

    //不備コメントモーダル内、TextFiledのコメント
    const [additionalResearchCommentTextFiledObj, setAdditionalResearchCommentTextFiledObj] = useState<additionalResearchStringTypeList>(initAdditionalResearchCommentTextFieldObj);
    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);

    const handleDeleteConfModalOpen = (pathParam: string, nameParam: string, dateParam: string) => {
        setDeleteConfModalOpen(true);
        setPath(pathParam);
        setFileName(nameParam);
        setDate(dateParam);
    }

    //プレビューを表示
    const handlePreviewChange = (pdfPath: string) => {
        const path = 'gs://'
            + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
            + '/'
            + objLongPageParam.planAreaId
            + '/'
            + objLongPageParam.branch
            + pdfPath
        if (previewPdfPath === path) {
            dispatch(setOtherDocumentPdfPreview(""));
        } else {
            dispatch(setOtherDocumentPdfPreview(path));
        }
    };

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    const handleClickFile = () => {
        const fileElem = document.getElementById("otherPdfFileElem");
        if (fileElem) {
            fileElem.click();
        }
    };

    const handleChangeFile = (newFile: any) => {
        fileUpload(newFile.target.files[0]);
        newFile.target.value = '';
    };

    //ファイル保存
    const fileDownload = (path: string, fileName: string) => {
        const filePath = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + path;
        (async () => {
            try {
                const url = await getFileData(filePath);
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    const link = document.createElement("a");
                    document.body.appendChild(link);
                    link.download = fileName;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    document.body.removeChild(link);
                };
                xhr.open('GET', url as string);
                xhr.send();
            } catch (e) {
                console.log(e);
            }
        })();
    }

    //ファイルアップロード
    const fileUpload = (file: any) => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner()
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_FILE);
                setMessageDialogOpen(true);
                const bucketPath = objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/pdf/'
                let uploadFileName = file.name
                const url = await getFileData(bucketPath + file.name);
                if (url) {
                    // 同ファイル名がパス先に存在する場合は
                    // ファイル名 + YYYYMMDDHHmmss + 拡張子でアップロード
                    const position = file.name.lastIndexOf(".")
                    uploadFileName = file.name.slice(0, position) + "_" + dayjs().format("YYYYMMDDHHmmss") + file.name.slice(position)
                }
                await uploadFile(file, bucketPath + uploadFileName);
                const uploadResultFile = await getFileMetadata(bucketPath + uploadFileName);
                const newUploadFile = uploadFileJsonPathFunc(uploadResultFile, userId);
                //DB保存
                const params = upsertLocationBaseFileParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch, newUploadFile, 'other_document_pdf_path', userId, uploadFileName)
                updateRegist(params, LOCAL_CONSTANT.FUNCTION_NAME.OTHER_PDF_UPLOAD);
                //Redux保存
                dispatch(setOtherDocumentPdfPath([...objLocationBase.other_document_pdf_path, newUploadFile]));
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD_FILE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_UPLOAD_FILE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    //ファイル削除
    const handlePdfDeleteChange = () => {
        const newListUploadFile = objLocationBase.other_document_pdf_path.filter(row => row.file_path !== path && row.upload_date !== date) //選択したpathとupload_date以外を返す
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE);
                setMessageDialogOpen(true);
                //CloudStorageから削除対象のファイル情報を取得
                const deleteFile = await getFileMetadata('/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + path);
                await deleteUploadFile(deleteFile, '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + path);
                //DB保存
                const params = upsertLocationBaseDeleteFileParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch, newListUploadFile, 'other_document_pdf_path', fileName)
                updateRegist(params, LOCAL_CONSTANT.FUNCTION_NAME.OTHER_PDF_DELETE);
                //Redux保存
                dispatch(setOtherDocumentPdfPath([...newListUploadFile]));
                //プレビューを戻す
                dispatch(setOtherDocumentPdfPreview(""));
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE)
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE)
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    const updateRegist = (params: any, function_name: string) => {
        userLogging(
            function_name,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    // 吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (labelName: string) => {
        setColumnNameStr(labelName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setAdditionalResearchCommentTextFiledObj({ ...additionalResearchCommentTextFiledObj, [labelName]: "" }); //テキストフィールドのコメントを初期化
    };

    // モーダル内でのコメント取得
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        setAdditionalResearchCommentTextFiledObj({
            ...additionalResearchCommentTextFiledObj,
            [labelName]: e.target.value,
        });
    };

    // モーダル内の保存ボタンをクリックしたとき不備コメントを保存
    const handleSaveComment = (labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_base",
                column_name: labelName,
                comment: additionalResearchCommentTextFiledObj[labelName],
            }
        };
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.OTHER_PDF_COMMENT,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            }
        })();
    };

    useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            //ユーザーIDを取得
            setUserId(JSON.parse(user.userData || 'null').staff_number);
            //ユーザー種別を取得
            setUserType(JSON.parse(user.userData || 'null').user_type);

        }
    }, [user]);

    return (
        <Box>

            <Box sx={{ display: 'flex' }}>
                <MessageDialog props={{
                    open: messageDialogOpen,
                    handleModal: closeMessageDialog,
                    body: message,
                    hasButton: hasButton,
                    buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
                }}
                />
                <input
                    id="otherPdfFileElem"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleChangeFile}
                    accept=".pdf"
                />
                <Button
                    sx={{ margin: '20px 0' }}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={handleClickFile}
                >その他資料pdf
                </Button>
                {/* 不備コメント、吹き出しアイコンボタン (リサーチのみ表示)*/}
                {userType === 'z001' &&
                    <Grid sx={{ display: 'flex', width: '100px' }}>
                        <IconButton
                            disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.OTHER_DOCUMENT, 'other_document_pdf')}
                            onClick={() => { handleChatBubbleClick('other_document_pdf') }}
                            sx={iconHover}
                        >
                            <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.OTHER_DOCUMENT, 'other_document_pdf'))}>
                                <ChatBubbleIcon sx={chatBubbleIconSx} />
                            </Box>
                        </IconButton>
                    </Grid>
                }
            </Box>

            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ paddingRight: "40px" }}>
                    <TableContainer sx={{ height: "424px", width: "100%", border: '1px solid black' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                                    <TableCell sx={headerCell}></TableCell>
                                    <TableCell sx={headerCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {objLocationBase.other_document_pdf_path.map((data, index) => (
                                    <TableRow className="tableRow" key={index}>
                                        <TableCell onClick={() => handlePreviewChange(data.file_path)}>{data.file_name}</TableCell>
                                        <TableCell onClick={() => handlePreviewChange(data.file_path)}>{data.upload_date}</TableCell>
                                        <TableCell onClick={() => handlePreviewChange(data.file_path)}>{staffName(data.upload_staff_number)}</TableCell>
                                        <TableCell>
                                            <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(data.file_path, data.file_name)}><DownloadIcon /></IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton sx={{ color: 'black' }} onClick={() => handleDeleteConfModalOpen(data.file_path, data.file_name, data.upload_date)}><DeleteIcon /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {/* プレビュー */}
            {previewPdfPath !== "" && (
                <Box sx={{ paddingTop: "30px" }}>
                    <PdfViewer props={{ pdfPath: previewPdfPath }} />
                </Box>
            )}

            {/* 削除確認モーダル */}
            <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handlePdfDeleteChange} />

            {/* 不備コメント モーダル画面 */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj: additionalResearchCommentTextFiledObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment,
                }} />

        </Box>
    )

}

export default OtherPdf;