import { createSlice } from '@reduxjs/toolkit';
import {
    initTradeAreaTaxAuditObj,
    initTradeAreaCar5MinMpObj,
    initTradeAreaMpDataTypeList,
    initTradeAreaMpDataTableTypeList
} from '../data/type';


export const tradeAreaSlice = createSlice({
    name: 'tradeArea',
    initialState: {
        taxAuditObj: initTradeAreaTaxAuditObj,
        car5MinMpObj: initTradeAreaCar5MinMpObj,
        initMpDataList: initTradeAreaMpDataTypeList,
        mpDataList: initTradeAreaMpDataTableTypeList
    },
    reducers: {
        setTradeAreaTaxAudit: (state, action) => {
            state.taxAuditObj = action.payload;
        },
        setTradeAreaCar5MinMp: (state, action) => {
            state.car5MinMpObj = action.payload;
        },
        setTradeAreaInitMpData: (state, action) => { 
            state.initMpDataList = action.payload
        },
        setTradeAreaMpData: (state, action) => {
            state.mpDataList = action.payload;
        },

        updateCell: (state, action) => {
            const objIndex = action.payload.id;
            state.mpDataList[objIndex] = {
                ...state.mpDataList[objIndex],
                    householdCount: action.payload.householdCount,
                    reality_survey_household_count: action.payload.reality_survey_household_count,
                    singleHousehold: action.payload.singleHousehold,
                    population: action.payload.population,
                    ageGroup20: action.payload.ageGroup20,
                    ageGroup30: action.payload.ageGroup30,
                    ageGroup50: action.payload.ageGroup50,
                    ageGroup65More: action.payload.ageGroup65More,
                    office: action.payload.office,
                    employees: action.payload.employees,         
                    reality_survey_office: action.payload.reality_survey_office,
                    reality_survey_employed_workers: action.payload.reality_survey_employed_workers
            };
        }
    }
});

export const {
    setTradeAreaTaxAudit,
    setTradeAreaCar5MinMp,
    setTradeAreaInitMpData,
    setTradeAreaMpData,
    updateCell,
} = tradeAreaSlice.actions;
export default tradeAreaSlice.reducer;