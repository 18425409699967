
/**
 * UnixTimeの数値を返す
 * @returns {number} UNIXTIME  例:1639381897
 */
export const getUnixTime = () => {
    // UNIXタイムスタンプを取得する (ミリ秒単位)
    const unixTime = new Date().getTime();

    // UNIXタイムスタンプを取得する (秒単位 - PHPのtime()と同じ)
    return Math.floor(unixTime / 1000);
};


// デバイス・OSの判定を行う
export const setDeviceType = () => {
    const ua = navigator.userAgent;
    const loerUa = navigator.userAgent.toLowerCase();
    let deviceName = "";
    let isDeviceType = true;
    if (ua.indexOf("iPhone") > -1) {
        deviceName = "iphone";
    } else if (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1) {
        deviceName = "android";
    } else if (ua.indexOf("iPad") > -1) {
        deviceName = "ipad";
    } else if (/ipad|macintosh/.test(loerUa) && "ontouchend" in document) {
        // OS判定がMacOSX向けになっているもの
        deviceName = "ipad";
    } else if (ua.indexOf("Android") > -1) {
        deviceName = "android";
    } else {
        isDeviceType = false;
        deviceName = "pc";
    }

    return deviceName + ':' + getDeviceAngle(isDeviceType);
};

// デバイスの向きを設定する
export const getDeviceAngle = (isDeviceType: boolean) => {
    let deviceAngle = '横';

    if (isDeviceType) {
        deviceAngle = "縦";
    }
    return deviceAngle;
};

// ブラウザ情報を取得する
export const getBrowser = () => {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf("msie") !== -1 || agent.indexOf("trident") !== -1) {
        return "Internet Explorer";
    } else if (agent.indexOf("edg") !== -1 || agent.indexOf("edge") !== -1) {
        return "Edge";
    } else if (agent.indexOf("opr") !== -1 || agent.indexOf("opera") !== -1) {
        return "Opera";
    } else if (agent.indexOf("chrome") !== -1) {
        return "Chrome";
    } else if (agent.indexOf("safari") !== -1) {
        return "Safari";
    } else if (agent.indexOf("firefox") !== -1) {
        return "FireFox";
    } else if (agent.indexOf("opr") !== -1 || agent.indexOf("opera") !== -1) {
        return "Opera";
    }
    return "ブラウザ取得不可";
};