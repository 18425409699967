import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    setSaveStatus,
    setDuplicateStatus,
} from "../../features/longPageSlice";
import { upsertlocationBaseParam } from "../components/LocationBaseFunc";
import { upsertLocationAddSurvey } from "../components/LocationAddSurveyFunc";
import { upsertCoverParam } from "../subPage/Cover/CoverFunction";
import { upsertStoreOpeningConditionsParam } from "../subPage/StoreOpeningConditions/StoreOpeningConditionsFunction";
import { upsertTradeAreaParam } from "../subPage/TradeArea/TradeAreaFunction";
import { upsertPhotoParam } from "../subPage/Pictuer/PhotoFunction";
import {
    upsertLocationTraffic5mcutParam,
    setParam,
} from "../subPage/TrafficVolume/TrafficVolumeFunction";
import { upsertLocationConflictMarketCut } from "../subPage/Conflict/AddConflictFunction";
import { upsertLocationImpact } from "../subPage/Impact/AddImpactFunction";
import { upsertDonationParam } from "../subPage/Donation/DonationFunction";
import { upsertDrawingParam } from "../subPage/Drawing/DrawingFunction";
import {
    Box,
    Button,
    Grid,
    styled,
    createTheme,
    Dialog,
    DialogTitle,
} from "@mui/material";
import { LOCAL_CONSTANT } from "../Const";
import { useNavigate } from "react-router-dom";
import { useUtilityContext } from "../../utility-provider";
import { useUserContext } from "../../user-provider";
import { apiRequest } from "../../api/ApiCall";
import { updateListRegist } from "../Common";
import { HideShowFunc } from "../components/PermissionFunc";
import InvalidInputList from "../components/InvalidInputList";
import LocationExcelAndN15AddMarktCheck from "../components/LocationExcelAndN15AddMarktCheck";
import { setObjEditImpactData } from "../../features/impactSlice";
import {
    setReduxReloadTrafficVolume,
    setTrafficVolumeClearDeleteParam,
} from "../../features/trafficVolumeSlice";

//スタイル
const FloatButton = styled(Button)({
    backgroundColor: "#FFFFFF",
    color: "#0E6F55",
    border: "solid 1px #00AC4C",
    marginLeft: "10px",
    "&: hover": {
        backgroundColor: "#FFFFFF",
    },
});
const theme = createTheme({
    spacing: 4,
});
const CancelButton = styled(Button)({
    backgroundColor: "#ffffff00",
    color: "#f00",
    border: "1px solid #f00",
    float: "left",
    margin: "10px 20px",
});
const SaveButton = styled(Button)({
    backgroundColor: "#00CC5B",
    color: "white",
    float: "right",
    margin: "10px 20px",
    "&: hover": {
        backgroundColor: "#29e17a",
    },
});
const DuplicateCancelButton = styled(Button)({
    backgroundColor: "#ffffff00",
    color: "#f00",
    border: "1px solid #f00",
    float: "left",
    margin: "10px",
});
const DuplicateButton = styled(Button)({
    backgroundColor: "#00CC5B",
    color: "white",
    float: "right",
    margin: "10px",
    "&: hover": {
        backgroundColor: "#29e17a",
    },
});

export interface menuProps {
    props: {
        planAreaId: string | null;
        branch: string | null;
        excelSequence: string | null;
    };
}
const Menu: React.FC<menuProps> = ({ props }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();
    const user = useUserContext();

    //ロングページ保存用ステータス
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
    // 複製店舗、候補地ID
    const objStorePlanAreaId = useAppSelector((state) => state.longPage.storePlanAreaId);
    // 単体保存ステータス
    const objUnitEdit = useAppSelector((state) => state.longPage.unitEdit);
    // 調査書テーブル
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    //追加調査を取得
    const objLocationAddSurvey = useAppSelector((state) => state.locationAddSurvey.obj);
    // 表紙
    const objCover = useAppSelector((state) => state.cover.obj);
    // 出店条件
    const objStoreOpeningConditions = useAppSelector((state) => state.storeOpeningConditions.obj);
    // 商圏
    const listTradeArea = useAppSelector((state) => state.tradeArea.mpDataList);
    // 現況写真
    const objPicture = useAppSelector((state) => state.picture);
    // 寄付き
    const objDonation = useAppSelector((state) => state.donation.obj);
    // 通行量 車通行量、人通行量
    const listUpdateTraffic = useAppSelector((state) => state.trafficVolume.updateList);
    // 通行量 削除パラメータ
    const listDeleteTrafficParam = useAppSelector((state) => state.trafficVolume.deleteParamterList);
    // 通行量(5m外歩行者TR算入確認)
    const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);
    // 通行量 その他 駅出入口の名称と距離
    const objStation = useAppSelector((state) => state.locationTraffic5mcut.stationObj);
    // 通行量 その他 大学短大の名称と距離
    const objSchool = useAppSelector((state) => state.locationTraffic5mcut.schoolObj);
    // 競合店（商圏カット）
    const objConflictMarketCut = useAppSelector((state) => state.conflict.market_cut);
    // 影響度
    const objEditImpactData = useAppSelector((state) => state.impact.editImpactData);
    // 図面
    const objDrawing = useAppSelector((state) => state.drawing.obj);
    //モーダル開閉フラグ(保存確認モーダル)
    const [saveConfModalOpen, setSaveConfModalOpen] = useState(false);
    //モーダルページ切替(保存ボタン用)
    const [pageing, setPageing] = useState<number>(0);
    //モーダル開閉フラグ(複製確認モーダル)
    const [duplicateConfModalOpen, setDuplicateConfModalOpen] = useState<boolean>(false);
    //モーダルページ切り替え(複製ボタン用)
    const [duplicatePageing, setDuplicatePageing] = useState<string>("duplicateConfirm");
    //不正な入力項目
    const [errorList, setErrorList] = useState<any>();
    //中間生成物フラグ
    const [locationExcelFlag, setLocationExcelFlag] = useState<string>("");
    //n15追加商圏フラグ
    const [n15AddMarketFlag, setN15AddMarketFlag] = useState<string>("");
    //ユーザー格納
    const [userType, setUserType] = useState<string>("");

    //保存ボタン活性化
    const hasSaveDisabled = () => {
        let disabled = true;
        // objLongPageSaveStatusが全てfalseならtrueを返す
        const longPageSaveStatus = Object.values(objLongPageSaveStatus).every(
            (value) => value === false
        );
        // objUnitEditが1つでもtrueならtrueを返す
        const unitEdit = Object.values(objUnitEdit).some(
            (value) => value === true
        );
        if (!longPageSaveStatus && !unitEdit) {
            disabled = false;
        }
        return disabled;
    };

    //モーダルクローズ(保存確認モーダル)
    const handleSaveConfModalClose = () => {
        setSaveConfModalOpen(false);
        setTimeout(() => {
            setPageing(0);
        }, 500);
    };

    //モーダルクローズ(複製確認モーダル)
    const hanldeDuplicateConfModalClose = () => {
        setDuplicateConfModalOpen(false);
        setTimeout(() => {
            setDuplicatePageing("duplicateConfirm");
        }, 500);
    };

    //保存用のparams
    const saveParams = () => {
        const params: any = [];
        // 調査書基本
        if (objLongPageSaveStatus.LongPage) {
            params.push(upsertlocationBaseParam(objLocationBase, props.planAreaId, props.branch));
        }
        // 追加調査
        if (objLongPageSaveStatus.AddSurvey) {
            params.push(upsertLocationAddSurvey(objLocationAddSurvey, props.planAreaId, props.branch));
        }
        // 表紙
        if (objLongPageSaveStatus.Cover) {
            params.push(upsertCoverParam(objCover, props.planAreaId, props.branch));
        }
        // 出店条件
        if (objLongPageSaveStatus.StoreOpeningConditions) {
            params.push(upsertStoreOpeningConditionsParam(objStoreOpeningConditions, props.planAreaId, props.branch));
        }
        // 商圏
        if (objLongPageSaveStatus.TradeArea) {
            params.push(upsertTradeAreaParam(listTradeArea, props.planAreaId, props.branch, props.excelSequence));
        }
        // 現況写真
        if (objLongPageSaveStatus.Picture) {
            params.push(upsertPhotoParam(objPicture, props.planAreaId, props.branch));
        }
        // 寄付き
        if (objLongPageSaveStatus.Donation) {
            params.push(
                upsertDonationParam(objDonation, props.planAreaId, props.branch)
            );
        }
        // 通行量
        if (objLongPageSaveStatus.TrafficVolume) {
            params.push(upsertLocationTraffic5mcutParam(objLocationTraffic5mcut, props.planAreaId, props.branch, objStation, objSchool));
        }
        // 通行量 車通行量、人通行量
        if (objLongPageSaveStatus.TrafficVolumeCarPeople) {
            params.push(setParam(props.planAreaId, listUpdateTraffic));
        }
        // 競合　商圏カット
        if (objLongPageSaveStatus.ConflictMarketCut) {
            params.push(upsertLocationConflictMarketCut(objConflictMarketCut, props.planAreaId, props.branch));
        }
        // 影響度
        if (objLongPageSaveStatus.Impact) {
            params.push(upsertLocationImpact(objEditImpactData, props.planAreaId, props.branch));
            // 影響度編集クリア
            dispatch(setObjEditImpactData([]));
        }
        // 図面
        if (objLongPageSaveStatus.Drawing) {
            params.push(upsertDrawingParam(objDrawing, props.planAreaId, props.branch));
        }
        return params;
    };

    //保存ボタン
    const handleSave = () => {
        const params = saveParams();
        // 通行量 削除パラメータもある場合
        if (params.length > 0 || listDeleteTrafficParam.length > 0) {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            updateListRegist(params)
                .then(() => {
                    dispatch(
                        setSaveStatus({
                            ...objLongPageSaveStatus,
                            LongPage: false,
                            AddSurvey: false,
                            Cover: false,
                            StoreOpeningConditions: false,
                            TradeArea: false,
                            Picture: false,
                            Donation: false,
                            TrafficVolume: false,
                            TrafficVolumeCarPeople: false,
                            Conflict: false,
                            ConflictMarketCut: false,
                            Impact: false,
                            Map: false,
                            Drawing: false,
                        })
                    );
                })
                .then(() => {
                    // 通行量のReduxを更新
                    dispatch(setReduxReloadTrafficVolume(listUpdateTraffic));
                    // 通行量 削除パラメータもある場合
                    if (listDeleteTrafficParam.length > 0) {
                        updateListRegist(listDeleteTrafficParam);
                    }
                })
                .then(() => {
                    // 通行量 削除パラメータを初期化
                    dispatch(setTrafficVolumeClearDeleteParam());
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    setPageing(1);
                })
                .catch((e) => {
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    setPageing(2);
                    console.log(e);
                });
        } else {
            setTimeout(() => {
                setPageing(1);
            }, 500);
        }
    };

    //画面遷移
    const handleMovePage = (location: string) => {
        navigate(
            location + `?planAreaId=${props.planAreaId}&branch=${props.branch}`
        );
    };

    //別タブ遷移
    const handleNewTabPage = (location: string) => {
        let baseUrl = "";
        let newTabUrl = "";
        if (location === "/r_view") {
            let planAreaId = props.planAreaId;
            if (objStorePlanAreaId !== "") {
                planAreaId = objStorePlanAreaId;
            }
            baseUrl = process.env.REACT_APP_RECRUIT_VIEW!;
            newTabUrl = `${baseUrl}?mode=detail_prop_site&id=${planAreaId}&encryptionFlag=0`;
        } else {
            baseUrl = window.location.origin;
            newTabUrl = `${baseUrl}${location}?planAreaId=${props.planAreaId}&branch=${props.branch}`;
        }

        window.open(newTabUrl);
    };

    //複製ボタン
    const handleDuplicate = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        //保存処理 項目内容に変更がない場合は保存処理を実施しない
        const params = saveParams();
        // 通行量 削除パラメータもある場合
        if (params.length > 0 || listDeleteTrafficParam.length > 0) {
            updateListRegist(params)
                .then(() => {
                    dispatch(
                        setSaveStatus({
                            ...objLongPageSaveStatus,
                            LongPage: false,
                            AddSurvey: false,
                            Cover: false,
                            StoreOpeningConditions: false,
                            TradeArea: false,
                            Picture: false,
                            Donation: false,
                            TrafficVolume: false,
                            TrafficVolumeCarPeople: false,
                            Conflict: false,
                            ConflictMarketCut: false,
                            Impact: false,
                            Map: false,
                            Drawing: false,
                        })
                    );
                })
                .then(() => {
                    // 通行量のReduxを更新
                    dispatch(setReduxReloadTrafficVolume(listUpdateTraffic));
                    // 通行量 削除パラメータもある場合
                    if (listDeleteTrafficParam.length > 0) {
                        updateListRegist(listDeleteTrafficParam);
                    }
                })
                .then(() => {
                    // 通行量 削除パラメータを初期化
                    dispatch(setTrafficVolumeClearDeleteParam());
                })
                .then(() => {
                    //保存処理後、複製処理
                    duplicate();
                })
                .catch((e) => {
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    console.log(e);
                });
        } else {
            //複製処理
            duplicate();
        }
    };

    //複製処理
    const duplicate = () => {
        const surveyRequestCheckParams = {
            mode: "regist",
            endPoint: "/location/Task/v1/survey-request-check",
            query: {
                plan_area_id: props.planAreaId,
                branch: props.branch,
                request_type: "0", //通常出力
            },
        };
        const duplicateParams = {
            mode: "regist",
            endPoint: "/location/system/v1/duplicate",
            query: {
                plan_area_id: props.planAreaId,
                branch: props.branch,
                store_code: objLocationBase.child_location,
                type: "1",
            },
        };
        (async () => {
            try {
                //依頼APIチェックを実施
                const resultCheck = await apiRequest(surveyRequestCheckParams);
                if (resultCheck.data.dataList.length > 0) {
                    const dataList = JSON.parse(resultCheck.data.dataList);
                    const resultLocationExcelFlag =
                        dataList.LocationExcelFlag === "0";
                    const resultN15AddMarketFlag =
                        dataList.n15AddMarketFlag === "0";
                    //中間生成物が取り込まれていない場合 「中間生成物が取り込まれていません」のメッセージ表示
                    if (resultLocationExcelFlag && !resultN15AddMarketFlag) {
                        if (utilityCtx.hideSpinner) {
                            utilityCtx.hideSpinner();
                        }
                        setDuplicatePageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
                        setLocationExcelFlag("0");
                        //n15追加商圏にデータが存在しない場合「n15追加商圏が取り込まれていません」のエラーメッセージを表示
                    } else if (
                        resultN15AddMarketFlag &&
                        !resultLocationExcelFlag
                    ) {
                        if (utilityCtx.hideSpinner) {
                            utilityCtx.hideSpinner();
                        }
                        setDuplicatePageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
                        setN15AddMarketFlag("0");
                        //中間生成物とn15追加商圏どちらも取り込まれていない場合
                    } else if (
                        resultLocationExcelFlag &&
                        resultN15AddMarketFlag
                    ) {
                        if (utilityCtx.hideSpinner) {
                            utilityCtx.hideSpinner();
                        }
                        setDuplicatePageing("errorLocationExcelFlagAndN15AddMarketFlagCheck");
                        setLocationExcelFlag("0");
                        setN15AddMarketFlag("0");
                        //中間生成物が存在する場合は入力チェック、出力依頼
                    } else {
                        if (dataList.Response === "error") {
                            if (utilityCtx.hideSpinner) {
                                utilityCtx.hideSpinner();
                            }
                            //不正な入力項目を表示
                            setErrorList(dataList.errorList);
                            setDuplicatePageing("errorList");
                        } else if (dataList.Response === "success") {
                            //複製APIを実施
                            const duplicateResultCheck = await apiRequest(
                                duplicateParams
                            );
                            //複製APIのレスポンス取得
                            const duplicateDataList = JSON.parse(
                                duplicateResultCheck.data.dataList
                            );
                            if (utilityCtx.hideSpinner) {
                                utilityCtx.hideSpinner();
                            }
                            if (duplicateDataList.Response === "error") {
                                // 複製でエラーの場合duplicateErrorをセットして終了とする
                                setDuplicatePageing("duplicateError");
                            } else if (
                                duplicateDataList.Response === "success"
                            ) {
                                // 複製で成功の場合、複製のステータスフラグを更新
                                setDuplicatePageing("duplicateComplete");
                                //複製完了後 複製のステータスフラグを更新
                                dispatch(setDuplicateStatus(true));
                            }
                        }
                    }
                }
            } catch (e) {
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setDuplicatePageing("duplicateError");
                console.log(e);
            }
        })();
    };

    //ユーザー取得
    useEffect(() => {
        if (JSON.parse(user.userData || "null") !== null) {
            setUserType(JSON.parse(user.userData || "null").user_type);
        }
    }, [user]);

    return (
        <>
            {/* フロートボタン */}
            <Grid container spacing={2}>
                <Grid item xs={5}></Grid>
                <Grid item xs={7} sx={{ position: "fixed", top: "6%", right: "2%", margin: theme.spacing(2), zIndex: 1000 }}>
                    {HideShowFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.LONG_PAGE,'duplicate') &&
                    (objLocationBase.location_type === "02" || objLocationBase.location_type === "03") &&
                        <FloatButton variant="outlined" onClick={() => { setDuplicateConfModalOpen(true) }}>複製</FloatButton>
                    }
                    {/* 子調査またはRFCの場合 依頼コメントボタンを非表示 */}
                    {(userType !== "z003" && (props.planAreaId?.startsWith("X") || props.planAreaId?.startsWith("Y")) === false) && 
                        <FloatButton variant="outlined" onClick={() => { handleMovePage("/RequestComment") }}>依頼/コメント</FloatButton>
                    }
                    <FloatButton variant="outlined" onClick={() => { handleNewTabPage("/DeficiencyComment") }}>{LOCAL_CONSTANT.LABEL.LIST_OF_DEFICIENCIES}</FloatButton>
                    <FloatButton variant="outlined" onClick={() => { handleNewTabPage("/r_view") }}>{LOCAL_CONSTANT.LABEL.R_VIEW}</FloatButton>
                    <FloatButton variant="outlined" onClick={() => { handleNewTabPage("/PdfSummary") }}>{LOCAL_CONSTANT.LABEL.PDF}</FloatButton>
                    <FloatButton variant="outlined" onClick={() => { setSaveConfModalOpen(true) }} disabled={hasSaveDisabled()}>{LOCAL_CONSTANT.LABEL.SAVE}</FloatButton>
                </Grid>
            </Grid>

            {/* 保存確認モーダル */}
            <Dialog open={saveConfModalOpen} onClose={handleSaveConfModalClose}>
                {pageing === 0 && (
                    <Box>
                        <DialogTitle sx={{ textAlign: "center" }}>入力中の内容を保存しますか？</DialogTitle>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CancelButton onClick={() => {handleSaveConfModalClose();}}>キャンセル</CancelButton>
                            </Grid>
                            <Grid item xs={6}>
                                <SaveButton onClick={() => {handleSave();}}>保存</SaveButton>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {pageing === 1 && (
                    <Box>
                        <DialogTitle sx={{ textAlign: "center" }}>保存しました。</DialogTitle>
                        <Box sx={{ margin: "30px" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <SaveButton onClick={() => {handleSaveConfModalClose();}}>閉じる</SaveButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}

                {pageing === 2 && (
                    <Box>
                        <DialogTitle sx={{ textAlign: "center" }}>保存に失敗しました。</DialogTitle>
                        <Box sx={{ margin: "30px" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <CancelButton onClick={() => {handleSaveConfModalClose();}}>閉じる</CancelButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </Dialog>

            {/* 複製ボタン モーダル */}
            <Dialog
                open={duplicateConfModalOpen}
                sx={{ minWidth: "700px" }}
                onClose={hanldeDuplicateConfModalClose}
            >
                {duplicatePageing === "duplicateConfirm" && (
                    <Box>
                        <DialogTitle sx={{ textAlign: "center" }}>複製を実施しますか？</DialogTitle>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DuplicateCancelButton onClick={() => {hanldeDuplicateConfModalClose();}}>キャンセル</DuplicateCancelButton>
                            </Grid>
                            <Grid item xs={6}>
                                <DuplicateButton onClick={() => {handleDuplicate();}}>はい</DuplicateButton>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {duplicatePageing === "duplicateComplete" && (
                    <Box>
                        <DialogTitle sx={{ textAlign: "center" }}>複製しました。
                        </DialogTitle>
                        <Box sx={{ margin: "30px" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <DuplicateButton onClick={() => {hanldeDuplicateConfModalClose();}}>閉じる</DuplicateButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}

                {duplicatePageing === "duplicateError" && (
                    <Box>
                        <DialogTitle sx={{ textAlign: "center" }}>複製に失敗しました。</DialogTitle>
                        <Box sx={{ margin: "30px" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <DuplicateCancelButton onClick={() => {hanldeDuplicateConfModalClose();}}>閉じる</DuplicateCancelButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}

                {duplicatePageing ===
                    "errorLocationExcelFlagAndN15AddMarketFlagCheck" && (
                    //中間生成物とN15追加商圏の判定結果
                    <LocationExcelAndN15AddMarktCheck
                        props={{
                            locationExcelFlag: locationExcelFlag,
                            n15AddMarketFlag: n15AddMarketFlag,
                            handleModalClose: hanldeDuplicateConfModalClose,
                        }}
                    />
                )}

                {/* エラー項目一覧 */}
                {duplicatePageing === "errorList" && (
                    <InvalidInputList
                        props={{
                            errorMessage:
                                LOCAL_CONSTANT.ERROR_MESSAGE
                                    .ERROR_LOCATION_ITEM,
                            errorList: errorList,
                            handleModalClose: hanldeDuplicateConfModalClose,
                        }}
                    />
                )}
            </Dialog>
        </>
    );
};

export default Menu;
