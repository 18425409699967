import { zIndexOrder } from "../Interface";
import { googleMapsLine2GeoJsonLine } from "../../../utility/turf";
import { planAreaSearchById, existingSejStoreSearchByDistance, getImpactCounter, getLongPageData } from "../../../data/DataManage";

// ラベルマーカー表示
export const setImpactLabelMarker = (latitude: number, longitude: number, text: string) => {
    return new google.maps.Marker({
        position: {
            lat: latitude,
            lng: longitude,
        },
        icon: {
            url: process.env.PUBLIC_URL + "/image/icons/sej_normal.svg",
            scaledSize: new google.maps.Size(0, 0),
            labelOrigin: new google.maps.Point(0, -20),
        },
        label: {
            text: text,
            color: "#000000",
            fontWeight: "bold",
            fontSize: "16px",
        },
    });
};

export const exsitesStoreCode = (impact: any, data: any) => {
    let hasImpact = false;
    impact.map((row: any) => {
        if (data.data.id === row.store_code) {
            hasImpact = true;
        }
    });

    return hasImpact;
};

// 登録済みマーカー置き換え
export const impactMarker = (map: google.maps.Map, data: any, targetImpact: any) => {
    data.marker.setMap(null);
    var marker = new google.maps.Marker({
        position: {
            lat: data.data.centerlat,
            lng: data.data.centerlon,
        },
    });

    let iconUrl = "";
    let x = 0;
    let y = 0;
    let zIndex = 0;
    iconUrl = process.env.PUBLIC_URL + "/image/icons/sej_back_green.svg";
    x = 10;
    y = 10;
    zIndex = zIndexOrder.existingSejStoreIcon;

    marker.setOptions({
        icon: {
            url: iconUrl,
            anchor: new google.maps.Point(x, y),
        },
        zIndex: zIndex,
        clickable: false,
    });

    data.marker = marker;
    data.marker.setMap(map);

    return data;
};

// POSTREQUEST整地
export const registImpactCounter = (selectStore: any, plan_area_id: string, branch: string, mode: string) => {
    interface Obj {
        [prop: string]: any;
    }
    const requestParams: Obj = {};
    const location_impact_counter: any = [];
    const location_impact_counter_uuid: any = [];
    let road_distance = 0;
    let car_front_store_count_1 = 0;
    let car_front_store_count_2 = 0;
    let car_total_share_ratio_1 = 0;
    let car_total_share_ratio_2 = 0;
    let people_front_store_count_1 = 0;
    let people_front_store_count_2 = 0;
    let people_total_share_ratio_1 = 0;
    let people_total_share_ratio_2 = 0;
    let car_route_count_1 = 0;
    let car_route_count_2 = 0;
    let people_route_count_1 = 0;
    let people_route_count_2 = 0;
    let car_root_1 = false;
    let car_root_2 = false;
    let people_root_1 = false;
    let people_root_2 = false;
    Object.keys(selectStore.counter).map((tab: any) => {
        let counter_seq = 1;
        if (Number(tab) === 0) {
            car_root_1 = true;
        } else if (Number(tab) === 1) {
            car_root_2 = true;
        } else if (Number(tab) === 2) {
            people_root_1 = true;
        } else if (Number(tab) === 3) {
            people_root_2 = true;
        }
        Object.keys(selectStore.counter[tab].impactCounter).map((impact) => {
            let directions_share = 0; // 共通
            let directions_not_share = 0; // 非共通
            let directions_share_ratio = 0; // 比率

            const data = selectStore.counter[tab].impactCounter[impact].impactData;
            const roadShape = googleMapsLine2GeoJsonLine(selectStore.counter[tab].path);
            if (location_impact_counter_uuid.includes(data.uuid) === false && counter_seq !== 1) {
                location_impact_counter_uuid.push(data.uuid);
            }
            if (road_distance === 0 || road_distance > selectStore.counter[tab].road_distance) {
                road_distance = selectStore.counter[tab].road_distance;
            }
            if (data.top_left_type.toString() === "1") {
                directions_share += Number(data.top_left_count);
            } else if (data.top_left_type.toString() === "2") {
                directions_not_share += Number(data.top_left_count);
            }
            if (data.top_bottom_type.toString() === "1") {
                directions_share += Number(data.top_bottom_count);
            } else if (data.top_bottom_type.toString() === "2") {
                directions_not_share += Number(data.top_bottom_count);
            }
            if (data.top_right_type.toString() === "1") {
                directions_share += Number(data.top_right_count);
            } else if (data.top_right_type.toString() === "2") {
                directions_not_share += Number(data.top_right_count);
            }
            if (data.left_top_type.toString() === "1") {
                directions_share += Number(data.left_top_count);
            } else if (data.left_top_type.toString() === "2") {
                directions_not_share += Number(data.left_top_count);
            }
            if (data.left_bottom_type.toString() === "1") {
                directions_share += Number(data.left_bottom_count);
            } else if (data.left_bottom_type.toString() === "2") {
                directions_not_share += Number(data.left_bottom_count);
            }
            if (data.left_right_type.toString() === "1") {
                directions_share += Number(data.left_right_count);
            } else if (data.left_right_type.toString() === "2") {
                directions_not_share += Number(data.left_right_count);
            }
            if (data.bottom_top_type.toString() === "1") {
                directions_share += Number(data.bottom_top_count);
            } else if (data.bottom_top_type.toString() === "2") {
                directions_not_share += Number(data.bottom_top_count);
            }
            if (data.bottom_left_type.toString() === "1") {
                directions_share += Number(data.bottom_left_count);
            } else if (data.bottom_left_type.toString() === "2") {
                directions_not_share += Number(data.bottom_left_count);
            }
            if (data.bottom_right_type.toString() === "1") {
                directions_share += Number(data.bottom_right_count);
            } else if (data.bottom_right_type.toString() === "2") {
                directions_not_share += Number(data.bottom_right_count);
            }
            if (data.right_top_type.toString() === "1") {
                directions_share += Number(data.right_top_count);
            } else if (data.right_top_type.toString() === "2") {
                directions_not_share += Number(data.right_top_count);
            }
            if (data.right_left_type.toString() === "1") {
                directions_share += Number(data.right_left_count);
            } else if (data.right_left_type.toString() === "2") {
                directions_not_share += Number(data.right_left_count);
            }
            if (data.right_bottom_type.toString() === "1") {
                directions_share += Number(data.right_bottom_count);
            } else if (data.right_bottom_type.toString() === "2") {
                directions_not_share += Number(data.right_bottom_count);
            }
            if (directions_share !== 0 || directions_not_share !== 0) {
                directions_share_ratio = directions_share / (directions_share + directions_not_share);
            }
            if (Number(tab) < 2) {
                // 車ルート
                if (counter_seq === 1) {
                    if (Number(tab) === 0) {
                        car_front_store_count_1 += directions_share + directions_not_share;
                    } else {
                        car_front_store_count_2 += directions_share + directions_not_share;
                    }
                }
                if (Number(tab) === 0) {
                    if (car_route_count_1 === 0) {
                        car_total_share_ratio_1 = directions_share_ratio;
                    } else {
                        car_total_share_ratio_1 = car_total_share_ratio_1 * directions_share_ratio;
                    }
                    car_route_count_1 += 1;
                } else {
                    if (car_route_count_2 === 0) {
                        car_total_share_ratio_2 = directions_share_ratio;
                    } else {
                        car_total_share_ratio_2 = car_total_share_ratio_2 * directions_share_ratio;
                    }
                    car_route_count_2 += 1;
                }
            } else if (Number(tab) >= 2) {
                // 人ルート
                if (counter_seq === 1) {
                    if (Number(tab) === 2) {
                        people_front_store_count_1 += directions_share + directions_not_share;
                    } else {
                        people_front_store_count_2 += directions_share + directions_not_share;
                    }
                }
                if (Number(tab) === 2) {
                    if (people_route_count_1 === 0) {
                        people_total_share_ratio_1 = directions_share_ratio;
                    } else {
                        people_total_share_ratio_1 = people_total_share_ratio_1 * directions_share_ratio;
                    }
                    people_route_count_1 += 1;
                } else {
                    if (people_route_count_2 === 0) {
                        people_total_share_ratio_2 = directions_share_ratio;
                    } else {
                        people_total_share_ratio_2 = people_total_share_ratio_2 * directions_share_ratio;
                    }
                    people_route_count_2 += 1;
                }
            }

            let counter_of_seq = data.seq.toString();
            if (data.counter_of_seq) {
                counter_of_seq = data.counter_of_seq.toString();
            }

            location_impact_counter.push({
                plan_area_id: plan_area_id.toString(),
                branch: branch.toString(),
                store_code: selectStore.key.toString(),
                route_seq: tab.toString(),
                counter_seq: counter_seq.toString(),
                counter_uuid: data.uuid.toString(),
                counter_of_seq: counter_of_seq,
                counter_name: data.name.toString(),
                counter_centerlat: data.centerlat.toString(),
                counter_centerlon: data.centerlon.toString(),
                counter_date: data.count_date.toString(),
                shape: JSON.stringify(roadShape).toString(),
                road_distance: selectStore.counter[tab].road_distance.toString(),
                top_left_count: data.top_left_count.toString(),
                top_left_type: data.top_left_type.toString(),
                top_bottom_count: data.top_bottom_count.toString(),
                top_bottom_type: data.top_bottom_type.toString(),
                top_right_count: data.top_right_count.toString(),
                top_right_type: data.top_right_type.toString(),
                left_top_count: data.left_top_count.toString(),
                left_top_type: data.left_top_type.toString(),
                left_bottom_count: data.left_bottom_count.toString(),
                left_bottom_type: data.left_bottom_type.toString(),
                left_right_count: data.left_right_count.toString(),
                left_right_type: data.left_right_type.toString(),
                bottom_top_count: data.bottom_top_count.toString(),
                bottom_top_type: data.bottom_top_type.toString(),
                bottom_left_count: data.bottom_left_count.toString(),
                bottom_left_type: data.bottom_left_type.toString(),
                bottom_right_count: data.bottom_right_count.toString(),
                bottom_right_type: data.bottom_right_type.toString(),
                right_top_count: data.right_top_count.toString(),
                right_top_type: data.right_top_type.toString(),
                right_left_count: data.right_left_count.toString(),
                right_left_type: data.right_left_type.toString(),
                right_bottom_count: data.right_bottom_count.toString(),
                right_bottom_type: data.right_bottom_type.toString(),
                directions_share: directions_share.toString(),
                directions_not_share: directions_not_share.toString(),
                directions_share_ratio: directions_share_ratio.toString(),
            });
            counter_seq = counter_seq + 1;
        });
    });

    if (mode !== "edit") {
        requestParams.store_position = "";
        requestParams.common_road = "";
        requestParams.plan_median_strip = "";
        requestParams.store_median_strip = "";
        requestParams.straight_count = "";
        requestParams.turn_count = "";
        requestParams.railroad_crossing = "";
        requestParams.river_width = "";
        requestParams.crossing_road_count = "";
        requestParams.conflict_sej = "";
        requestParams.conflict_main_cvs = "";
        requestParams.conflict_other_cvs = "";
        requestParams.near_store_code = "";
        requestParams.average_common_ratio = "";
        requestParams.salesfloor = selectStore?.data.salesfloor.toString();
        requestParams.parkingarea = selectStore?.data.parkingarea.toString();
    }
    requestParams.type = selectStore?.data.type.toString();
    requestParams.tobaccolicense = selectStore?.data.tobaccolicense.toString();
    let tmp_car_front_store_count = 0;
    let tmp_people_front_store_count = 0;
    let tmp_car_total_share_ratio = 0;
    let tmp_people_total_share_ratio = 0;
    if (car_root_1 && car_root_2) {
        tmp_car_front_store_count = (car_front_store_count_1 + car_front_store_count_2) / 2;
        tmp_car_total_share_ratio = car_total_share_ratio_1 + car_total_share_ratio_2;
    } else {
        tmp_car_front_store_count = car_front_store_count_1 + car_front_store_count_2;
        if (car_root_1) {
            tmp_car_total_share_ratio = car_total_share_ratio_1;
        } else if (car_root_2) {
            tmp_car_total_share_ratio = car_total_share_ratio_2;
        }
    }
    if (people_root_1 && people_root_2) {
        tmp_people_front_store_count = (people_front_store_count_1 + people_front_store_count_2) / 2;
        tmp_people_total_share_ratio = people_total_share_ratio_1 + people_total_share_ratio_2;
    } else {
        tmp_people_front_store_count = people_front_store_count_1 + people_front_store_count_2;
        if (people_root_1) {
            tmp_people_total_share_ratio = people_total_share_ratio_1;
        } else if (people_root_2) {
            tmp_people_total_share_ratio = people_total_share_ratio_2;
        }
    }
    requestParams.plan_area_id = plan_area_id.toString();
    requestParams.store_code = selectStore.key.toString();
    requestParams.road_distance = road_distance.toString();
    requestParams.car_front_store_count = tmp_car_front_store_count.toString();
    requestParams.car_total_share_ratio = tmp_car_total_share_ratio.toString();
    requestParams.people_front_store_count = tmp_people_front_store_count.toString();
    requestParams.people_total_share_ratio = tmp_people_total_share_ratio.toString();
    requestParams.location_impact_counter = location_impact_counter;
    requestParams.location_impact_counter_uuid = location_impact_counter_uuid;

    return requestParams;
};

export const upsertLocationImpact = (object: any, plan_area_id: any, branch: any) => {
    return {
        mode: "regist",
        endPoint: "/location/Impact/v1/bulk-update",
        query: {
            plan_area_id: plan_area_id,
            branch: branch,
            location_impact: object,
        },
    };
};

export const callApi = async (target: string, planAreaId: string, lat: number, lng: number, distance: number, excelSequence: string, user: any) => {
    if (target === "planArea") {
        const jsonUserData = JSON.parse(user.userData);
        let manageCitiesFlg = true;
        if (jsonUserData.administrator_flag === "1" || jsonUserData.manage_cities?.includes("ALL")) {
            manageCitiesFlg = false;
        }
        const manage_steps = ["0", "8", "99"];
        let stepCnt = jsonUserData.manage_steps.filter((val: any) => manage_steps.includes(val) === false);
        let managePlansOutsideCities = jsonUserData.manage_plans_outside_cities;
        if (!managePlansOutsideCities.includes("")) {
            managePlansOutsideCities.push("");
        }
        // テスト中固定値
        return await planAreaSearchById({
            mode: "planAreaSearchById",
            id: planAreaId,
            manageCitiesFlg: manageCitiesFlg,
            manageCities: jsonUserData.manage_cities.join(","),
            manageStep: stepCnt.join(","),
            managePlansOutsideCities: managePlansOutsideCities.join(","),
        });
    } else if (target === "getLongPageData") {
        const param: any = [];
        param.push({
            mode: "getExistingSejPlanArea",
            longitude: lng,
            latitude: lat,
            distance: distance,
            propSiteNo: planAreaId,
            excelSequence: excelSequence,
        });
        param.push({
            mode: "getImpactCounter",
            longitude: lng,
            latitude: lat,
            uuid: "",
            distance: distance,
        });
        return await getLongPageData(param);
    } else if (target === "existingSejStore") {
        return await existingSejStoreSearchByDistance({
            mode: "existingSejStoreSearchByDistance",
            longitude: lng,
            latitude: lat,
            distance: distance,
        });
    } else if (target === "getImpactCounter") {
        return await getImpactCounter({
            mode: "getImpactCounter",
            longitude: lng,
            latitude: lat,
            uuid: "",
            distance: distance,
        });
    }
};
