import dayjs from "dayjs";

//図面ページAPI取得 計算式と小計のデータ加工
export const handDrawnLayoutDataFunc = (res: any) => {
    const DataList: any[] = [];
    res.map((data: any, index: number) => {
        const DataObj = {
            id: index,
            handDrawnLayoutFormula: data.handDrawnLayoutFormula,
            handDrawnLayoutSubTotal: data.handDrawnLayoutSubTotal,
        };
        DataList.push(DataObj);
    });
    if (DataList.length > 0) {
        return DataList;
    } else {
        //hand_drawn_layoutの初期値を返す
        return [
            {
                id: 0,
                handDrawnLayoutFormula: "",
                handDrawnLayoutSubTotal: 0,
            },
        ];
    }
};

//図面ページAPI取得
export const drawingData = (res: any) => {
    const DataObj = {
        upload_file_paths: JSON.parse(res[0].upload_file_paths),
        is_green_gem: res[0].is_green_gem,
        is_grave: res[0].is_grave,
        is_median_strip: res[0].is_median_strip,
        is_bus_stop_blackout: res[0].is_bus_stop_blackout,
        is_in_green_gem: res[0].is_in_green_gem,
        is_other_tenant_parking: res[0].is_other_tenant_parking,
        is_expressway_etc_pole: res[0].is_expressway_etc_pole,
        is_bus_lane_etc: res[0].is_bus_lane_etc,
        is_electricity_pole: res[0].is_electricity_pole,
        is_sidewalk: res[0].is_sidewalk,
        is_one_way_street: res[0].is_one_way_street,
        is_tram_track: res[0].is_tram_track,
        is_electricity_pole_branch_line: res[0].is_electricity_pole_branch_line,
        is_crosswalk: res[0].is_crosswalk,
        is_no_entry_road: res[0].is_no_entry_road,
        is_parking_meter: res[0].is_parking_meter,
        is_high_voltage_towers: res[0].is_high_voltage_towers,
        is_guardrail: res[0].is_guardrail,
        is_waterway_etc: res[0].is_waterway_etc,
        is_others_place: res[0].is_others_place,
        is_hydrant_etc: res[0].is_hydrant_etc,
        is_street_trees_etc: res[0].is_street_trees_etc,
        is_railroad_crossing: res[0].is_railroad_crossing,
        is_old_road: res[0].is_old_road,
        is_billboard_tower_etc: res[0].is_billboard_tower_etc,
        is_street_lights_etc: res[0].is_street_lights_etc,
        is_bridge: res[0].is_bridge,
        is_unpaved_road: res[0].is_unpaved_road,
        is_garbage_dump: res[0].is_garbage_dump,
        is_road_signs: res[0].is_road_signs,
        is_overpass: res[0].is_overpass,
        is_unmaintained_etc: res[0].is_unmaintained_etc,
        is_other: res[0].is_other,
        other_long_text: res[0].other_long_text,
        hand_drawn_layout: handDrawnLayoutDataFunc(JSON.parse(res[0].hand_drawn_layout)),
        hand_drawn_layout_total: res[0].hand_drawn_layout_total,
        hand_drawn_layout_total_tsubo: res[0].hand_drawn_layout_total_tsubo,
        layout_file_path: res[0].layout_file_path,
        layout_file_user: res[0].layout_file_user,
        placement_file_path: res[0].placement_file_path,
        placement_file_user: res[0].placement_file_user,
        designer_placement_file_path: res[0].designer_placement_file_path,
        designer_placement_file_user: res[0].designer_placement_file_user,
    };
    return DataObj;
};

//図面ページAPI登録 計算式と小計のデータ加工
export const hand_drawn_layout_Func = (res: any[]) => {
    const DataList: any[] = [];
    res.map((data: any) => {
        const DataObj = {
            handDrawnLayoutFormula: data.handDrawnLayoutFormula,
            handDrawnLayoutSubTotal: data.handDrawnLayoutSubTotal,
        };
        DataList.push(DataObj);
    });
    return DataList;
};

//図面ページAPI登録
export const upsertDrawingParam = (objDrawing: any, plan_area_id: any, branch: any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Drawing/v1/regist",
        query: {
            plan_area_id: plan_area_id,
            branch: branch,
            upload_file_paths: JSON.stringify(objDrawing.upload_file_paths),
            is_green_gem: objDrawing.is_green_gem,
            is_grave: objDrawing.is_grave,
            is_median_strip: objDrawing.is_median_strip,
            is_bus_stop_blackout: objDrawing.is_bus_stop_blackout,
            is_in_green_gem: objDrawing.is_in_green_gem,
            is_other_tenant_parking: objDrawing.is_other_tenant_parking,
            is_expressway_etc_pole: objDrawing.is_expressway_etc_pole,
            is_bus_lane_etc: objDrawing.is_bus_lane_etc,
            is_electricity_pole: objDrawing.is_electricity_pole,
            is_sidewalk: objDrawing.is_sidewalk,
            is_one_way_street: objDrawing.is_one_way_street,
            is_tram_track: objDrawing.is_tram_track,
            is_electricity_pole_branch_line: objDrawing.is_electricity_pole_branch_line,
            is_crosswalk: objDrawing.is_crosswalk,
            is_no_entry_road: objDrawing.is_no_entry_road,
            is_parking_meter: objDrawing.is_parking_meter,
            is_high_voltage_towers: objDrawing.is_high_voltage_towers,
            is_guardrail: objDrawing.is_guardrail,
            is_waterway_etc: objDrawing.is_waterway_etc,
            is_others_place: objDrawing.is_others_place,
            is_hydrant_etc: objDrawing.is_hydrant_etc,
            is_street_trees_etc: objDrawing.is_street_trees_etc,
            is_railroad_crossing: objDrawing.is_railroad_crossing,
            is_old_road: objDrawing.is_old_road,
            is_billboard_tower_etc: objDrawing.is_billboard_tower_etc,
            is_street_lights_etc: objDrawing.is_street_lights_etc,
            is_bridge: objDrawing.is_bridge,
            is_unpaved_road: objDrawing.is_unpaved_road,
            is_garbage_dump: objDrawing.is_garbage_dump,
            is_road_signs: objDrawing.is_road_signs,
            is_overpass: objDrawing.is_overpass,
            is_unmaintained_etc: objDrawing.is_unmaintained_etc,
            is_other: objDrawing.is_other,
            other_long_text: objDrawing.other_long_text,
            hand_drawn_layout: JSON.stringify(hand_drawn_layout_Func(objDrawing.hand_drawn_layout)),
            hand_drawn_layout_total: objDrawing.hand_drawn_layout_total,
            hand_drawn_layout_total_tsubo: tsuboFunc(objDrawing.hand_drawn_layout_total),
            layout_file_path: objDrawing.layout_file_path,
            layout_file_user: objDrawing.layout_file_user,
            placement_file_path: objDrawing.placement_file_path,
            placement_file_user: objDrawing.placement_file_user,
            designer_placement_file_path: objDrawing.designer_placement_file_path,
            designer_placement_file_user: objDrawing.designer_placement_file_user,
        },
    };
    return params;
};

//㎡から坪へ変換
export const tsuboFunc = (total: number) => {
    return total === 0 ? 0 : total * 0.3025;
};

//図面ページAPI登録 PDFアップロード
export const upsertDrawingPdfParam = (objDrawing: any, plan_area_id: any, branch: any, file_log: any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Drawing/v1/regist",
        query: drawingPdfParamQuery(objDrawing, plan_area_id, branch, file_log),
    };
    return params;
};

//図面ページAPI登録 PDFアップロードとPDF削除時のquery
export const drawingPdfParamQuery = (objDrawing: any, plan_area_id: any, branch: any, file_log: any) => {
    return {
        plan_area_id: plan_area_id,
        branch: branch,
        upload_file_paths: JSON.stringify(objDrawing.upload_file_paths),
        is_green_gem: objDrawing.is_green_gem,
        is_grave: objDrawing.is_grave,
        is_median_strip: objDrawing.is_median_strip,
        is_bus_stop_blackout: objDrawing.is_bus_stop_blackout,
        is_in_green_gem: objDrawing.is_in_green_gem,
        is_other_tenant_parking: objDrawing.is_other_tenant_parking,
        is_expressway_etc_pole: objDrawing.is_expressway_etc_pole,
        is_bus_lane_etc: objDrawing.is_bus_lane_etc,
        is_electricity_pole: objDrawing.is_electricity_pole,
        is_sidewalk: objDrawing.is_sidewalk,
        is_one_way_street: objDrawing.is_one_way_street,
        is_tram_track: objDrawing.is_tram_track,
        is_electricity_pole_branch_line: objDrawing.is_electricity_pole_branch_line,
        is_crosswalk: objDrawing.is_crosswalk,
        is_no_entry_road: objDrawing.is_no_entry_road,
        is_parking_meter: objDrawing.is_parking_meter,
        is_high_voltage_towers: objDrawing.is_high_voltage_towers,
        is_guardrail: objDrawing.is_guardrail,
        is_waterway_etc: objDrawing.is_waterway_etc,
        is_others_place: objDrawing.is_others_place,
        is_hydrant_etc: objDrawing.is_hydrant_etc,
        is_street_trees_etc: objDrawing.is_street_trees_etc,
        is_railroad_crossing: objDrawing.is_railroad_crossing,
        is_old_road: objDrawing.is_old_road,
        is_billboard_tower_etc: objDrawing.is_billboard_tower_etc,
        is_street_lights_etc: objDrawing.is_street_lights_etc,
        is_bridge: objDrawing.is_bridge,
        is_unpaved_road: objDrawing.is_unpaved_road,
        is_garbage_dump: objDrawing.is_garbage_dump,
        is_road_signs: objDrawing.is_road_signs,
        is_overpass: objDrawing.is_overpass,
        is_unmaintained_etc: objDrawing.is_unmaintained_etc,
        is_other: objDrawing.is_other,
        other_long_text: objDrawing.other_long_text,
        hand_drawn_layout: JSON.stringify(hand_drawn_layout_Func(objDrawing.hand_drawn_layout)),
        hand_drawn_layout_total: objDrawing.hand_drawn_layout_total,
        hand_drawn_layout_total_tsubo: tsuboFunc(objDrawing.hand_drawn_layout_total),
        layout_file_path: objDrawing.layout_file_path,
        layout_file_user: objDrawing.layout_file_user,
        placement_file_path: objDrawing.placement_file_path,
        placement_file_user: objDrawing.placement_file_user,
        designer_placement_file_path: objDrawing.designer_placement_file_path,
        designer_placement_file_user: objDrawing.designer_placement_file_user,
        file_log: file_log,
    };
};
