// 背景カラー
export const BGDefaultColor = "#FFFCD6";
export const BGChangeColor = "#FFFFFF";
export const BGDisabledColor = "rgba(0, 0, 0, 0.06)";
// テキストカラー
export const TextDefaultColor = "rgba(0, 0, 0)";
export const TextChangeColor = "rgba(0, 0, 255, 1)";
export const TextDisabledColor = "rgba(0, 0, 0, 0.38)";

interface tableType {
    [key: string]: any; // <-この行を追加!
}
export const table_name = {
    表紙: "location_cover",
    出店条件: "location_storeopeningconditions",
    商圏: "location_trade_area",
    寄付き: "location_donation",
    通行量5m外: "location_traffic_5mcut",
    通行量: "location_traffic",
    競合: "location_conflict",
    影響度: "location_impact",
    図面: "location_drawing",
} as tableType;
export const getTableName = (key: string) => {
    return key in table_name ? table_name[key] : "";
};
interface tableColumnType {
    [key: string]: any; // <-この行を追加!
}
// DBカラム名に置換テーブル
export const table_column_name = {
    location_cover: {
        address: "address",
        contract_due_date: "contract_due_date",
        open_due_date: "open_due_date",
        target_contract_not_opened: "target_contract_not_opened",
    },
    location_storeopeningconditions: {
        setting_reason: "setting_reason",
        candidate_location_ruins: "candidate_location_ruins",
        candidate_location_ruins_long_text:
            "candidate_location_ruins_long_text",
        is_store_name_select_reason: "is_store_name_select_reason",
        removal_Reason: "removal_Reason",
        open24_hours: "open24_hours",
        not_open24_hours: "not_open24_hours",
        alcohol_license: "alcohol_license",
        no_alcohol_license: "no_alcohol_license",
        tobacco_licence: "tobacco_licence",
        no_tobacco_licence: "no_tobacco_licence",
        area_type: "area_type",
        nearest_already_store_distance: "nearest_already_store_distance",
        environment_type: "environment_type",
        acquisition_requirement: "acquisition_requirement",
        tobacco_distance_standard: "tobacco_distance_standard",
        distance_standard_alreadystore_count:
            "distance_standard_alreadystore_count",
        previous_job: "previous_job",
        conflict_cvs: "conflict_cvs",
        cvs_last_nippan: "cvs_last_nippan",
        relocation_distance: "relocation_distance",
        ownership_type: "ownership_type",
        store_type: "store_type",
        store_frontage: "store_frontage",
        store_area: "store_area",
        salses_area: "salses_area",
        open_case: "open_case",
        nakajima_chilled: "nakajima_chilled",
        island_chilled: "island_chilled",
        gondola_units: "gondola_units",
        gondola_height: "gondola_height",
        ice_case_large: "ice_case_large",
        ice_case_medium: "ice_case_medium",
        ice_case_small: "ice_case_small",
        compact_stocker: "compact_stocker",
        low_temp_reach_in: "low_temp_reach_in",
        walk_in: "walk_in",
        conter_width: "conter_width",
        existing_store_name: "existing_store_name",
        store_no: "store_no",
        average_nippan: "average_nippan",
        achievement_acquisition_start: "achievement_acquisition_start",
        achievement_acquisition_end: "achievement_acquisition_end",
        reception_no: "reception_no",
        s_store_open_date: "s_store_open_date",
        s_store_close_due_date: "s_store_close_due_date",
        closing_days: "closing_days",
        in_sales_construction_days: "in_sales_construction_days",
        car_common_ratio: "car_common_ratio",
        person_common_ratio: "person_common_ratio",
        s_store_line_distance: "s_store_line_distance",
        s_store_way_distance: "s_store_way_distance",
        s_store_car_customer_ratio: "s_store_car_customer_ratio",
        last_fixed_month: "last_fixed_month",
        last_one_year_nippan: "last_one_year_nippan",
        year_on_year: "year_on_year",
        year_month: "year_month",
        change_type: "change_type",
        content: "content",
        last_one_year_nippan_0: "last_one_year_nippan_0",
        last_one_year_nippan_1: "last_one_year_nippan_1",
        last_one_year_nippan_2: "last_one_year_nippan_2",
        last_one_year_nippan_3: "last_one_year_nippan_3",
        last_one_year_nippan_4: "last_one_year_nippan_4",
        last_one_year_nippan_5: "last_one_year_nippan_5",
        last_one_year_nippan_6: "last_one_year_nippan_6",
        last_one_year_nippan_7: "last_one_year_nippan_7",
        last_one_year_nippan_8: "last_one_year_nippan_8",
        last_one_year_nippan_9: "last_one_year_nippan_9",
        last_one_year_nippan_10: "last_one_year_nippan_10",
        last_one_year_nippan_11: "last_one_year_nippan_11",
        year_on_year_0: "year_on_year_0",
        year_on_year_1: "year_on_year_1",
        year_on_year_2: "year_on_year_2",
        year_on_year_3: "year_on_year_3",
        year_on_year_4: "year_on_year_4",
        year_on_year_5: "year_on_year_5",
        year_on_year_6: "year_on_year_6",
        year_on_year_7: "year_on_year_7",
        year_on_year_8: "year_on_year_8",
        year_on_year_9: "year_on_year_9",
        year_on_year_10: "year_on_year_10",
        year_on_year_11: "year_on_year_11",
        store_move_type: "store_move_type",
        store_move_distance: "store_move_distance",
        store_type_multiple_store: "store_type_multiple_store",
    },
    location_trade_area: {
        reality_survey_household_count: "reality_survey_household_count",
        reality_survey_office: "reality_survey_office",
        reality_survey_employed_workers: "reality_survey_employed_workers",
    },
    location_donation: {
        plan_area_id: "plan_area_id",
        branch: "branch",
        create_date: "create_date",
        update_date: "update_date",
        delete_flag: "delete_flag",
        driving_lane_positive_frontage: "driving_lane_positive_frontage",
        driving_lane_positive_sideway_a: "driving_lane_positive_sideway_a",
        driving_lane_positive_sideway_b: "driving_lane_positive_sideway_b",
        driving_lane_positive_backstreet: "driving_lane_positive_backstreet",
        driving_lane_opposite_frontage: "driving_lane_opposite_frontage",
        driving_lane_opposite_sideway_a: "driving_lane_opposite_sideway_a",
        driving_lane_opposite_sideway_b: "driving_lane_opposite_sideway_b",
        driving_lane_opposite_backstreet: "driving_lane_opposite_backstreet",
        median_strip_select: "median_strip_select",
        median_strip_select2: "median_strip_select2",
        median_strip_select3: "median_strip_select3",
        median_strip_select4: "median_strip_select4",
        median_strip_height: "median_strip_height",
        median_strip_height2: "median_strip_height2",
        median_strip_height3: "median_strip_height3",
        median_strip_height4: "median_strip_height4",
        sidewalk_select: "sidewalk_select",
        sidewalk_select2: "sidewalk_select2",
        sidewalk_select3: "sidewalk_select3",
        sidewalk_select4: "sidewalk_select4",
        sidewalk_height: "sidewalk_height",
        sidewalk_height2: "sidewalk_height2",
        sidewalk_height3: "sidewalk_height3",
        sidewalk_height4: "sidewalk_height4",
        road_width_door: "road_width_door",
        road_width_door2: "road_width_door2",
        road_width_door3: "road_width_door3",
        road_width_door4: "road_width_door4",
        road_width_forward: "road_width_forward",
        road_width_forward2: "road_width_forward2",
        road_width_forward3: "road_width_forward3",
        road_width_forward4: "road_width_forward4",
        road_width_front_location: "road_width_front_location",
        road_width_front_location2: "road_width_front_location2",
        road_width_front_location3: "road_width_front_location3",
        road_width_front_location4: "road_width_front_location4",
        slope_climb: "slope_climb",
        slope_climb2: "slope_climb2",
        slope_climb3: "slope_climb3",
        slope_climb4: "slope_climb4",
        slope_descend: "slope_descend",
        slope_descend2: "slope_descend2",
        slope_descend3: "slope_descend3",
        slope_descend4: "slope_descend4",
        traffic_signal_etc_forward_select: "traffic_signal_etc_forward_select",
        traffic_signal_etc_forward_select2:
            "traffic_signal_etc_forward_select2",
        traffic_signal_etc_forward_select3:
            "traffic_signal_etc_forward_select3",
        traffic_signal_etc_forward_select4:
            "traffic_signal_etc_forward_select4",
        traffic_signal_etc_forward_distance:
            "traffic_signal_etc_forward_distance",
        traffic_signal_etc_forward_distance2:
            "traffic_signal_etc_forward_distance2",
        traffic_signal_etc_forward_distance3:
            "traffic_signal_etc_forward_distance3",
        traffic_signal_etc_forward_distance4:
            "traffic_signal_etc_forward_distance4",
        traffic_signal_etc_front_location_select:
            "traffic_signal_etc_front_location_select",
        traffic_signal_etc_front_location_select2:
            "traffic_signal_etc_front_location_select2",
        traffic_signal_etc_front_location_select3:
            "traffic_signal_etc_front_location_select3",
        traffic_signal_etc_front_location_select4:
            "traffic_signal_etc_front_location_select4",
        traffic_signal_etc_front_location_distance:
            "traffic_signal_etc_front_location_distance",
        traffic_signal_etc_front_location_distance2:
            "traffic_signal_etc_front_location_distance2",
        traffic_signal_etc_front_location_distance3:
            "traffic_signal_etc_front_location_distance3",
        traffic_signal_etc_front_location_distance4:
            "traffic_signal_etc_front_location_distance4",
        visibility_factor_building: "visibility_factor_building",
        visibility_factor_building2: "visibility_factor_building2",
        visibility_factor_building3: "visibility_factor_building3",
        visibility_factor_building4: "visibility_factor_building4",
        visibility_factor_sign_post: "visibility_factor_sign_post",
        visibility_factor_sign_post2: "visibility_factor_sign_post2",
        visibility_factor_sign_post3: "visibility_factor_sign_post3",
        visibility_factor_sign_post4: "visibility_factor_sign_post4",
        parking_area: "parking_area",
        parkingtsubo_area: "parkingtsubo_area",
        parking_capacity: "parking_capacity",
        parking_tangent_line_frontage: "parking_tangent_line_frontage",
        parking_tangent_line_sideway_a: "parking_tangent_line_sideway_a",
        parking_tangent_line_sideway_b: "parking_tangent_line_sideway_b",
        parking_tangent_line_backstreet: "parking_tangent_line_backstreet",
        no1_entrance_frontage: "no1_entrance_frontage",
        no1_entrance_sideway_a: "no1_entrance_sideway_a",
        no1_entrance_sideway_b: "no1_entrance_sideway_b",
        no1_entrance_backstreet: "no1_entrance_backstreet",
        no1_depth_frontage: "no1_depth_frontage",
        no1_depth_sideway_a: "no1_depth_sideway_a",
        no1_depth_sideway_b: "no1_depth_sideway_b",
        no1_depth_backstreet: "no1_depth_backstreet",
        no2_entrance_frontage: "no2_entrance_frontage",
        no2_entrance_sideway_a: "no2_entrance_sideway_a",
        no2_entrance_sideway_b: "no2_entrance_sideway_b",
        no2_entrance_backstreet: "no2_entrance_backstreet",
        no2_depth_frontage: "no2_depth_frontage",
        no2_depth_sideway_a: "no2_depth_sideway_a",
        no2_depth_sideway_b: "no2_depth_sideway_b",
        no2_depth_backstreet: "no2_depth_backstreet",
        complete_schedule_year: "complete_schedule_year",
        complete_schedule_month: "complete_schedule_month",
    },
    location_traffic_5mcut: {
        others_value_1: "others_value_1",
        others_value_2: "others_value_2",
        others_facility_3: "others_facility_3",
        others_name_3: "others_name_3",
        others_distance_3: "others_distance_3",
        others_value_3: "others_value_3",
        others_facility_4: "others_facility_4",
        others_name_4: "others_name_4",
        others_distance_4: "others_distance_4",
        others_value_4: "others_value_4",
    },
    location_traffic: {
        plan_area_id: "plan_area_id",
        branch: "branch",
        traffic_type: "traffic_type",
        traffic_type_seq: "traffic_type_seq",
        create_date: "create_date",
        update_date: "update_date",
        delete_flag: "delete_flag",
        counter_uuid: "counter_uuid",
        count_date: "count_date",
        count_time: "count_time",
        front_traffic_jam_time: "front_traffic_jam_time",
        side_a_traffic_jam_time: "side_a_traffic_jam_time",
        count_type: "count_type",
        car_front_standard: "car_front_standard",
        car_front_light: "car_front_light",
        car_front_large: "car_front_large",
        car_side_a_standard: "car_side_a_standard",
        car_side_a_light: "car_side_a_light",
        car_side_a_large: "car_side_a_large",
        car_side_b_standard: "car_side_b_standard",
        car_side_b_light: "car_side_b_light",
        car_side_b_large: "car_side_b_large",
        car_back_standard: "car_back_standard",
        car_back_light: "car_back_light",
        car_back_large: "car_back_large",
        people_5m_in_male_65over: "people_5m_in_male_65over",
        people_5m_in_female_65over: "people_5m_in_female_65over",
        people_5m_in_male_other: "people_5m_in_male_other",
        people_5m_in_female_other: "people_5m_in_female_other",
        people_5m_in_student: "people_5m_in_student",
        people_5m_in_bicycle: "people_5m_in_bicycle",
        people_5m_out_male_65over: "people_5m_out_male_65over",
        people_5m_out_female_65over: "people_5m_out_female_65over",
        people_5m_out_male_other: "people_5m_out_male_other",
        people_5m_out_female_other: "people_5m_out_female_other",
        people_5m_out_student: "people_5m_out_student",
        people_5m_out_bicycle: "people_5m_out_bicycle",
    },
    location_conflict : {
        conflict_type: "conflict_type",
        conflict_sequence: "conflict_sequence",
        create_date: "create_date",        
        update_date: "update_date",
        type: "type",
        delete_flag: "delete_flag",
        conflict_id: "conflict_id",
        road_shape: "road_shape",
        road_distance: "road_distance",
        direct_distance: "direct_distance",
        location_conflict_type: "location_conflict_type",
        include_commercial_area: "include_commercial_area",
        bland_name: "bland_name",
        tenpo_name: "tenpo_name",
        prefectures: "prefectures",
        tenpo_address: "tenpo_address",
        conflict_business_type : "conflict_business_type",
        business_hours: "business_hours",
        closing_time: "closing_time",
        handle_category_liquor: "handle_category_liquor",
        handle_category_tobacco: "handle_category_tobacco",
        location: "location",
        sales_floor_area_size: "sales_floor_area_size",
        gondola_scale: "gondola_scale",
        handle_category_parking: "handle_category_parking",
        store_frontage: "store_frontage",
        parking_area_size:"parking_area_size",
        number_of_parking: "number_of_parking",
        front_parking_tangent: "front_parking_tangent",
        front_first_entrance: "front_first_entrance",
        front_first_depth: "front_first_depth",
        front_second_entrance: "front_second_entrance",
        front_second_depth: "front_second_depth",
        side_road_type: "side_road_type",
        side_parking_tangent: "side_parking_tangent",
        side_first_entrance: "side_first_entrance",
        side_first_depth: "side_first_depth",
        side_second_entrance: "side_second_entrance",
        side_second_depth: "side_second_depth",
        estimated_daily_sales: "estimated_daily_sales",
        plan_area_shared_road: "plan_area_shared_road",
        plan_area_direction: "plan_area_direction",
        conflict_shared_road: "conflict_shared_road",
        conflict_median_strip: "conflict_median_strip",
        count_left_right_turns: "count_left_right_turns",
        count_straight: "count_left_right_turns",
        market_cut: "market_cut",
        bland_code: "bland_code",
        conductor_not_included: "conductor_not_included",
    },
    location_impact: {
        store_position: "store_position",
        common_road: "common_road",
        plan_median_strip: "plan_median_strip",
        store_median_strip: "store_median_strip",
        straight_count: "straight_count",
        turn_count: "turn_count",
        railroad_crossing: "railroad_crossing",
        river_width: "river_width",
        crossing_road_count: "crossing_road_count",
        conflict_sej: "conflict_sej",
        conflict_main_cvs: "conflict_main_cvs",
        conflict_other_cvs: "conflict_other_cvs",
        near_store_code: "near_store_code",
        average_common_ratio: "average_common_ratio",
        road_distance: "road_distance",
        salesfloor: "salesfloor",
        parkingarea: "parkingarea",
        car_front_store_count: "car_front_store_count",
        car_total_share_ratio: "car_total_share_ratio",
        people_front_store_count: "people_front_store_count",
        people_total_share_ratio: "people_total_share_ratio",
        tobaccolicense: "tobaccolicense",
    },
    location_drawing: {
        plan_area_id: "plan_area_id",
        branch: "branch",
        create_date: "create_date",
        update_date: "update_date",
        delete_flag: "delete_flag",
        upload_file_paths: "upload_file_paths",
        is_green_gem: "is_green_gem",
        is_grave: "is_grave",
        is_median_strip: "is_median_strip",
        is_bus_stop_blackout: "is_bus_stop_blackout",
        is_in_green_gem: "is_in_green_gem",
        is_other_tenant_parking: "is_other_tenant_parking",
        is_expressway_etc_pole: "is_expressway_etc_pole",
        is_bus_lane_etc: "is_bus_lane_etc",
        is_electricity_pole: "is_electricity_pole",
        is_sidewalk: "is_sidewalk",
        is_one_way_street: "is_one_way_street",
        is_tram_track: "is_tram_track",
        is_electricity_pole_branch_line: "is_electricity_pole_branch_line",
        is_crosswalk: "is_crosswalk",
        is_no_entry_road: "is_no_entry_road",
        is_parking_meter: "is_parking_meter",
        is_high_voltage_towers: "is_high_voltage_towers",
        is_guardrail: "is_guardrail",
        is_waterway_etc: "is_waterway_etc",
        is_others_place: "is_others_place",
        is_hydrant_etc: "is_hydrant_etc",
        is_street_trees_etc: "is_street_trees_etc",
        is_railroad_crossing: "is_railroad_crossing",
        is_old_road: "is_old_road",
        is_billboard_tower_etc: "is_billboard_tower_etc",
        is_street_lights_etc: "is_street_lights_etc",
        is_bridge: "is_bridge",
        is_unpaved_road: "is_unpaved_road",
        is_garbage_dump: "is_garbage_dump",
        is_road_signs: "is_road_signs",
        is_overpass: "is_overpass",
        is_unmaintained_etc: "is_unmaintained_etc",
        is_other: "is_other",
        other_long_text: "other_long_text",
        hand_drawn_layout: "hand_drawn_layout",
        hand_drawn_layout_total: "hand_drawn_layout_total",
        hand_drawn_layout_total_tsubo: "hand_drawn_layout_total_tsubo",
    },
} as tableColumnType;
export const getTableColumnName = (key: string, coulmn: string) => {
    return key in table_column_name ? table_column_name[key][coulmn] : "";
};
