import { createSlice } from '@reduxjs/toolkit';
import { initCoverObj } from '../data/type';

//Sliceの作成
export const coverSlice = createSlice({
    name: 'cover',
    //初期State(Typeからインポート)
    initialState: {
        obj: initCoverObj,
        planAreaInfo: {},
    },
    //toolKitを利用した値変更処理
    reducers: {
        setObjCover: (state, action) => {
            state.obj = action.payload;
        },
        //候補地情報取り込みボタン押下時の更新項目
        setPlanAreaInfo:(state,action) => { 
            state.obj.address = action.payload.address;
            state.obj.contract_due_date = action.payload.contract_due_date;
            state.obj.open_due_date = action.payload.open_due_date;
            state.obj.new_area_no = action.payload.new_area_no;
            state.obj.survey_creator = action.payload.survey_creator;
            state.obj.confirmer = action.payload.confirmer;
            state.obj.survey_name = action.payload.survey_name;
            state.obj.prefectures_code = action.payload.prefectures_code;
            state.obj.branch_no = action.payload.branch_no;
        }
    }
});

export const {
    setObjCover,
    setPlanAreaInfo
} = coverSlice.actions;
export default coverSlice.reducer;

