import { LOCAL_CONSTANT } from "../../Const";

export const ImpactLabel = () => {
    return [
        [
            {
                size: LOCAL_CONSTANT.NUMBER.SIX,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "ルート変更",
                keyName: "change_route",
                inputType: "update-route",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "change",
                keyName: "update",
                inputType: "update",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "削除",
                keyName: "delete",
                inputType: "delete",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: LOCAL_CONSTANT.LABEL.STORE_NO,
                keyName: "store_code",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: LOCAL_CONSTANT.LABEL.STORE_NO,
                keyName: "store_code",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: LOCAL_CONSTANT.LABEL.STORE_NAME,
                keyName: "tenpo_name",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: LOCAL_CONSTANT.LABEL.STORE_NAME,
                keyName: "tenpo_name",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "道なり距離",
                keyName: "road_distance",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "道なり距離",
                keyName: "road_distance",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "売場面積",
                keyName: "salesfloor",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "売場面積",
                keyName: "salesfloor",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "駐車場面積",
                keyName: "parkingarea",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "駐車場面積",
                keyName: "parkingarea",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "たばこ取扱",
                keyName: "tobaccolicense",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "たばこ取扱",
                keyName: "tobaccolicense",
                inputType: "codeSelect",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "既存店位置",
                keyName: "store_position",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "既存店位置",
                keyName: "store_position",
                inputType: "select",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "車",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "店前総数",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "店前総数",
                keyName: "car_front_store_count",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "累積共通比",
                keyName: "competitiveTarget",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "累積共通比",
                keyName: "car_total_share_ratio",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "人",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "店前総数",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "店前総数",
                keyName: "people_front_store_count",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "累積共通比",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "累積共通比",
                keyName: "people_total_share_ratio",
                inputType: "disabledInput",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "候補店共通道路",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "候補店共通道路",
                keyName: "common_road",
                inputType: "select",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "候補店\n中央分離帯高さ",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "候補店\n中央分離帯高さ",
                keyName: "plan_median_strip",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "既存店\n中央分離帯高さ",
                keyName: "label",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "既存店\n中央分離帯高さ",
                keyName: "store_median_strip",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "経路",
                keyName: "store_median_strip",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "直進回数",
                keyName: "straight_count",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "直進回数",
                keyName: "straight_count",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "右左折回数",
                keyName: "turn_count",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "右左折回数",
                keyName: "turn_count",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                newLine: "label",
                inputType: "newLine",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "線路横断",
                keyName: "railroad_crossing",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "線路横断",
                keyName: "railroad_crossing",
                inputType: "select",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "河川幅",
                keyName: "river_width",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "河川幅",
                keyName: "river_width",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "横断車線数合計",
                keyName: "crossing_road_count",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "横断車線数合計",
                keyName: "crossing_road_count",
                inputType: "input",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                Divider: "label",
                inputType: "Divider",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "経路競合",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "セブンイレブン",
                keyName: "conflict_sej",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "セブンイレブン",
                keyName: "conflict_sej",
                inputType: "select",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "LW/FM\nセイコーマート",
                keyName: "conflict_main_cvs",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "LW/FM\nセイコーマート",
                keyName: "conflict_main_cvs",
                inputType: "select",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "その他CVS",
                keyName: "conflict_other_cvs",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "その他CVS",
                keyName: "conflict_other_cvs",
                inputType: "select",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWENTEEN,
                labelName: "",
                newLine: "label",
                inputType: "newLine",
            },
        ],
        [
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "",
                keyName: "label",
                inputType: "header",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.ONE,
                labelName: "周辺既存店店番",
                keyName: "average_common_ratio",
                inputType: "label",
            },
            {
                size: LOCAL_CONSTANT.NUMBER.TWO,
                labelName: "周辺既存店店番",
                keyName: "average_common_ratio",
                inputType: "input",
            },
        ],
    ];
};

export const DISTANCE = 5;
export const GRAY_COLOR = "#FFFFFF";
export const GREEN_COLOR = "#FFCCCC";
export const YELLOW_COLOR = "#FFFF99";
export const CAR_COLOR = "#0000FF";
export const PEOPLE_COLOR = "#00FF00";
