import { getFileData, getFileMetadata } from "../utility/storage";
import { apiRequest } from "../api/ApiCall"

// トークン取得
export const getAccessToken = () => {
    let access_token = "?access_token=";
    const objectJSON = sessionStorage.getItem("token");
    if (objectJSON) {
        access_token = "?access_token=" + JSON.parse(objectJSON) + "";
    }

    return access_token;
};

// スタッフ名取得
export const staffName = (code: string) => {
    let name = "";
    const res: any = localStorage.getItem("staffMaster");
    if (res) {
        // const d = JSON.parse(res)
        JSON.parse(res)?.map((row: any) => {
            if (row.staff_number === code) {
                name = row.staff_name;
            }
        });
    }

    return name;
};

export const setSelecterDisplay = (codeMaster: any, code_value: string) => {
    let code_display = "";
    if (codeMaster && code_value !== "" && code_value !== undefined) {
        codeMaster.filter((row: any) => {
            if (row.code_value === code_value.toString()) {
                code_display = row.code_display;
            }
        });
    }
    return code_display;
};

export const setSelecterValue = (codeMaster: any, code_display: string) => {
    let code_value = "";
    if (codeMaster && code_display !== "" && code_display !== undefined) {
        codeMaster.filter((row: any) => {
            if (row.code_display === code_display.toString()) {
                code_value = row.code_value;
            }
        });
    }
    return code_value;
};

/**
 * ダウンロードファイル名指定でファイルダウンロード
 * @param filePath ダウンロードパス
 * @param fileName ファイル名
 */
export const commonFileDownload = (filePath: string, fileName: string) => {
    (async () => {
        try {
            const url = await getFileData(filePath);
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = (event) => {
                const blob = xhr.response;
                const link = document.createElement("a");
                document.body.appendChild(link);
                link.download = fileName;
                link.href = window.URL.createObjectURL(blob);
                link.click();
                document.body.removeChild(link);
            };
            xhr.open("GET", url as string);
            xhr.send();
        } catch (e) {
            console.log(e);
        }
    })();
};

/**
 * アップロードファイル名でファイルダウンロード
 * @param filePath ダウンロードパス
 */
export const commonFileDownloadFileName = (filePath: string) => {
    (async () => {
        try {
            getFileMetadata(filePath).then((metaData: any) => {
                getFileData(filePath).then((url: any) => {
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = (event) => {
                        const blob = xhr.response;
                        const link = document.createElement("a");
                        document.body.appendChild(link);
                        link.download = metaData.name;
                        link.href = window.URL.createObjectURL(blob);
                        link.click();
                        document.body.removeChild(link);
                    };
                    xhr.open("GET", url as string);
                    xhr.send();
                });
            });
        } catch (e) {
            console.log(e);
        }
    })();
};

/**
 * 各パラメータに対してのAPIリクエストを実行
 * @param params APIに送るパラメータのリスト
 */
export const updateListRegist = (params: any) => {
    return new Promise((resolve, reject) => {
        params.map((param: any) => {
            (async () => {
                try {
                    await apiRequest(param);
                    resolve(true);
                } catch (e) {
                    console.log(e);
                    reject();
                }
            })();
        })
    })
}