import { LOCAL_CONSTANT } from "../../Const";

export const dateDisplayByType = (
    row: any,
    trafficType: string,
    type: string
) => {
    const date = new Date(row.count_date);
    let fullYear = Number(date.getFullYear());
    let month = Number(date.getMonth() + 1);
    let day = Number(date.getDate());
    let dayOfWeek = Number(date.getDay());

    let result = "";
    const dayname = ["日", "月", "火", "水", "木", "金", "土"];

    if (
        trafficType !== LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal &&
        trafficType !== LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal &&
        trafficType !== LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.typeVal &&
        trafficType !== LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.typeVal
    ) {
        if (type === "yearday") {
            result =
                fullYear.toString() +
                "/" +
                month.toString().padStart(2, "0") +
                "/" +
                day.toString().padStart(2, "0") +
                "（" +
                dayname[dayOfWeek] +
                "）";
        } else if (type === "year") {
            result = fullYear.toString();
        } else if (type === "day") {
            result =
                month.toString().padStart(2, "0") +
                "/" +
                day.toString().padStart(2, "0") +
                "（" +
                dayname[dayOfWeek] +
                "）";
        }
    }

    return result;
};

export const timeDisplay = (row: any, trafficType: string) => {
    const date = new Date(row.count_date);
    let hours = Number(date.getHours());
    let minutes = Number(date.getMinutes());
    let afterHours = hours;
    let afterMinutes = 0;
    if (Number(row.count_time) >= 60) {
        afterHours += Math.floor(Number(row.count_time) / 60);
        afterMinutes =
            minutes +
            (Number(row.count_time) -
                Math.floor(Number(row.count_time) / 60) * 60);
    } else {
        afterMinutes = minutes + Number(row.count_time);
        if (afterMinutes >= 60) {
            afterHours += 1;
            afterMinutes = afterMinutes - 60;
        }
    }
    let result = "";

    if (
        trafficType !== LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal &&
        trafficType !== LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal &&
        trafficType !== LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.typeVal &&
        trafficType !== LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.typeVal
    ) {
        result =
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0") +
            "～" +
            afterHours.toString().padStart(2, "0") +
            ":" +
            afterMinutes.toString().padStart(2, "0");
    }

    return result;
};

export const carValue = (
    value: any,
    target: string,
    trafficType: any,
    nightRatio: number,
    holidaysRatio: number
) => {
    let result = value;
    if (
        target !== "front_traffic_jam_time" &&
        target !== "side_a_traffic_jam_time"
    ) {
        if (
            trafficType === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal
        ) {
            result = Math.round(value * nightRatio * 100) / 100;
        } else if (
            trafficType === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal
        ) {
            result = Math.round(value * holidaysRatio * 100) / 100;
        }
    } else {
        if (
            trafficType ===
                LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal ||
            trafficType === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal
        ) {
            result = "";
        }
    }

    return result;
};

export const carAvg = (
    trafficData: any,
    target: string,
    trafficType: any,
    nightRatio: number,
    holidaysRatio: number
) => {
    let count = 0;
    let sum = 0;
    let avgValue = "";
    trafficData.map((dataRow: any) => {
        sum += Number(dataRow[target]);
        count++;
    });

    if (sum > 0 && sum / count > 0) {
        avgValue = (Math.round((sum * 10) / count) / 10).toString();
        if (
            target !== "front_traffic_jam_time" &&
            target !== "side_a_traffic_jam_time"
        ) {
            if (
                trafficType ===
                LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal
            ) {
                avgValue = (
                    Math.round((sum * nightRatio * 100) / count) / 100
                ).toString();
            } else if (
                trafficType ===
                LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal
            ) {
                avgValue = (
                    Math.round((sum * holidaysRatio * 100) / count) / 100
                ).toString();
            }
        } else if (
            target === "front_traffic_jam_time" ||
            target === "side_a_traffic_jam_time"
        ) {
            avgValue =
                sum / count > 0
                    ? (Math.round((sum * 100) / count) / 100).toString()
                    : "0";
        } else {
            if (
                trafficType ===
                    LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal ||
                trafficType ===
                    LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal
            ) {
                avgValue = "";
            }
        }
    } else {
        avgValue = "0";
    }

    return avgValue;
};

export const peopleAvg = (
    trafficData: any,
    targetKey: string,
    target: string,
    trafficType: string,
    nightRatio: number,
    holidaysRatio: number
) => {
    let count = 0;
    let sum = 0;
    let avgValue = 0;
    trafficData.map((row: any) => {
        sum += addition(row, targetKey, target);
        count = count + 1;
    });
    if (sum > 0 && sum / count > 0) {
        avgValue = sum / count;
        if (
            trafficType === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.typeVal
        ) {
            avgValue = Math.round((sum * nightRatio * 100) / count) / 100;
        } else if (
            trafficType === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.typeVal
        ) {
            avgValue = Math.round((sum * holidaysRatio * 100) / count) / 100;
        }
    }
    if (targetKey === "bicycle") {
        let countAll = 0;
        let sumAll = 0;
        let avgValueAll = 0;
        let allAvgValue = 0;
        // 合計平均値の算出
        trafficData.map((row: any) => {
            sumAll += addition(row, "sumAll", target);
            countAll = countAll + 1;
        });
        if (sumAll > 0 && sumAll / countAll > 0) {
            avgValueAll = sumAll / countAll;
            if (
                trafficType ===
                LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.typeVal
            ) {
                avgValueAll =
                    Math.round((sumAll * nightRatio * 100) / countAll) / 100;
            } else if (
                trafficType ===
                LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.typeVal
            ) {
                avgValueAll =
                    Math.round((sumAll * holidaysRatio * 100) / countAll) / 100;
            }
            // 自転車平均値 / 合計平均値 * 100（四捨五入）
            allAvgValue = Math.round((avgValue / avgValueAll) * 100);
        }
        return avgValue + "\n（" + allAvgValue + "%）";
    }

    return avgValue;
};

export const peopleAddition = (
    value: any,
    trafficData: any,
    targetKey: string,
    target: string,
    trafficType: string,
    nightRatio: number,
    holidaysRatio: number
) => {
    let sum = value;
    if (value === "" && targetKey !== "sumAll") {
        sum = "";
    } else {
        sum = addition(trafficData, targetKey, target);
        if (
            trafficType === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.typeVal
        ) {
            sum = Math.round(sum * nightRatio * 100) / 100;
        } else if (
            trafficType === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.typeVal
        ) {
            sum = Math.round(sum * holidaysRatio * 100) / 100;
        }
    }

    return sum;
};

export const addition = (row: any, targetKey: string, target: string) => {
    let sum = 0;
    switch (targetKey) {
        case "male_65over":
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN)) {
                sum += Number(row.people_5m_in_male_65over);
            }
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT)) {
                sum += Number(row.people_5m_out_male_65over);
            }
            break;
        case "female_65over":
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN)) {
                sum += Number(row.people_5m_in_female_65over);
            }
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT)) {
                sum += Number(row.people_5m_out_female_65over);
            }
            break;
        case "male_other":
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN)) {
                sum += Number(row.people_5m_in_male_other);
            }
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT)) {
                sum += Number(row.people_5m_out_male_other);
            }
            break;
        case "female_other":
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN)) {
                sum += Number(row.people_5m_in_female_other);
            }
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT)) {
                sum += Number(row.people_5m_out_female_other);
            }
            break;
        case "student":
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN)) {
                sum += Number(row.people_5m_in_student);
            }
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT)) {
                sum += Number(row.people_5m_out_student);
            }
            break;
        case "bicycle":
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN)) {
                sum += Number(row.people_5m_in_bicycle);
            }
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT)) {
                sum += Number(row.people_5m_out_bicycle);
            }
            break;
        case "sumAll":
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN)) {
                sum += Number(row.people_5m_in_male_65over);
                sum += Number(row.people_5m_in_female_65over);
                sum += Number(row.people_5m_in_male_other);
                sum += Number(row.people_5m_in_female_other);
                sum += Number(row.people_5m_in_student);
            }
            if (target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT)) {
                sum += Number(row.people_5m_out_male_65over);
                sum += Number(row.people_5m_out_female_65over);
                sum += Number(row.people_5m_out_male_other);
                sum += Number(row.people_5m_out_female_other);
                sum += Number(row.people_5m_out_student);
            }
            break;
    }
    return sum;
};

export const hasChecked = (val: number) => {
    return val > 0 ? true : false;
};

export const borderStyle = (width: number, height: number) => {
    return {
        width: width + "%",
        height: height + "px",
        border: "solid 1px rgba(224, 224, 224, 1)",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        whiteSpace: "pre-wrap",
    };
};

export const borderStyleDisabled = (width: number, height: number) => {
    return {
        width: width + "%",
        height: height + "px",
        border: "solid 1px rgba(224, 224, 224, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#BBBBBB",
    };
};

export const borderStyleColor = (width: number, height: number) => {
    return {
        width: width + "%",
        height: height + "px",
        backgroundColor: "#D9D9D9",
        color: "black",
        border: "solid 1px rgba(224, 224, 224, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };
};

export const headerStyle = (width: number, height: number) => {
    return {
        minWidth: width + "%",
        maxWidth: width + "%",
        height: height + "px",
        padding: "0",
        backgroundColor: "#D9D9D9",
        color: "black",
        whiteSpace: "pre-wrap",
    };
};

export const headerStyleWithBorder = (width: number, height: number) => {
    return {
        width: width + "%",
        minWidth: width + "%",
        maxWidth: width + "%",
        height: height + "px",
        padding: "0",
        backgroundColor: "#D9D9D9",
        color: "black",
        whiteSpace: "pre-wrap",
        borderLeft: "solid 2px rgba(100, 100, 100, 1)",
    };
};

export const hasDisabled = (
    row: any,
    target: string,
    userInputData: any,
    separateValue: string
) => {
    let disabled = false;
    const date = new Date(row.count_date);
    let hours = Number(date.getHours());
    let minutes = Number(date.getMinutes());
    let afterHours = hours;
    let afterMinutes = minutes + Number(row.count_time);
    if (afterMinutes >= 60) {
        afterHours += 1;
        afterMinutes = afterMinutes - 60;
    }
    const beforTime = Number(
        hours.toString().padStart(2, "0") + minutes.toString().padStart(2, "0")
    );
    const afterTime = Number(
        afterHours.toString().padStart(2, "0") +
            afterMinutes.toString().padStart(2, "0")
    );
    switch (target) {
        case "morning":
            if (userInputData[target].checked === true) {
                disabled = true;
            } else if (beforTime < 900 || afterTime > 1030) {
                disabled = true;
            }
            break;
        case "noon":
            if (userInputData[target].checked === true) {
                disabled = true;
            } else if (beforTime < 1300 || afterTime > 1500) {
                disabled = true;
            } else if (row.Holidays !== 0) {
                disabled = true;
            }
            break;
        case "night":
            if (["0", "2"].includes(separateValue)) {
                disabled = true;
            } else if (userInputData[target].checked === true) {
                disabled = true;
            } else if (beforTime < 2000 || afterTime > 2130) {
                disabled = true;
            } else if (row.Holidays !== 0) {
                disabled = true;
            }
            break;
        case "Holidays":
            if (["0", "1"].includes(separateValue)) {
                disabled = true;
            } else if (userInputData[target].checked === true) {
                disabled = true;
            } else if (beforTime < 1030 || afterTime > 1630) {
                disabled = true;
            } else if (row.noon !== 0) {
                disabled = true;
            }
            if (row.night_TR) {
                if (row.night_TR !== 0) {
                    disabled = true;
                }
            }
            break;
        case "morning_TR":
            if (userInputData[target].checked === true) {
                disabled = true;
            } else if (beforTime < 730 || afterTime > 830) {
                disabled = true;
            }
            break;
        case "noon_TR":
            if (userInputData[target].checked === true) {
                disabled = true;
            } else if (beforTime < 1200 || afterTime > 1300) {
                disabled = true;
            }
            break;
        case "night_TR":
            if (userInputData[target].checked === true) {
                disabled = true;
            } else if (beforTime < 1600 || afterTime > 1730) {
                disabled = true;
            } else if (row.Holidays !== 0) {
                disabled = true;
            }
            break;
        default:
            disabled = true;
            break;
    }

    return disabled;
};

export const setParam = (planAreaId: string | null, trafficData: any) => {
    const updateParam: any = [];
    let car_front_standard = "";
    let car_side_a_standard = "";
    let car_side_b_standard = "";
    let car_back_standard = "";
    trafficData.map((row: any) => {
        car_front_standard = row.car_front_standard.toString();
        car_side_a_standard = row.car_side_a_standard.toString();
        car_side_b_standard = row.car_side_b_standard.toString();
        car_back_standard = row.car_back_standard.toString();
        car_front_standard = (
            Number(row.car_front_total) -
            (Number(row.car_front_light) + Number(row.car_front_large))
        ).toString();
        car_side_a_standard = (
            Number(row.car_side_a_total) -
            (Number(row.car_side_a_light) + Number(row.car_side_a_large))
        ).toString();
        car_side_b_standard = (
            Number(row.car_side_b_total) -
            (Number(row.car_side_b_light) + Number(row.car_side_b_large))
        ).toString();
        car_back_standard = (
            Number(row.car_back_total) -
            (Number(row.car_back_light) + Number(row.car_back_large))
        ).toString();
        updateParam.push({
            plan_area_id: row.plan_area_id.toString(),
            branch: row.branch.toString(),
            traffic_type: row.traffic_type.toString(),
            traffic_type_seq: row.traffic_type_seq.toString(),
            counter_uuid: row.counter_uuid.toString(),
            count_date: row.count_date.toString(),
            count_time: row.count_time.toString(),
            count_type: row.count_type.toString(),
            front_traffic_jam_time: row.front_traffic_jam_time.toString(),
            side_a_traffic_jam_time: row.side_a_traffic_jam_time.toString(),
            car_front_standard: car_front_standard.toString(),
            car_front_light: row.car_front_light.toString(),
            car_front_large: row.car_front_large.toString(),
            car_side_a_standard: car_side_a_standard.toString(),
            car_side_a_light: row.car_side_a_light.toString(),
            car_side_a_large: row.car_side_a_large.toString(),
            car_side_b_standard: car_side_b_standard.toString(),
            car_side_b_light: row.car_side_b_light.toString(),
            car_side_b_large: row.car_side_b_large.toString(),
            car_back_standard: car_back_standard.toString(),
            car_back_light: row.car_back_light.toString(),
            car_back_large: row.car_back_large.toString(),
            people_5m_in_male_65over: row.people_5m_in_male_65over.toString(),
            people_5m_in_female_65over:
                row.people_5m_in_female_65over.toString(),
            people_5m_in_male_other: row.people_5m_in_male_other.toString(),
            people_5m_in_female_other: row.people_5m_in_female_other.toString(),
            people_5m_in_student: row.people_5m_in_student.toString(),
            people_5m_in_bicycle: row.people_5m_in_bicycle.toString(),
            people_5m_out_male_65over: row.people_5m_out_male_65over.toString(),
            people_5m_out_female_65over:
                row.people_5m_out_female_65over.toString(),
            people_5m_out_male_other: row.people_5m_out_male_other.toString(),
            people_5m_out_female_other:
                row.people_5m_out_female_other.toString(),
            people_5m_out_student: row.people_5m_out_student.toString(),
            people_5m_out_bicycle: row.people_5m_out_bicycle.toString(),
        });
    });

    return {
        mode: "regist",
        endPoint: "/location/Traffic/v1/update",
        query: {
            planAreaId: planAreaId,
            data: updateParam,
        },
    };
};

export const addTrafficParams = (
    planAreaId: string,
    branch: string,
    traffic_type: string,
    type_seq: string,
    count_type: string,
    count_date: string
) => {
    return {
        plan_area_id: planAreaId,
        branch: branch,
        traffic_type: traffic_type.toString(),
        traffic_type_seq: type_seq.toString(),
        counter_uuid: "",
        count_date: count_date.toString(),
        count_time: "15",
        front_traffic_jam_time: "0",
        side_a_traffic_jam_time: "0",
        count_type: count_type.toString(),
        car_front_standard: "0",
        car_front_light: "0",
        car_front_large: "0",
        car_side_a_standard: "0",
        car_side_a_light: "0",
        car_side_a_large: "0",
        car_side_b_standard: "0",
        car_side_b_light: "0",
        car_side_b_large: "0",
        car_back_standard: "0",
        car_back_light: "0",
        car_back_large: "0",
        people_5m_in_male_65over: "0",
        people_5m_in_female_65over: "0",
        people_5m_in_male_other: "0",
        people_5m_in_female_other: "0",
        people_5m_in_student: "0",
        people_5m_in_bicycle: "0",
        people_5m_out_male_65over: "0",
        people_5m_out_female_65over: "0",
        people_5m_out_male_other: "0",
        people_5m_out_female_other: "0",
        people_5m_out_student: "0",
        people_5m_out_bicycle: "0",
    };
};

export const addRequestParams = (
    objLongPageParam: any,
    row: any,
    trafficType: string,
    type_seq: string,
    inOut: boolean
) => {
    return {
        plan_area_id: objLongPageParam.planAreaId.toString(),
        branch: objLongPageParam.branch.toString(),
        traffic_type: trafficType.toString(),
        traffic_type_seq: type_seq.toString(),
        counter_uuid: row.uuid.toString(),
        count_date: row.count_date.toString(),
        count_time: row.count_time.toString(),
        front_traffic_jam_time: row.front_traffic_jam_time.toString(),
        side_a_traffic_jam_time: row.side_a_traffic_jam_time.toString(),
        count_type: row.count_type.toString(),
        car_front_standard: row.car_front_standard.toString(),
        car_front_light: row.car_front_light.toString(),
        car_front_large: row.car_front_large.toString(),
        car_side_a_standard: row.car_side_a_standard.toString(),
        car_side_a_light: row.car_side_a_light.toString(),
        car_side_a_large: row.car_side_a_large.toString(),
        car_side_b_standard: row.car_side_b_standard.toString(),
        car_side_b_light: row.car_side_b_light.toString(),
        car_side_b_large: row.car_side_b_large.toString(),
        car_back_standard: row.car_back_standard.toString(),
        car_back_light: row.car_back_light.toString(),
        car_back_large: row.car_back_large.toString(),
        people_5m_in_male_65over: row.people_5m_in_male_65over.toString(),
        people_5m_in_female_65over: row.people_5m_in_female_65over.toString(),
        people_5m_in_male_other: row.people_5m_in_male_other.toString(),
        people_5m_in_female_other: row.people_5m_in_female_other.toString(),
        people_5m_in_student: row.people_5m_in_student.toString(),
        people_5m_in_bicycle: row.people_5m_in_bicycle.toString(),
        people_5m_out_male_65over:
            inOut === true ? row.people_5m_out_male_65over.toString() : "0",
        people_5m_out_female_65over:
            inOut === true ? row.people_5m_out_female_65over.toString() : "0",
        people_5m_out_male_other:
            inOut === true ? row.people_5m_out_male_other.toString() : "0",
        people_5m_out_female_other:
            inOut === true ? row.people_5m_out_female_other.toString() : "0",
        people_5m_out_student:
            inOut === true ? row.people_5m_out_student.toString() : "0",
        people_5m_out_bicycle:
            inOut === true ? row.people_5m_out_bicycle.toString() : "0",
    };
};

export const initCountDate = (date: Date, hours: string, minutes: string) => {
    return (
        date.getFullYear().toString() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0") +
        " " +
        hours.toString().padStart(2, "0") +
        ":" +
        minutes.toString().padStart(2, "0") +
        ":00.001"
    );
};

export const locationTrafficTotal = (data: any) => {
    const copyData = JSON.parse(JSON.stringify(data));
    copyData.map((row: any) => {
        row.car_front_total =
            row.car_front_standard + row.car_front_light + row.car_front_large;
        row.car_side_a_total =
            row.car_side_a_standard +
            row.car_side_a_light +
            row.car_side_a_large;
        row.car_side_b_total =
            row.car_side_b_standard +
            row.car_side_b_light +
            row.car_side_b_large;
        row.car_back_total =
            row.car_back_standard + row.car_back_light + row.car_back_large;
    });

    return copyData;
};

export const upsertLocationTraffic5mcutParam = (
    object: any,
    plan_area_id: any,
    branch: any,
    stationObject: any,
    schoolObject: any
) => {
    const params = {
        mode: "regist",
        endPoint: "/location/TrafficVolume/v1/regist-locationTraffic5mcut",
        query: {
            plan_area_id: plan_area_id,
            branch: branch,
            create_date: object.create_date,
            update_date: object.update_date,
            delete_flag: object.delete_flag,
            excel_path: object.excel_path,
            layout_img_path: object.layout_img_path,
            photo_img_path: object.photo_img_path,
            check_planting: object.check_planting,
            check_visibility: object.check_visibility,
            check_stairs: object.check_stairs,
            check_count: object.check_count,
            car_another_survey: object.car_another_survey,
            people_another_survey: object.people_another_survey,
            cut_survey: object.cut_survey,
            others_facility_1: object.others_facility_1,
            others_name_1: stationObject.station_entrance_name,
            others_distance_1: (
                Math.round(Number(stationObject.road_distance)) / 1000
            ).toString(),
            others_value_1: object.others_value_1,
            others_facility_2: object.others_facility_2,
            others_name_2: schoolObject.display_name,
            others_distance_2: schoolObject.trade_area_value,
            others_value_2: object.others_value_2,
            others_facility_3: object.others_facility_3,
            others_name_3: object.others_name_3,
            others_distance_3: object.others_distance_3,
            others_value_3: object.others_value_3,
            others_facility_4: object.others_facility_4,
            others_name_4: object.others_name_4,
            others_distance_4: object.others_distance_4,
            others_value_4: object.others_value_4,
            approval_confirm_flag: object.approval_confirm_flag,
            approval_confirm_user: object.approval_confirm_user,
            approval_confirm_department: object.approval_confirm_department,
            approval_confirm_date: object.approval_confirm_date,
            approval_confirm_stamp: object.approval_confirm_stamp,
            approval_research_mgr_flag: object.approval_research_mgr_flag,
            approval_research_mgr_user: object.approval_research_mgr_user,
            approval_research_mgr_department:
                object.approval_research_mgr_department,
            approval_research_mgr_date: object.approval_research_mgr_date,
            approval_research_mgr_stamp: object.approval_research_mgr_stamp,
            approval_research_gm_flag: object.approval_research_gm_flag,
            approval_research_gm_user: object.approval_research_gm_user,
            approval_research_gm_department:
                object.approval_research_gm_department,
            approval_research_gm_date: object.approval_research_gm_date,
            approval_research_gm_stamp: object.approval_research_gm_stamp,
            approval_mgr_flag: object.approval_mgr_flag,
            approval_mgr_user: object.approval_mgr_user,
            approval_mgr_department: object.approval_mgr_department,
            approval_mgr_date: object.approval_mgr_date,
            approval_mgr_stamp: object.approval_mgr_stamp,
            approval_gm_flag: object.approval_gm_flag,
            approval_gm_user: object.approval_gm_user,
            approval_gm_department: object.approval_gm_department,
            approval_gm_date: object.approval_gm_date,
            approval_gm_stamp: object.approval_gm_stamp,
        },
    };

    return params;
};

export const checkSelectTraffic = (
    registered: number,
    input: any,
    userInput: any,
    timeZone: string
) => {
    let regist = "";
    if (
        registered > 0 &&
        ((registered === 1 && input > 1 && userInput === true) ||
            (registered === 2 && (input > 0 || userInput === true)))
    ) {
        regist =
            LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_ADD_TRAFFIC.replace(
                "_TIME_",
                timeZone
            ).replace("_COUNT_", registered.toString());
    } else if (input === 2 && userInput === true) {
        regist =
            LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_ADD_OVER_TRAFFIC.replace(
                "_TIME_",
                timeZone
            );
    }

    return regist;
};

export const checkTrafficTime = (row: any, targetTraffic: any) => {
    let color = "#FFFFFF";

    if (row.uuid === targetTraffic.counter_uuid) {
        color = "#FFFF00";
    } else {
        color = hasTime(row, targetTraffic.traffic_type);
    }

    return color;
};

export const hasTime = (row: any, traffic_type: string) => {
    let color = "#FFFFFF";
    const date = new Date(row.count_date);
    const startTime =
        date.getHours().toString().padStart(2, "0") +
        date.getMinutes().toString().padStart(2, "0");
    const checkTime =
        Number(date.getMinutes().toString().padStart(2, "0")) +
        Number(row.count_time.toString().padStart(2, "0"));
    let endTime =
        date.getHours().toString().padStart(2, "0") +
        checkTime.toString().padStart(2, "0");
    if (checkTime >= 60) {
        const minutes = checkTime - 60;
        endTime =
            (date.getHours() + 1).toString().padStart(2, "0") +
            minutes.toString().padStart(2, "0");
    }
    let startTarget = 0;
    let endTarget = 0;
    switch (traffic_type) {
        case "0":
        case "6":
        case "morning":
            startTarget = 900;
            endTarget = 1030;
            break;
        case "1":
        case "8":
        case "noon":
            startTarget = 1300;
            endTarget = 1500;
            break;
        case "2":
        case "10":
        case "night":
            startTarget = 2000;
            endTarget = 2130;
            break;
        case "4":
        case "13":
        case "holidays":
            startTarget = 1030;
            endTarget = 1630;
            break;
        case "7":
        case "morning_TR":
            startTarget = 730;
            endTarget = 830;
            break;
        case "9":
        case "noon_TR":
            startTarget = 1200;
            endTarget = 1300;
            break;
        case "12":
        case "night_TR":
            startTarget = 1600;
            endTarget = 1730;
            break;
    }
    if (Number(startTime) < startTarget || Number(endTime) > endTarget) {
        color = "#cccccc";
    }

    return color;
};

export const changeParams = (
    changeTraffic: any,
    targetTraffic: any,
    planAreaId: any,
    inOut: boolean
) => {
    const updateParam = {
        counter_uuid: changeTraffic.uuid.toString(),
        count_date: changeTraffic.count_date.toString(),
        count_time: changeTraffic.count_time.toString(),
        count_type: changeTraffic.count_type.toString(),
        front_traffic_jam_time: changeTraffic.front_traffic_jam_time.toString(),
        side_a_traffic_jam_time:
            changeTraffic.side_a_traffic_jam_time.toString(),
        car_front_standard: changeTraffic.car_front_standard.toString(),
        car_front_light: changeTraffic.car_front_light.toString(),
        car_front_large: changeTraffic.car_front_large.toString(),
        car_side_a_standard: changeTraffic.car_side_a_standard.toString(),
        car_side_a_light: changeTraffic.car_side_a_light.toString(),
        car_side_a_large: changeTraffic.car_side_a_large.toString(),
        car_side_b_standard: changeTraffic.car_side_b_standard.toString(),
        car_side_b_light: changeTraffic.car_side_b_light.toString(),
        car_side_b_large: changeTraffic.car_side_b_large.toString(),
        car_back_standard: changeTraffic.car_back_standard.toString(),
        car_back_light: changeTraffic.car_back_light.toString(),
        car_back_large: changeTraffic.car_back_large.toString(),
        people_5m_in_male_65over: inOut
            ? changeTraffic.people_5m_in_male_65over.toString()
            : "0",
        people_5m_in_female_65over: inOut
            ? changeTraffic.people_5m_in_female_65over.toString()
            : "0",
        people_5m_in_male_other: inOut
            ? changeTraffic.people_5m_in_male_other.toString()
            : "0",
        people_5m_in_female_other: inOut
            ? changeTraffic.people_5m_in_female_other.toString()
            : "0",
        people_5m_in_student: inOut
            ? changeTraffic.people_5m_in_student.toString()
            : "0",
        people_5m_in_bicycle: inOut
            ? changeTraffic.people_5m_in_bicycle.toString()
            : "0",
        people_5m_out_male_65over: inOut
            ? changeTraffic.people_5m_out_male_65over.toString()
            : "0",
        people_5m_out_female_65over: inOut
            ? changeTraffic.people_5m_out_female_65over.toString()
            : "0",
        people_5m_out_male_other: inOut
            ? changeTraffic.people_5m_out_male_other.toString()
            : "0",
        people_5m_out_female_other: inOut
            ? changeTraffic.people_5m_out_female_other.toString()
            : "0",
        people_5m_out_student: inOut
            ? changeTraffic.people_5m_out_student.toString()
            : "0",
        people_5m_out_bicycle: inOut
            ? changeTraffic.people_5m_out_bicycle.toString()
            : "0",
        plan_area_id: targetTraffic.plan_area_id.toString(),
        branch: targetTraffic.branch.toString(),
        traffic_type: targetTraffic.traffic_type.toString(),
        traffic_type_seq: targetTraffic.traffic_type_seq.toString(),
    };
    const params = {
        mode: "regist",
        endPoint: "/location/Traffic/v1/update",
        query: {
            planAreaId: planAreaId,
            data: [updateParam],
        },
    };

    return params;
};
