export const mailDetail = (target: string, params: any) => {
    let detail = {
        title: "",
        body: "",
        type: "",
        staffNoList: [],
        plan_area_id: "",
        branch: "",
    };
    switch (target) {
        case "requestEmail_5mOutsideMarketCut":
            detail.title = `【立地調査】${params.survey_name}　${params.titleDetail}`;
            detail.body = `以下URLより「${params.titleDetail}」をご確認の上、承認・差戻の登録をお願いします。\n\n`;
            detail.body += `ＵＲＬ：${params.url}\n\n`;
            detail.body += `担当RFC：${params.rfc}\n\n`;
            detail.body += `確認担当者：${params.confirmationPerson}`;
            detail.type = params.type;
            detail.staffNoList = params.staffNoList;
            detail.plan_area_id = params.plan_area_id;
            detail.branch = params.branch;
            break;
        case "returnEmail_5mOutsideMarketcut":
            detail.title = `【立地調査】${params.survey_name}　${params.titleDetail}差戻`;
            detail.body = `${params.survey_name}の「${params.titleDetail}」が差戻されました。\n\n`;
            detail.body += `確認をお願いします。\n\n`;
            detail.body += `差戻者：${params.operator}\n\n`;
            detail.body += `差戻項目：${params.returnedItems}\n\n`;
            detail.body += `担当RFC：${params.rfc}\n\n`;
            detail.body += `確認担当者：${params.confirmationPerson}`;
            detail.type = params.type;
            detail.staffNoList = params.staffNoList;
            detail.plan_area_id = params.plan_area_id;
            detail.branch = params.branch;
            break;
    }

    return detail;
};
