import { createSlice } from '@reduxjs/toolkit';
import {
    initStoreOpeningConditionsObj,
    initStoreOpeningConditionsShowLongTextObj,
} from '../data/type';

//Sliceの作成
export const storeOpeningConditionsSlice = createSlice({
    name: 'storeOpeningConditions',
    //初期State(Typeからインポート)
    initialState: {
        obj: initStoreOpeningConditionsObj,
        initObj: initStoreOpeningConditionsObj,
        showAddItems: false,
        showLongText: initStoreOpeningConditionsShowLongTextObj,
        showPreviousJob: false,
        monthSelect: [],
        sixMonthPassed: '',
        monthHeader: []
    },
    //toolKitを利用した値変更処理
    reducers: {
        setObjStoreOpeningConditions: (state, action) => {
            state.obj = action.payload;
        },
        setInitObjStoreOpeningConditions: (state, action) => {
            state.initObj = action.payload;
        },
        setClosingDays: (state, action) => {
            state.obj.closing_days = action.payload.closing_days;
        },
        setStoreOpeningConditionsShowAddItems:(state, action) => {
            state.showAddItems = action.payload;
        },
        setStoreOpeningConditionsLongTextShow: (state, action) => {
            state.showLongText = action.payload;
        },
        setShowPreviousJob: (state, action) => {
            state.showPreviousJob = action.payload;
        },
        setMonthSelect:(state,action) => {
            state.monthSelect = action.payload;
        },
        setSixMonthPassed:(state,action) => {
            state.sixMonthPassed = action.payload;
        },
        setMonthHeder:(state, action) => {
            state.monthHeader = action.payload;
        },
        setAchievementAcquisition: (state, action) => {
            state.obj.achievement_acquisition_start = action.payload.achievement_acquisition_start;
            state.obj.achievement_acquisition_end = action.payload.achievement_acquisition_end;
        },
        setNippanOnYearAverage: (state, action) => {
            state.obj.last_one_year_nippan_average = action.payload.last_one_year_nippan_average;
            state.obj.year_on_year_average = action.payload.year_on_year_average;
        }
    }
});

export const {
    setObjStoreOpeningConditions,
    setInitObjStoreOpeningConditions,
    setClosingDays,
    setStoreOpeningConditionsShowAddItems,
    setStoreOpeningConditionsLongTextShow,
    setShowPreviousJob,
    setMonthSelect,
    setSixMonthPassed,
    setMonthHeder,
    setAchievementAcquisition,
    setNippanOnYearAverage
} = storeOpeningConditionsSlice.actions;
export default storeOpeningConditionsSlice.reducer;