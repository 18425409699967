import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import { LOCAL_CONSTANT } from "../../Const";
import {
    Box,
    Button,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormControl,
} from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EditIcon from "@mui/icons-material/Edit";
import {
    checkListDisabled,
    validationCheck,
    sortLocationConflictData,
} from "./AddConflictFunction";
import { useUtilityContext } from "../../../utility-provider";
import { apiRequest } from "../../../api/ApiCall";
import {
    CVS_MASTER_DATA,
    SM_DRUG_MASTER_DATA,
} from "../../ResearchReport/Conflict";
import Comment from "../../components/Comment";
import { setPermissionFunc } from "../../components/PermissionFunc";
import MessageDialog from "../../components/MessageDialog";
import { setUnitEdit } from "../../../features/longPageSlice";
import { userLogging } from "../../../utility/firestore";
import { useUserContext } from "../../../user-provider";
import {
    ownStoreType,
    ownStoreTypeSMDS,
    columnListCVS,
    columnListSMDS,
} from "./ConflictData";
import OwnStore from "./OwnStore";
import ConflictListDetails from "./ConflictListDetails";

export interface conflictListProps {
    props: {
        ownStore: any;
        locationConflict: any;
        setReload: any;
        type: string;
        targetRadius: string;
        userType: string | null;
    };
}

const inactive = {
    minHeight: "50px",
    height: "50px",
    minWidth: "240px",
    width: "240px",
    padding: "10px 0",
    border: "1px solid black",
    backgroundColor: "rgba(0, 0, 0, 0.06)",
};

const hours = [
    { code_value: "00", code_display: "0" },
    { code_value: "01", code_display: "1" },
    { code_value: "02", code_display: "2" },
    { code_value: "03", code_display: "3" },
    { code_value: "04", code_display: "4" },
    { code_value: "05", code_display: "5" },
    { code_value: "06", code_display: "6" },
    { code_value: "07", code_display: "7" },
    { code_value: "08", code_display: "8" },
    { code_value: "09", code_display: "9" },
    { code_value: "10", code_display: "10" },
    { code_value: "11", code_display: "11" },
    { code_value: "12", code_display: "12" },
    { code_value: "13", code_display: "13" },
    { code_value: "14", code_display: "14" },
    { code_value: "15", code_display: "15" },
    { code_value: "16", code_display: "16" },
    { code_value: "17", code_display: "17" },
    { code_value: "18", code_display: "18" },
    { code_value: "19", code_display: "19" },
    { code_value: "20", code_display: "20" },
    { code_value: "21", code_display: "21" },
    { code_value: "22", code_display: "22" },
    { code_value: "23", code_display: "23" },
];
const minutes = [
    { code_value: "00", code_display: "0" },
    { code_value: "15", code_display: "15" },
    { code_value: "30", code_display: "30" },
    { code_value: "45", code_display: "45" },
];

const sortList = [
    { code_value: "conflict_sequence", code_display: "登録順" },
    { code_value: "direct_distance", code_display: "距離順" },
];

export const ConflictList: React.FC<conflictListProps> = ({ props }) => {
    const user = useUserContext();
    const utilityCtx = useUtilityContext();
    const dispatch = useAppDispatch();

    const [draw, setDraw] = React.useState<boolean>(false);
    const [codeMaster, setCodeMaster] = React.useState<any>();
    const [storeData, setStoreData] = React.useState<any>(undefined);
    const [conflictData, setConflictData] = React.useState<any>([]);
    const [editStore, setEditStore] = React.useState<boolean>(false);
    const [editConflict, setEditConflict] = React.useState<boolean>(false);
    const [editDisabled, setEditDisabled] = React.useState<boolean>(true);
    const [originStoreData, setOriginStoreData] =
        React.useState<any>(undefined);
    const [originConflictData, setOriginConflictData] = React.useState<any>([]);

    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    // 表示色変更用BQログデータ
    const objRrBranchLog = useAppSelector((state) => state.longPage.rrBranchLog);
    // 単体保存ステータス
    const objUnitEdit = useAppSelector((state) => state.longPage.unitEdit);
    // タスクIDを取得するため
    const objLocationBase = useAppSelector(
        (state) => state.longPage.locationBase
    );
    // 表紙
    const objCover = useAppSelector((state) => state.cover.obj);
    // 出店条件
    const objStoreOpeningConditions = useAppSelector(
        (state) => state.storeOpeningConditions.obj
    );
    // 寄付
    const objDonation = useAppSelector((state) => state.donation.obj);
    const [messageDialogOpen, setMessageDialogOpen] =
        React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");
    const [hasButton, setHasButton] = React.useState<boolean>(false);
    const [conflictSort, setConflictSort] =
        React.useState<string>("conflict_sequence");

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    const header = () => {
        const tmp: any = [];
        conflictData?.map((row: any) => {
            tmp.push({
                key:
                    LOCAL_CONSTANT.LABEL.CONFLICT_STORES +
                    row.conflict_sequence.toString(),
                label:
                    LOCAL_CONSTANT.LABEL.CONFLICT_STORES +
                    row.conflict_sequence.toString(),
            });
        });

        return tmp;
    };

    const CVSLabel = () => {
        //requireStatusが0が任意、1が必須でアスタリスクが1つ,2が条件合致時必須でアスタリスクが2つ
        if (props.type === CVS_MASTER_DATA) {
            return ownStoreType(objCover, storeData, objDonation, objStoreOpeningConditions)
        } else {
            return ownStoreTypeSMDS(objCover, storeData, objDonation, objStoreOpeningConditions)
        }
    };

    const columnList = () => {
        if (props.type === CVS_MASTER_DATA) {
            return columnListCVS()
        } else {
            return columnListSMDS()
        }
    };

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster");
        if (res) {
            const d = JSON.parse(res);
            setCodeMaster(d);
        }

        document
            .getElementById("outerContentRef" + props.type)
            ?.addEventListener("scroll", outerContentRefFunc);
        document
            .getElementById("innerContentRef" + props.type)
            ?.addEventListener("scroll", innerContentRefFunc);
    }, []);

    React.useEffect(() => {
        if (props.locationConflict) {
            const copyData = JSON.parse(JSON.stringify(props.locationConflict));
            setConflictData(sortLocationConflictData(copyData, conflictSort));
        }
    }, [props.locationConflict]);

    React.useEffect(() => {
        if (props.ownStore) {
            setStoreData(props.ownStore);
        }
    }, [props.ownStore]);

    const clickHandleEditStore = () => {
        // 競合CVS 自店 編集フラグ更新
        if (!objUnitEdit.ConflictCVSOwn)
            dispatch(setUnitEdit({ ...objUnitEdit, ConflictCVSOwn: true }));
        setEditStore(true);
        const copyData = JSON.parse(JSON.stringify(storeData));
        setOriginStoreData(copyData);
    };

    const cancelEditStore = () => {
        // 競合CVS 自店 編集フラグ更新
        if (objUnitEdit.ConflictCVSOwn)
            dispatch(setUnitEdit({ ...objUnitEdit, ConflictCVSOwn: false }));
        setEditStore(false);
        setStoreData(originStoreData);
    };

    const registEditStore = () => {
        // 競合CVS 自店 編集フラグ更新
        if (objUnitEdit.ConflictCVSOwn)
            dispatch(setUnitEdit({ ...objUnitEdit, ConflictCVSOwn: false }));
        setEditStore(false);
        updateRegist(
            [storeData],
            LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_CONFLICT_SEJ_UPDATE
        );
    };

    const clickHandleEditConflict = (type: string) => {
        // 競合 編集フラグ更新
        switch (type) {
            case "cvs-master-data":
                if (!objUnitEdit.ConflictCVS)
                    dispatch(
                        setUnitEdit({ ...objUnitEdit, ConflictCVS: true })
                    );
                break; //競合CVS 競合店
            case "sm-drug-master-data":
                if (!objUnitEdit.ConflictSM)
                    dispatch(setUnitEdit({ ...objUnitEdit, ConflictSM: true }));
                break; //競合SM 競合
        }
        setEditConflict(true);
        setEditDisabled(false);
        const copyData = JSON.parse(JSON.stringify(conflictData));
        setOriginConflictData(copyData);
    };

    const cancelEditConflict = (type: string) => {
        // 競合 編集フラグ更新
        switch (type) {
            case "cvs-master-data":
                if (objUnitEdit.ConflictCVS)
                    dispatch(
                        setUnitEdit({ ...objUnitEdit, ConflictCVS: false })
                    );
                break; //競合CVS 競合店
            case "sm-drug-master-data":
                if (objUnitEdit.ConflictSM)
                    dispatch(
                        setUnitEdit({ ...objUnitEdit, ConflictSM: false })
                    );
                break; //競合SM 競合
        }
        setEditConflict(false);
        setEditDisabled(true);
        setConflictData(originConflictData);
    };

    const registEditConflict = (type: string) => {
        // バリデーションチェック
        const response = validationCheck(conflictData);
        if (response && response.length > 0) {
            let tempMessage = "";
            response.map((row: any) => {
                tempMessage += row;
            });
            setMessage(tempMessage);
            setMessageDialogOpen(true);
            setHasButton(true);
        } else {
            setEditConflict(false);
            setEditDisabled(true);
            updateRegist(
                conflictData,
                LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_CONFLICT_UPDATE
            );
        }
        // 競合 編集フラグ更新
        switch (type) {
            case "cvs-master-data":
                if (objUnitEdit.ConflictCVS)
                    dispatch(
                        setUnitEdit({ ...objUnitEdit, ConflictCVS: false })
                    );
                break; //競合CVS 競合店
            case "sm-drug-master-data":
                if (objUnitEdit.ConflictSM)
                    dispatch(
                        setUnitEdit({ ...objUnitEdit, ConflictSM: false })
                    );
                break; //競合SM 競合
        }
    };

    const updateRegist = (data: any, function_name: string) => {
        const params = {
            mode: "regist",
            endPoint: "/location/Conflict/v1/update",
            query: {
                planAreaId: objLongPageParam.planAreaId,
                data: data,
            },
        };
        // userLogging
        userLogging(function_name, params, user);

        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                props.setReload(true);
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    const checkEditDisabled = (data: any, columnDisabled: string) => {
        let disabled = false;
        if (editDisabled) {
            disabled = true;
        } else {
            if (columnDisabled !== "") {
                disabled = checkListDisabled(data, columnDisabled);
            }
        }
        return disabled;
    };

    // スクロールイベント
    const outerContentRefFunc = () => {
        const outerContentRef = document.getElementById(
            "outerContentRef" + props.type
        );
        const innerContentRef = document.getElementById(
            "innerContentRef" + props.type
        );
        if (outerContentRef && innerContentRef) {
            innerContentRef.scrollLeft = outerContentRef.scrollLeft;
        }
    };
    const innerContentRefFunc = () => {
        const outerContentRef = document.getElementById(
            "outerContentRef" + props.type
        );
        const innerContentRef = document.getElementById(
            "innerContentRef" + props.type
        );
        if (innerContentRef && outerContentRef) {
            outerContentRef.scrollLeft = innerContentRef.scrollLeft;
        }
    };

    const [commentOpen, setCommentOpen] = React.useState<boolean>(false);
    const [text, setText] = React.useState<string>("");

    const storeCommentOpen = () => {
        setText("conflict_stores"); //競合自店
        setCommentOpen(true);
    };

    const clickCommentOpen = () => {
        let text = "conflict_s_m"; //競合SM
        if (props.type === CVS_MASTER_DATA) {
            text = "conflict_store"; //競合店
        }
        setText(text);
        setCommentOpen(true);
    };

    const handleSaveComment = () => {
        setCommentOpen(false);
    };

    const sortLocationConflict = (e: SelectChangeEvent<string>) => {
        setConflictSort(e.target.value);
        setConflictData(sortLocationConflictData(conflictData, e.target.value));
    };

    return (
        <Box
            sx={{
                width: "100%",
                border: "1px solid black",
                display: "flex",
                justifyContent: "start",
            }}
        >
            <MessageDialog
                props={{
                    open: messageDialogOpen,
                    handleModal: closeMessageDialog,
                    body: message,
                    hasButton: hasButton,
                    buttonBody: LOCAL_CONSTANT.LABEL.CLOSE,
                }}
            />
            <Comment
                props={{
                    open: commentOpen,
                    params: {
                        table_name: "location_conflict",
                        column_name: text,
                        comment: "",
                    },
                    handleModal: setCommentOpen,
                    handleSaveComment: handleSaveComment,
                }}
            />
            <Box sx={{ width: "380px", margin: "4px" }}>
                <Box
                    sx={{
                        minWidth: "100%",
                        width: "100%",
                        height: "90px",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    {/* 競合CVS 自店　不備コメントボタン */}
                    {props.type === CVS_MASTER_DATA && (
                        <Button
                            onClick={storeCommentOpen}
                            disabled={setPermissionFunc(
                                props.userType,
                                objLocationBase.task_id,
                                LOCAL_CONSTANT.NAVI.CONFLICT,
                                "own_deficiency_comment_button"
                            )}
                        >
                            <ChatBubbleIcon />
                        </Button>
                    )}
                    <Box
                        sx={{
                            display:
                                props.type === SM_DRUG_MASTER_DATA
                                    ? "none"
                                    : "flex",
                        }}
                    >
                        <Button
                            onClick={clickHandleEditStore}
                            disabled={setPermissionFunc(
                                props.userType,
                                objLocationBase.task_id,
                                LOCAL_CONSTANT.NAVI.CONFLICT,
                                "own_edit_button"
                            )}
                            sx={{
                                display: editStore === false ? "block" : "none",
                            }}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            onClick={cancelEditStore}
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{
                                display: editStore === false ? "none" : "block",
                                margin: "14px 10px",
                            }}
                        >
                            {LOCAL_CONSTANT.LABEL.CANCEL}
                        </Button>
                        <Button
                            onClick={registEditStore}
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                                display: editStore === false ? "none" : "block",
                                margin: "14px 10px",
                            }}
                        >
                            {LOCAL_CONSTANT.LABEL.SAVE}
                        </Button>
                    </Box>
                </Box>
                <OwnStore props={{
                    type: props.type,
                    ownStoreList: CVSLabel(),
                    storeData: storeData,
                    editStore: editStore,
                    codeMaster: codeMaster,
                    editStoreDisabled: false,
                    draw: draw,
                    setDraw: setDraw,
                }} />
            </Box>
            <Box sx={{ width: "66%", margin: "0 4px" }}>
                <Box sx={{ display: "flex" }}>
                    <FormControl
                        sx={{ m: 1, minWidth: 120, padding: "10px 0" }}
                        size="small"
                    >
                        <Select
                            onChange={(e) => {
                                sortLocationConflict(e);
                            }}
                            value={conflictSort}
                        >
                            {sortList.map((val, i) => (
                                <MenuItem key={i} value={val.code_value}>
                                    {val.code_display}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box
                        sx={{
                            width: "100%",
                            height: "76px",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <Button
                            onClick={clickCommentOpen}
                            disabled={setPermissionFunc(
                                props.userType,
                                objLocationBase.task_id,
                                LOCAL_CONSTANT.NAVI.CONFLICT,
                                "conflict_deficiency_comment_button"
                            )}
                        >
                            <ChatBubbleIcon />
                        </Button>
                        <Box sx={{ display: "flex" }}>
                            <Button
                                onClick={() =>
                                    clickHandleEditConflict(props.type)
                                }
                                disabled={setPermissionFunc(
                                    props.userType,
                                    objLocationBase.task_id,
                                    LOCAL_CONSTANT.NAVI.CONFLICT,
                                    "conflict_edit_button"
                                )}
                                sx={{
                                    display:
                                        editConflict === false
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <EditIcon />
                            </Button>
                            <Button
                                onClick={() => cancelEditConflict(props.type)}
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{
                                    display:
                                        editConflict === false
                                            ? "none"
                                            : "block",
                                    margin: "10px",
                                }}
                            >
                                {LOCAL_CONSTANT.LABEL.CANCEL}
                            </Button>
                            <Button
                                onClick={() => registEditConflict(props.type)}
                                variant="contained"
                                color="success"
                                size="small"
                                sx={{
                                    display:
                                        editConflict === false
                                            ? "none"
                                            : "block",
                                    margin: "10px",
                                }}
                            >
                                {LOCAL_CONSTANT.LABEL.SAVE}
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        overflowX: "scroll",
                        overflowY: "clip",
                    }}
                    id={"outerContentRef" + props.type}
                >
                    <Box sx={{ display: "flex" }}>
                        {header().map((row: any) => (
                            <Box key={row.key}>
                                <Typography
                                    sx={{
                                        minWidth: "240px",
                                        width: "240px",
                                        padding: "1px 0",
                                        fontSize: "0.01rem",
                                    }}
                                >
                                    {row.label}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        overflowX: "scroll",
                        overflowY: "clip",
                        textAlign: "center",
                    }}
                    id={"innerContentRef" + props.type}
                >
                    <Box>
                        <Box sx={{ display: "flex" }}>
                            {header().map((row: any) => (
                                <Box key={row.key}>
                                    <Typography
                                        sx={{
                                            minHeight: "50px",
                                            height: "50px",
                                            minWidth: "240px",
                                            width: "240px",
                                            padding: "10px 0",
                                            border: "1px solid black",
                                            backgroundColor: "#D9D9D9",
                                            color: "black",
                                        }}
                                    >
                                        {row.label}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <ConflictListDetails props={{
                            detailList: columnList(),
                            conflictData: conflictData,
                            type: props.type,
                            codeMaster: codeMaster,
                            editConflict: editConflict,
                            editDisabled: editDisabled,
                            draw: draw,
                            setDraw: setDraw,
                            targetRadius: props.targetRadius,
                            ownStore: props.ownStore
                        }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ConflictList;
