import * as React from 'react';

import { initAddImpactObj } from '../../../data/type';
import { point2distance } from "../../../utility/actionTurf";
import { putMarker } from "./viewDataFunction";


/**
 * 既存店、候補地描画処理
 * @param existingSejStoreList 
 * @param map 
 * @param onClick 
 */
export const drawExistingSejPlanArea = (
    list: any,
    map: google.maps.Map,
    setExSejStoreList: any,
    planAreaList: any,
) => {
    const tmpMarker: any[] = [];
    list.forEach((data: any, index: any) => {
        try {
            let marker = null
            if (data.type === "0") {
                marker = putMarker(data, 'sej')
            } else if (data.type === "1") {
                marker = putMarker(data, 'locationPlanArea')
            }
            if (marker) {
                const result = point2distance(
                    new google.maps.LatLng(planAreaList[0].centerlat, planAreaList[0].centerlon),
                    new google.maps.LatLng(data.centerlat, data.centerlon)
                )
                data.selectedMode = false
                data = { ...data, ...initAddImpactObj };
                data.directDistance = result
                data.roadDistance = 0
                marker.setMap(map);

                if (data.type === "1") {
                    // 
                    const location_excel_03_data = JSON.parse(data.location_excel_03_data)
                    if (location_excel_03_data[0][0] !== null) {

                    }
                }
                tmpMarker.push({
                    key: data.type === "0" ? data.store_code : data.id,
                    type: data.type,
                    data: data,
                    marker: marker,
                    selectImpact: null,
                })
            }

        } catch (error) {
            console.log("------------------------")
            console.log("既存店表示エラー")
            console.log(error)
            console.log(data)
            console.log("------------------------")
        }
    })

    setExSejStoreList(tmpMarker);

    return tmpMarker
}

/**
 * 前回表示分のmarker、polygonの差分を非表示にする
 * @param diffList 
 * @param list 
 */
const diffClear = (diffList: any, list: any) => {
    list?.forEach(function (item: any) {
        diffList?.forEach(function (diffItem: any) {
            if (item.key === diffItem.store_code) {
                item.marker.setMap(null);
            }
        })
    });
}

export interface existingSejPlanAreaProps {
    props: {
    }
}

const ExistingSejPlanArea: React.FC<existingSejPlanAreaProps> = ({ props }) => {


    return (
        <></>
    );

}


export default ExistingSejPlanArea;
