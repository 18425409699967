import { LOCAL_CONSTANT } from "../../Const";

export const ownStoreType = (
    objCover: any,
    storeData: any,
    objDonation: any,
    objStoreOpeningConditions: any
) => {
    return [
        {
            key: 1,
            fontSize: "1rem",
            cols: 0,
            target: "data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: "",
            keyName: LOCAL_CONSTANT.LABEL.OWN_STORE,
            SELECT_KEY: "",
            requireStatus: 0,
        },
        {
            key: 2,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.COMPETITIVE_TARGET,
            keyName: "location_conflict_type",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 3,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.FIVE_MINUTES_WALK_WITHIN_COMMERCIAL_AREA,
            keyName: "include_commercial_area",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 4,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "bland_name",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.CHAIN_NAME,
            keyName: "bland_name",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 5,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: objCover["survey_name"],
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.STORE_NAME,
            keyName: "tenpo_name",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 6,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "business_hours",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.BUSINESS_HOURS,
            keyName: "business_hours",
            SELECT_KEY: "business_hours",
            requireStatus: 1,
        },
        {
            key: 7,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TIME-LABEL",
            labelName: LOCAL_CONSTANT.LABEL.CLOSING_TIME,
            keyName: "closing_time",
            SELECT_KEY: "closing_time",
            requireStatus: 2,
        },
        {
            key: 8,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "handle_category_liquor",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.LIQUOR_LICENSE,
            keyName: "handle_category_liquor",
            SELECT_KEY: "marubatu",
            requireStatus: 1,
        },
        {
            key: 9,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "handle_category_tobacco",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.TOBACCO_LICENSE,
            keyName: "handle_category_tobacco",
            SELECT_KEY: "marubatu",
            requireStatus: 1,
        },
        {
            key: 10,
            fontSize: "1rem",
            cols: 1,
            target: "db-select",
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.LOCATION,
            keyName: "location",
            SELECT_KEY: "location",
            requireStatus: 1,
        },
        {
            key: 11,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "sales_floor_area_size",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.SALES_FLOOR_AREA +
                "(" +
                LOCAL_CONSTANT.LABEL.SQUARE_METRE +
                ")",
            keyName: "sales_floor_area_size",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 12,
            fontSize: "1rem",
            cols: 1,
            target: "db-text",
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.GONDOLA_SCALE,
            keyName: "gondola_scale",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 13,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "handle_category_parking",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.PARKING_AVAILABLE,
            keyName: "handle_category_parking",
            SELECT_KEY: "common_yesnone_kana",
            requireStatus: 1,
        },
        {
            key: 14,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "store_frontage",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.STORE_FRONTAGE,
            keyName: "store_frontage",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 15,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "parking_area_size",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.PARKING_AREA +
                "(" +
                LOCAL_CONSTANT.LABEL.SQUARE_METRE +
                ")",
            keyName: "parking_area_size",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 16,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "number_of_parking",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.NUMBER_OF_PARKING_SPACES,
            keyName: "number_of_parking",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 17,
            fontSize: "1rem",
            cols: 2,
            target: "data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.FRONT,
            keyName: "front_parking_tangent",
            SELECT_KEY: "",
            colsLabel: [
                {
                    key: 1,
                    fontSize: "1rem",
                    value: storeValue(
                        "front_parking_tangent",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.PARKING_LOT_TANGENT,
                    keyName: "front_parking_tangent",
                    requireStatus: 1,
                },
                {
                    key: 2,
                    fontSize: "1rem",
                    value: storeValue(
                        "front_first_entrance",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.FIRST_ENTRANCE,
                    keyName: "front_first_entrance",
                    requireStatus: 1,
                },
                {
                    key: 3,
                    fontSize: "1rem",
                    value: storeValue(
                        "front_first_depth",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.FIRST_DEPTH,
                    keyName: "front_first_depth",
                    requireStatus: 1,
                },
                {
                    key: 4,
                    fontSize: "1rem",
                    value: storeValue(
                        "front_second_entrance",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.SECOND_ENTRANCE,
                    keyName: "front_second_entrance",
                    requireStatus: 2,
                },
                {
                    key: 5,
                    fontSize: "1rem",
                    value: storeValue(
                        "front_second_depth",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.SECOND_DEPTH,
                    keyName: "front_second_depth",
                    requireStatus: 2,
                },
            ],
        },
        {
            key: 18,
            fontSize: "1rem",
            cols: 1,
            target: "db-value",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.TYPE_OF_SIDE_ROAD,
            keyName: "side_road_type",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 19,
            fontSize: "1rem",
            cols: 2,
            target: "data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.SIDE +
                "（" +
                LOCAL_CONSTANT.LABEL.BACK +
                "）",
            keyName: "side_parking_tangent",
            SELECT_KEY: "",
            colsLabel: [
                {
                    key: 1,
                    fontSize: "1rem",
                    value: storeValue(
                        "side_parking_tangent",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.PARKING_LOT_TANGENT,
                    keyName: "side_parking_tangent",
                    requireStatus: 2,
                },
                {
                    key: 2,
                    fontSize: "1rem",
                    value: storeValue(
                        "side_first_entrance",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.FIRST_ENTRANCE,
                    keyName: "side_first_entrance",
                    requireStatus: 2,
                },
                {
                    key: 3,
                    fontSize: "1rem",
                    value: storeValue(
                        "side_first_depth",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.FIRST_DEPTH,
                    keyName: "side_first_depth",
                    requireStatus: 2,
                },
                {
                    key: 4,
                    fontSize: "1rem",
                    value: storeValue(
                        "side_second_entrance",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.SECOND_ENTRANCE,
                    keyName: "side_second_entrance",
                    requireStatus: 2,
                },
                {
                    key: 5,
                    fontSize: "1rem",
                    value: storeValue(
                        "side_second_depth",
                        storeData,
                        objDonation,
                        objStoreOpeningConditions
                    ),
                    labelName: LOCAL_CONSTANT.LABEL.SECOND_DEPTH,
                    keyName: "side_second_depth",
                    requireStatus: 2,
                },
            ],
        },
        {
            key: 20,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.ESTIMATED_DAILY_SALES +
                "(" +
                LOCAL_CONSTANT.LABEL.THOUSAND_YEN +
                ")",
            keyName: "estimated_daily_sales",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 21,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.DIRECT_DISTANCE +
                "(" +
                LOCAL_CONSTANT.LABEL.METRE +
                ")",
            keyName: "direct_distance",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 22,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.ROAD_DISTANCE +
                "(" +
                LOCAL_CONSTANT.LABEL.METRE +
                ")",
            keyName: "road_distance",
            SELECT_KEY: "",
            requireStatus: 2,
        },
        {
            key: 23,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.COMMON_ROAD_IN_FRONT_OF_CANDIDATE,
            keyName: "plan_area_shared_road",
            SELECT_KEY: "common__shared_road",
            requireStatus: 2,
        },
        {
            key: 24,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.DIRECTION,
            keyName: "plan_area_direction",
            SELECT_KEY: "store_position",
            requireStatus: 2,
        },
        {
            key: 25,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.COMMON_ROAD_BEFORE_COMPETITION,
            keyName: "conflict_shared_road",
            SELECT_KEY: "common__shared_road",
            requireStatus: 2,
        },
        {
            key: 26,
            fontSize: "0.8rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName:
                LOCAL_CONSTANT.LABEL
                    .PRESENCE_OF_COMMON_ROAD_SEPARATION_STRIP_BEFORE_COMPETITION,
            keyName: "conflict_median_strip",
            SELECT_KEY: "common_yesnone_kana",
            requireStatus: 2,
        },
        {
            key: 27,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.LEFT_RIGHT_TURN_INDICATOR,
            keyName: "count_left_right_turns",
            SELECT_KEY: "count_left_right_turns",
            requireStatus: 2,
        },
        {
            key: 28,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.STRAIGHT_GAUGE,
            keyName: "count_straight",
            SELECT_KEY: "count_straight",
            requireStatus: 2,
        },
    ];
};

export const ownStoreTypeSMDS = (
    objCover: any,
    storeData: any,
    objDonation: any,
    objStoreOpeningConditions: any
) => {
    return [
        {
            key: 1,
            fontSize: "1rem",
            cols: 0,
            target: "data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: "",
            keyName: LOCAL_CONSTANT.LABEL.OWN_STORE,
            SELECT_KEY: "",
            requireStatus: 0,
        },
        {
            key: 2,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.COMPETITIVE_TARGET,
            keyName: "location_conflict_type",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 3,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.FIVE_MINUTES_WALK_WITHIN_COMMERCIAL_AREA,
            keyName: "include_commercial_area",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 4,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "bland_name",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.CHAIN_NAME,
            keyName: "bland_name",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 5,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: objCover["survey_name"],
            type: "TEXT",
            labelName: LOCAL_CONSTANT.LABEL.STORE_NAME,
            keyName: "tenpo_name",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 6,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "business_hours",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.BUSINESS_HOURS,
            keyName: "business_hours",
            SELECT_KEY: "business_hours",
            requireStatus: 1,
        },
        {
            key: 7,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TIME-LABEL",
            labelName: LOCAL_CONSTANT.LABEL.CLOSING_TIME,
            keyName: "closing_time",
            SELECT_KEY: "closing_time",
            requireStatus: 2,
        },
        {
            key: 8,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "sales_floor_area_size",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.SALES_FLOOR_AREA +
                "(" +
                LOCAL_CONSTANT.LABEL.SQUARE_METRE +
                ")",
            keyName: "sales_floor_area_size",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 9,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "handle_category_parking",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.PARKING_AVAILABLE,
            keyName: "handle_category_parking",
            SELECT_KEY: "common_yesnone_kana",
            requireStatus: 1,
        },
        {
            key: 10,
            fontSize: "1rem",
            cols: 1,
            target: "data",
            value: storeValue(
                "parking_area_size",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.PARKING_AREA +
                "(" +
                LOCAL_CONSTANT.LABEL.SQUARE_METRE +
                ")",
            keyName: "parking_area_size",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 11,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.DIRECT_DISTANCE +
                "(" +
                LOCAL_CONSTANT.LABEL.METRE +
                ")",
            keyName: "direct_distance",
            SELECT_KEY: "",
            requireStatus: 1,
        },
        {
            key: 12,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "TEXT",
            labelName:
                LOCAL_CONSTANT.LABEL.ROAD_DISTANCE +
                "(" +
                LOCAL_CONSTANT.LABEL.METRE +
                ")",
            keyName: "road_distance",
            SELECT_KEY: "",
            requireStatus: 2,
        },
        {
            key: 13,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.COMMON_ROAD_IN_FRONT_OF_CANDIDATE,
            keyName: "plan_area_shared_road",
            SELECT_KEY: "common__shared_road",
            requireStatus: 2,
        },
        {
            key: 14,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.DIRECTION,
            keyName: "plan_area_direction",
            SELECT_KEY: "store_position",
            requireStatus: 2,
        },
        {
            key: 15,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.COMMON_ROAD_BEFORE_COMPETITION,
            keyName: "conflict_shared_road",
            SELECT_KEY: "common__shared_road",
            requireStatus: 2,
        },
        {
            key: 16,
            fontSize: "0.8rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName:
                LOCAL_CONSTANT.LABEL
                    .PRESENCE_OF_COMMON_ROAD_SEPARATION_STRIP_BEFORE_COMPETITION,
            keyName: "conflict_median_strip",
            SELECT_KEY: "common_yesnone_kana",
            requireStatus: 2,
        },
        {
            key: 17,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.LEFT_RIGHT_TURN_INDICATOR,
            keyName: "count_left_right_turns",
            SELECT_KEY: "count_left_right_turns",
            requireStatus: 2,
        },
        {
            key: 18,
            fontSize: "1rem",
            cols: 1,
            target: "gray-data",
            value: storeValue(
                "",
                storeData,
                objDonation,
                objStoreOpeningConditions
            ),
            type: "SELECT",
            labelName: LOCAL_CONSTANT.LABEL.STRAIGHT_GAUGE,
            keyName: "count_straight",
            SELECT_KEY: "count_straight",
            requireStatus: 2,
        },
    ];
};

export const columnListCVS = () => {
    return [
        {
            key: 1,
            keyName: "location_conflict_type",
            disabled: "",
            type: "location_conflict_type",
            labelName: "競合対象",
        },
        {
            key: 2,
            keyName: "include_commercial_area",
            disabled: "",
            type: "select",
            labelName: "徒歩5分商圏内外",
            SELECT_KEY: "include_commercial_area",
        },
        {
            key: 3,
            keyName: "bland_code",
            disabled: "",
            type: "select",
            labelName: "チェーン名",
            SELECT_KEY: "bland_code",
        },
        {
            key: 4,
            keyName: "tenpo_name",
            disabled: "",
            type: "text",
            labelName: "店名",
        },
        {
            key: 5,
            keyName: "business_hours",
            disabled: "",
            type: "select",
            labelName: "営業時間",
            SELECT_KEY: "business_hours",
        },
        {
            key: 6,
            keyName: "closing_time",
            disabled: "closing",
            type: "time-label",
            labelName: "閉店時間",
        },
        {
            key: 7,
            keyName: "handle_category_liquor",
            disabled: "",
            type: "select",
            labelName: "酒免許",
            SELECT_KEY: "marubatu",
        },
        {
            key: 8,
            keyName: "handle_category_tobacco",
            disabled: "",
            type: "select",
            labelName: "煙草免許",
            SELECT_KEY: "marubatu",
        },
        {
            key: 9,
            keyName: "location",
            disabled: "",
            type: "select",
            labelName: "立地",
            SELECT_KEY: "location",
        },
        {
            key: 10,
            keyName: "sales_floor_area_size",
            disabled: "",
            type: "text",
            labelName: "売場面積(㎡)",
        },
        {
            key: 11,
            keyName: "gondola_scale",
            disabled: "",
            type: "text",
            labelName: "ゴンドラ尺数",
        },
        {
            key: 12,
            keyName: "handle_category_parking",
            disabled: "",
            type: "select",
            labelName: "駐車場有無",
            SELECT_KEY: "common_yesnone_kana",
        },
        {
            key: 13,
            keyName: "store_frontage",
            disabled: "store_frontage",
            type: "text",
            labelName: "店舗間口",
        },
        {
            key: 14,
            keyName: "parking_area_size",
            disabled: "parking",
            type: "text",
            labelName: "駐車場面積(㎡)",
        },
        {
            key: 15,
            keyName: "number_of_parking",
            disabled: "parking",
            type: "text",
            labelName: "駐車場台数",
        },
        {
            key: 16,
            keyName: "front_parking_tangent",
            disabled: "front",
            type: "text",
            labelName: "前面駐車場接線(m)",
        },
        {
            key: 17,
            keyName: "front_first_entrance",
            disabled: "front",
            type: "text",
            labelName: "前面第一進入口(m)",
        },
        {
            key: 18,
            keyName: "front_first_depth",
            disabled: "front",
            type: "text",
            labelName: "前面第一奥行き(m)",
        },
        {
            key: 19,
            keyName: "front_second_entrance",
            disabled: "front",
            type: "text",
            labelName: "前面第二進入口(m)",
        },
        {
            key: 20,
            keyName: "front_second_depth",
            disabled: "front",
            type: "text",
            labelName: "前面第二奥行き(m)",
        },
        {
            key: 21,
            keyName: "side_road_type",
            disabled: "side_road_type",
            type: "codemaster-value",
            labelName: "側道有無種類",
        },
        {
            key: 22,
            keyName: "side_parking_tangent",
            disabled: "side",
            type: "text",
            labelName: "側道(裏道)駐車場接線(m)",
        },
        {
            key: 23,
            keyName: "side_first_entrance",
            disabled: "side",
            type: "text",
            labelName: "側道(裏道)第一進入口(m)",
        },
        {
            key: 24,
            keyName: "side_first_depth",
            disabled: "side",
            type: "text",
            labelName: "側道(裏道)第一奥行き(m)",
        },
        {
            key: 25,
            keyName: "side_second_entrance",
            disabled: "side",
            type: "text",
            labelName: "側道第二進入口(m)",
        },
        {
            key: 26,
            keyName: "side_second_depth",
            disabled: "side",
            type: "text",
            labelName: "側道第二奥行き(m)",
        },
        {
            key: 27,
            keyName: "estimated_daily_sales",
            disabled: "",
            type: "text",
            labelName: "推定日販(千円)",
        },
        {
            key: 28,
            keyName: "direct_distance",
            disabled: "",
            type: "round",
            labelName: "直線距離",
        },
        {
            key: 29,
            keyName: "road_distance",
            disabled: "road_distance",
            type: "round",
            labelName: "道なり距離",
        },
        {
            key: 30,
            keyName: "plan_area_shared_road",
            disabled: "plan_area_shared_road",
            type: "select",
            labelName: "候補前共通道路",
            SELECT_KEY: "common__shared_road",
        },
        {
            key: 31,
            keyName: "plan_area_direction",
            disabled: "plan_area_direction",
            type: "select",
            labelName: "方向",
            SELECT_KEY: "store_position",
        },
        {
            key: 32,
            keyName: "conflict_shared_road",
            disabled: "conflict_shared_road",
            type: "select",
            labelName: "競合前共通道路",
            SELECT_KEY: "common__shared_road",
        },
        {
            key: 33,
            keyName: "conflict_median_strip",
            disabled: "conflict_median_strip",
            type: "select",
            labelName: "競合前共通道路分離帯有無",
            SELECT_KEY: "common_yesnone_kana",
        },
        {
            key: 34,
            keyName: "count_left_right_turns",
            disabled: "count_left_right_turns",
            type: "select",
            labelName: "右左折計",
            SELECT_KEY: "count_left_right_turns",
        },
        {
            key: 35,
            keyName: "count_straight",
            disabled: "count_straight",
            type: "select",
            labelName: "直進計",
            SELECT_KEY: "count_straight",
        },
    ];
};

export const columnListSMDS = () => {
    return [
        {
            key: 1,
            keyName: "location_conflict_type",
            disabled: "",
            type: "location_conflict_type",
            labelName: "競合対象",
        },
        {
            key: 2,
            keyName: "include_commercial_area",
            disabled: "",
            type: "select",
            labelName: "徒歩5分商圏内外",
            SELECT_KEY: "include_commercial_area",
        },
        {
            key: 3,
            keyName: "bland_code",
            disabled: "",
            type: "label",
            labelName: "チェーン名",
        },
        {
            key: 4,
            keyName: "tenpo_name",
            disabled: "",
            type: "text",
            labelName: "店名",
        },
        {
            key: 5,
            keyName: "business_hours",
            disabled: "",
            type: "select",
            labelName: "営業時間",
            SELECT_KEY: "business_hours",
        },
        {
            key: 6,
            keyName: "closing_time",
            disabled: "closing",
            type: "time-label",
            labelName: "閉店時間",
        },
        {
            key: 7,
            keyName: "sales_floor_area_size",
            disabled: "",
            type: "text",
            labelName: "売場面積(㎡)",
        },
        {
            key: 8,
            keyName: "handle_category_parking",
            disabled: "",
            type: "select",
            labelName: "駐車場有無",
            SELECT_KEY: "common_yesnone_kana",
        },
        {
            key: 9,
            keyName: "parking_area_size",
            disabled: "parking",
            type: "text",
            labelName: "駐車場面積(㎡)",
        },
        {
            key: 10,
            keyName: "direct_distance",
            disabled: "",
            type: "round",
            labelName: "直線距離",
        },
        {
            key: 11,
            keyName: "road_distance",
            disabled: "road_distance",
            type: "round",
            labelName: "道なり距離",
        },
        {
            key: 12,
            keyName: "plan_area_shared_road",
            disabled: "plan_area_shared_road",
            type: "select",
            labelName: "候補前共通道路",
            SELECT_KEY: "common__shared_road",
        },
        {
            key: 13,
            keyName: "plan_area_direction",
            disabled: "plan_area_direction",
            type: "select",
            labelName: "方向",
            SELECT_KEY: "store_position",
        },
        {
            key: 14,
            keyName: "conflict_shared_road",
            disabled: "conflict_shared_road",
            type: "select",
            labelName: "競合前共通道路",
            SELECT_KEY: "common__shared_road",
        },
        {
            key: 15,
            keyName: "conflict_median_strip",
            disabled: "conflict_median_strip",
            type: "select",
            labelName: "競合前共通道路分離帯有無",
            SELECT_KEY: "common_yesnone_kana",
        },
        {
            key: 16,
            keyName: "count_left_right_turns",
            disabled: "count_left_right_turns",
            type: "select",
            labelName: "右左折計",
            SELECT_KEY: "count_left_right_turns",
        },
        {
            key: 17,
            keyName: "count_straight",
            disabled: "count_straight",
            type: "select",
            labelName: "直進計",
            SELECT_KEY: "count_straight",
        },
    ];
};

const storeValue = (
    key: string,
    storeData: any,
    objDonation: any,
    objStoreOpeningConditions: any
) => {
    let resultValue = "";
    if (storeData && objDonation && objStoreOpeningConditions) {
        switch (key) {
            case "bland_code":
                resultValue = storeData.bland_code;
                break;
            case "bland_name":
                resultValue = storeData.bland_name;
                break;
            case "business_hours":
                resultValue = "24H以外";
                if (objStoreOpeningConditions.open24_hours === "12") {
                    resultValue = "24H";
                }
                break;
            case "handle_category_liquor":
                resultValue = "なし";
                if (objStoreOpeningConditions.alcohol_license === "12") {
                    resultValue = "あり";
                }
                break;
            case "handle_category_tobacco":
                resultValue = "なし";
                if (objStoreOpeningConditions.tobacco_licence === "12") {
                    resultValue = "あり";
                }
                break;
            case "handle_category_tobacco":
                resultValue = objStoreOpeningConditions.salses_area.toString();
                break;
            case "sales_floor_area_size":
                resultValue = objStoreOpeningConditions.salses_area.toString();
                break;
            case "handle_category_parking":
                const parkingArea =
                    objDonation.parking_area === ""
                        ? 0
                        : Number(objDonation.parking_area);
                resultValue = parkingArea > 0 ? "あり" : "なし";
                break;
            case "store_frontage":
                resultValue =
                    objStoreOpeningConditions.store_frontage.toString();
                break;
            case "parking_area_size":
                resultValue = objDonation.parking_area.toString();
                break;
            case "number_of_parking":
                resultValue = objDonation.parking_capacity.toString();
                break;
            case "front_parking_tangent":
                resultValue =
                    objDonation.parking_tangent_line_frontage.toString();
                break;
            case "front_first_entrance":
                resultValue = objDonation.no1_entrance_frontage.toString();
                break;
            case "front_first_depth":
                resultValue = objDonation.no1_depth_frontage.toString();
                break;
            case "front_second_entrance":
                resultValue = objDonation.no2_entrance_frontage.toString();
                break;
            case "front_second_depth":
                resultValue = objDonation.no2_depth_frontage.toString();
                break;
            case "side_parking_tangent":
                if (storeData["side_road_type"] === "0") {
                    resultValue =
                        objDonation.parking_tangent_line_sideway_a.toString();
                } else if (storeData["side_road_type"] === "1") {
                    resultValue =
                        objDonation.parking_tangent_line_sideway_b.toString();
                } else if (storeData["side_road_type"] === "3") {
                    resultValue =
                        objDonation.parking_tangent_line_backstreet.toString();
                }
                break;
            case "side_first_entrance":
                if (storeData["side_road_type"] === "0") {
                    resultValue = objDonation.no1_entrance_sideway_a.toString();
                } else if (storeData["side_road_type"] === "1") {
                    resultValue = objDonation.no1_entrance_sideway_b.toString();
                } else if (storeData["side_road_type"] === "3") {
                    resultValue =
                        objDonation.no1_entrance_backstreet.toString();
                }
                break;
            case "side_first_depth":
                if (storeData["side_road_type"] === "0") {
                    resultValue = objDonation.no1_depth_sideway_a.toString();
                } else if (storeData["side_road_type"] === "1") {
                    resultValue = objDonation.no1_depth_sideway_b.toString();
                } else if (storeData["side_road_type"] === "3") {
                    resultValue = objDonation.no1_depth_backstreet.toString();
                }
                break;
            case "side_second_entrance":
                if (storeData["side_road_type"] === "0") {
                    resultValue = objDonation.no2_entrance_sideway_a.toString();
                } else if (storeData["side_road_type"] === "1") {
                    resultValue = objDonation.no2_entrance_sideway_b.toString();
                } else if (storeData["side_road_type"] === "3") {
                    resultValue =
                        objDonation.no2_entrance_backstreet.toString();
                }
                break;
            case "side_second_depth":
                if (storeData["side_road_type"] === "0") {
                    resultValue = objDonation.no2_depth_sideway_a.toString();
                } else if (storeData["side_road_type"] === "1") {
                    resultValue = objDonation.no2_depth_sideway_b.toString();
                } else if (storeData["side_road_type"] === "3") {
                    resultValue = objDonation.no2_depth_backstreet.toString();
                }
                break;
            default:
                break;
        }
    }

    return resultValue;
};
