import dayjs from "dayjs";

//出店条件ページAPI取得
export const storeOpeningConditionsData = (res: any) => {
    const DataObj = {
        setting_reason: res[0].setting_reason, //ゾーン設定の理由
        candidate_location_ruins: res[0].candidate_location_ruins, //候補地跡地
        candidate_location_ruins_long_text:
            res[0].candidate_location_ruins_long_text, //候補地跡地(ロングテキスト)
        is_store_name_select_reason: res[0].is_store_name_select_reason, //所在地表示以外にて選定（選定根拠を下記に記載） チェックボックス
        removal_Reason: res[0].removal_Reason, //理由
        open24_hours: res[0].open24_hours, //24時間営業 有
        not_open24_hours: res[0].not_open24_hours, //24時間営業 無
        alcohol_license: res[0].alcohol_license, //酒免許 有
        no_alcohol_license: res[0].no_alcohol_license, //酒免許 無
        tobacco_licence: res[0].tobacco_licence, //煙草免許 有
        no_tobacco_licence: res[0].no_tobacco_licence, //煙草免許 無
        area_type: res[0].area_type, //地域区分
        nearest_already_store_distance: res[0].nearest_already_store_distance, //最寄り既設店までの距離
        environment_type: res[0].environment_type, //環境区分
        acquisition_requirement: res[0].acquisition_requirement, //取得要件
        tobacco_distance_standard: res[0].tobacco_distance_standard, //煙草距離基準
        distance_standard_alreadystore_count:
            res[0].distance_standard_alreadystore_count, //距離基準内の既設店舗数
        previous_job: res[0].previous_job, //前職
        conflict_cvs: res[0].conflict_cvs, //元競合CVS
        cvs_last_nippan: res[0].cvs_last_nippan, //CVS直近日販
        relocation_distance: res[0].relocation_distance, //移転
        ownership_type: res[0].ownership_type, //所有形態​
        store_type: res[0].store_type, //店舗形態
        store_frontage: res[0].store_frontage, //店舗間口
        store_area: res[0].store_area, //店舗面積
        salses_area: res[0].salses_area, //売り場
        open_case: res[0].open_case, //オープンケース
        nakajima_chilled: res[0].nakajima_chilled, //中島チルド
        island_chilled: res[0].island_chilled, //アイランドチルド
        gondola_units: res[0].gondola_units, //ゴンドラ　尺数
        gondola_height: res[0].gondola_height, //ゴンドラ　高さ（mm）
        ice_case_large: res[0].ice_case_large, //アイスケース　大
        ice_case_medium: res[0].ice_case_medium, //アイスケース　中
        ice_case_small: res[0].ice_case_small, //アイスケース　小
        compact_stocker: res[0].compact_stocker, //コンパクトストッカー
        low_temp_reach_in: res[0].low_temp_reach_in, //低温リーチイン
        walk_in: res[0].walk_in, //ウォークイン
        conter_width: res[0].conter_width, //カウンター幅
        existing_store_name: res[0].existing_store_name, //既存店名​
        store_no: res[0].store_no, //店NO
        average_nippan: res[0].average_nippan, //平均日販​
        achievement_acquisition_start: res[0].achievement_acquisition_start, //実績取得期間​　前
        achievement_acquisition_end: res[0].achievement_acquisition_end, //実績取得期間​　後
        reception_no: res[0].reception_no, //受付NO
        s_store_open_date:
            dayjs(res[0].s_store_open_date).format("YYYY-MM-DD") ===
            "Invalid Date"
                ? ""
                : dayjs(res[0].s_store_open_date).format("YYYY-MM-DD"), //S店開店日
        s_store_close_due_date:
            dayjs(res[0].s_store_close_due_date).format("YYYY-MM-DD") ===
            "Invalid Date"
                ? ""
                : dayjs(res[0].s_store_close_due_date).format("YYYY-MM-DD"), //S店閉店予定日
        closing_days: res[0].closing_days, //休業日数
        in_sales_construction_days: res[0].in_sales_construction_days, //営業中工事日数
        car_common_ratio: res[0].car_common_ratio, //車共通比
        person_common_ratio: res[0].person_common_ratio, //人共通比
        s_store_line_distance: res[0].s_store_line_distance, //S店との直線距離
        s_store_way_distance: res[0].s_store_way_distance, //S店との道なり距離
        s_store_car_customer_ratio: res[0].s_store_car_customer_ratio, //S店車客構成比
        last_fixed_month: res[0].last_fixed_month, //直近確定月
        last_one_year_nippan: res[0].last_one_year_nippan, //直近1年日販
        year_on_year: res[0].year_on_year, //前年比
        year_month: res[0].year_month, //年月
        change_type: res[0].change_type, //変化の種類
        content: res[0].content, //内容
        last_one_year_nippan_0: res[0].last_one_year_nippan_0, //直近1年日販（直近月）
        last_one_year_nippan_1: res[0].last_one_year_nippan_1, //直近1年日販（直近月-1）
        last_one_year_nippan_2: res[0].last_one_year_nippan_2, //直近1年日販（直近月-2）
        last_one_year_nippan_3: res[0].last_one_year_nippan_3, //直近1年日販（直近月-3）
        last_one_year_nippan_4: res[0].last_one_year_nippan_4, //直近1年日販（直近月-4）
        last_one_year_nippan_5: res[0].last_one_year_nippan_5, //直近1年日販（直近月-5）
        last_one_year_nippan_6: res[0].last_one_year_nippan_6, //直近1年日販（直近月-6）
        last_one_year_nippan_7: res[0].last_one_year_nippan_7, //直近1年日販（直近月-7）
        last_one_year_nippan_8: res[0].last_one_year_nippan_8, //直近1年日販（直近月-8）
        last_one_year_nippan_9: res[0].last_one_year_nippan_9, //直近1年日販（直近月-9）
        last_one_year_nippan_10: res[0].last_one_year_nippan_10, //直近1年日販（直近月-10）
        last_one_year_nippan_11: res[0].last_one_year_nippan_11, //直近1年日販（直近月-11）
        year_on_year_0: res[0].year_on_year_0, //前年比（直近月）
        year_on_year_1: res[0].year_on_year_1, //前年比（直近月-1）
        year_on_year_2: res[0].year_on_year_2, //前年比（直近月-2）
        year_on_year_3: res[0].year_on_year_3, //前年比（直近月-3）
        year_on_year_4: res[0].year_on_year_4, //前年比（直近月-4）
        year_on_year_5: res[0].year_on_year_5, //前年比（直近月-5）
        year_on_year_6: res[0].year_on_year_6, //前年比（直近月-6）
        year_on_year_7: res[0].year_on_year_7, //前年比（直近月-7）
        year_on_year_8: res[0].year_on_year_8, //前年比（直近月-8）
        year_on_year_9: res[0].year_on_year_9, //前年比（直近月-9）
        year_on_year_10: res[0].year_on_year_10, //前年比（直近月-10）
        year_on_year_11: res[0].year_on_year_11, //前年比（直近月-11）
        store_move_type: res[0].store_move_type, //既存店敷地移転区分
        store_move_distance: res[0].store_move_distance, //既存店移転時距離
        store_type_multiple_store: res[0].store_type_multiple_store, //複合店舗
        last_one_year_nippan_average: res[0].last_one_year_nippan_average, //直近1年日販平均
        year_on_year_average: res[0].year_on_year_average, //前年比平均
    };
    return DataObj;
};

//出店条件ページAPI登録
export const upsertStoreOpeningConditionsParam = (
    objStoreOpeningConditions: any,
    plan_area_id: any,
    branch: any
) => {
    const params = {
        mode: "regist",
        endPoint: "/location/StoreOpeningConditions/v1/regist",
        query: {
            plan_area_id: plan_area_id,
            branch: branch,
            setting_reason: objStoreOpeningConditions.setting_reason, //ゾーン設定の理由
            candidate_location_ruins:
                objStoreOpeningConditions.candidate_location_ruins, //候補地跡地
            candidate_location_ruins_long_text:
                objStoreOpeningConditions.candidate_location_ruins_long_text, //候補地跡地(ロングテキスト)
            is_store_name_select_reason:
                objStoreOpeningConditions.is_store_name_select_reason, //所在地表示以外にて選定（選定根拠を下記に記載） チェックボックス
            removal_Reason: objStoreOpeningConditions.removal_Reason, //理由
            open24_hours: objStoreOpeningConditions.open24_hours, //24時間営業 有
            not_open24_hours: String(
                12 - Number(objStoreOpeningConditions.open24_hours)
            ), //24時間営業 無
            alcohol_license: objStoreOpeningConditions.alcohol_license, //酒免許 有
            no_alcohol_license: String(
                12 - Number(objStoreOpeningConditions.alcohol_license)
            ), //酒免許 無
            tobacco_licence: objStoreOpeningConditions.tobacco_licence, //煙草免許 有
            no_tobacco_licence: String(
                12 - Number(objStoreOpeningConditions.tobacco_licence)
            ), //煙草免許 無
            area_type: objStoreOpeningConditions.area_type, //地域区分
            nearest_already_store_distance:
                objStoreOpeningConditions.nearest_already_store_distance, //最寄り既設店までの距離
            environment_type: objStoreOpeningConditions.environment_type, //環境区分
            acquisition_requirement:
                objStoreOpeningConditions.acquisition_requirement, //取得要件
            tobacco_distance_standard:
                objStoreOpeningConditions.tobacco_distance_standard, //煙草距離基準
            distance_standard_alreadystore_count:
                objStoreOpeningConditions.distance_standard_alreadystore_count, //距離基準内の既設店舗数
            previous_job: objStoreOpeningConditions.previous_job, //前職
            conflict_cvs: objStoreOpeningConditions.conflict_cvs, //元競合CVS
            cvs_last_nippan: objStoreOpeningConditions.cvs_last_nippan, //CVS直近日販
            relocation_distance: objStoreOpeningConditions.relocation_distance, //移転
            ownership_type: objStoreOpeningConditions.ownership_type, //所有形態​
            store_type: objStoreOpeningConditions.store_type, //店舗形態
            store_frontage: objStoreOpeningConditions.store_frontage, //店舗間口
            store_area: objStoreOpeningConditions.store_area, //店舗面積
            salses_area: objStoreOpeningConditions.salses_area, //売り場
            open_case: objStoreOpeningConditions.open_case, //オープンケース
            nakajima_chilled: objStoreOpeningConditions.nakajima_chilled, //中島チルド
            island_chilled: objStoreOpeningConditions.island_chilled, //アイランドチルド
            gondola_units: objStoreOpeningConditions.gondola_units, //ゴンドラ　尺数
            gondola_height: objStoreOpeningConditions.gondola_height, //ゴンドラ　高さ（mm）
            ice_case_large: objStoreOpeningConditions.ice_case_large, //アイスケース　大
            ice_case_medium: objStoreOpeningConditions.ice_case_medium, //アイスケース　中
            ice_case_small: objStoreOpeningConditions.ice_case_small, //アイスケース　小
            compact_stocker: objStoreOpeningConditions.compact_stocker, //コンパクトストッカー
            low_temp_reach_in: objStoreOpeningConditions.low_temp_reach_in, //低温リーチイン
            walk_in: objStoreOpeningConditions.walk_in, //ウォークイン
            conter_width: objStoreOpeningConditions.conter_width, //カウンター幅
            existing_store_name: objStoreOpeningConditions.existing_store_name, //既存店名​
            store_no: objStoreOpeningConditions.store_no, //店NO
            average_nippan: objStoreOpeningConditions.average_nippan, //平均日販​
            achievement_acquisition_start:
                objStoreOpeningConditions.achievement_acquisition_start, //実績取得期間​　前
            achievement_acquisition_end:
                objStoreOpeningConditions.achievement_acquisition_end, //実績取得期間​　後
            reception_no: objStoreOpeningConditions.reception_no, //受付NO
            s_store_open_date:
                objStoreOpeningConditions.s_store_open_date === "Invalid Date"
                    ? ""
                    : objStoreOpeningConditions.s_store_open_date, //S店開店日
            s_store_close_due_date:
                objStoreOpeningConditions.s_store_close_due_date === "Invalid Date"
                    ? ""
                    : objStoreOpeningConditions.s_store_close_due_date, //S店閉店予定日
            closing_days: objStoreOpeningConditions.closing_days, //休業日数
            in_sales_construction_days:
                objStoreOpeningConditions.in_sales_construction_days, //営業中工事日数
            car_common_ratio: objStoreOpeningConditions.car_common_ratio, //車共通比
            person_common_ratio: objStoreOpeningConditions.person_common_ratio, //人共通比
            s_store_line_distance: objStoreOpeningConditions.s_store_line_distance, //S店との直線距離
            s_store_way_distance: objStoreOpeningConditions.s_store_way_distance, //S店との道なり距離
            s_store_car_customer_ratio:
                objStoreOpeningConditions.s_store_car_customer_ratio, //S店車客構成比
            last_fixed_month: objStoreOpeningConditions.last_fixed_month, //直近確定月
            last_one_year_nippan: objStoreOpeningConditions.last_one_year_nippan, //直近1年日販
            year_on_year: objStoreOpeningConditions.year_on_year, //前年比
            year_month: objStoreOpeningConditions.year_month, //年月
            change_type: objStoreOpeningConditions.change_type, //変化の種類
            content: objStoreOpeningConditions.content, //内容
            last_one_year_nippan_0:
                objStoreOpeningConditions.last_one_year_nippan_0, //直近1年日販（直近月）
            last_one_year_nippan_1:
                objStoreOpeningConditions.last_one_year_nippan_1, //直近1年日販（直近月-1）
            last_one_year_nippan_2:
                objStoreOpeningConditions.last_one_year_nippan_2, //直近1年日販（直近月-2）
            last_one_year_nippan_3:
                objStoreOpeningConditions.last_one_year_nippan_3, //直近1年日販（直近月-3）
            last_one_year_nippan_4:
                objStoreOpeningConditions.last_one_year_nippan_4, //直近1年日販（直近月-4）
            last_one_year_nippan_5:
                objStoreOpeningConditions.last_one_year_nippan_5, //直近1年日販（直近月-5）
            last_one_year_nippan_6:
                objStoreOpeningConditions.last_one_year_nippan_6, //直近1年日販（直近月-6）
            last_one_year_nippan_7:
                objStoreOpeningConditions.last_one_year_nippan_7, //直近1年日販（直近月-7）
            last_one_year_nippan_8:
                objStoreOpeningConditions.last_one_year_nippan_8, //直近1年日販（直近月-8）
            last_one_year_nippan_9:
                objStoreOpeningConditions.last_one_year_nippan_9, //直近1年日販（直近月-9）
            last_one_year_nippan_10:
                objStoreOpeningConditions.last_one_year_nippan_10, //直近1年日販（直近月-10）
            last_one_year_nippan_11:
                objStoreOpeningConditions.last_one_year_nippan_11, //直近1年日販（直近月-11）
            year_on_year_0: objStoreOpeningConditions.year_on_year_0, //前年比（直近月）
            year_on_year_1: objStoreOpeningConditions.year_on_year_1, //前年比（直近月-1）
            year_on_year_2: objStoreOpeningConditions.year_on_year_2, //前年比（直近月-2）
            year_on_year_3: objStoreOpeningConditions.year_on_year_3, //前年比（直近月-3）
            year_on_year_4: objStoreOpeningConditions.year_on_year_4, //前年比（直近月-4）
            year_on_year_5: objStoreOpeningConditions.year_on_year_5, //前年比（直近月-5）
            year_on_year_6: objStoreOpeningConditions.year_on_year_6, //前年比（直近月-6）
            year_on_year_7: objStoreOpeningConditions.year_on_year_7, //前年比（直近月-7）
            year_on_year_8: objStoreOpeningConditions.year_on_year_8, //前年比（直近月-8）
            year_on_year_9: objStoreOpeningConditions.year_on_year_9, //前年比（直近月-9）
            year_on_year_10: objStoreOpeningConditions.year_on_year_10, //前年比（直近月-10）
            year_on_year_11: objStoreOpeningConditions.year_on_year_11, //前年比（直近月-11）
            store_move_type: objStoreOpeningConditions.store_move_type, //既存店敷地移転区分
            store_move_distance: objStoreOpeningConditions.store_move_distance, //既存店移転時距離
            store_type_multiple_store:
                objStoreOpeningConditions.store_type_multiple_store, //複合店舗
            last_one_year_nippan_average:
                objStoreOpeningConditions.last_one_year_nippan_average, //直近1年日販平均
            year_on_year_average: objStoreOpeningConditions.year_on_year_average, //前年比平均
        },
    };
    return params;
};

//2024年5月の文字列を年と月に分ける関数
export const parseDateString = (dateString: string) => {
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(5, dateString.length - 1), 10);
    return { year, month };
};

//2024と5の数字から12か月分を取得する関数
export const getTwelveMonths = (year: number, month: number) => {
    const twelveMonths: { colName: string; label: string }[] = [];
    for (let j = 0; j < 12; j++) {
        const monthObj = {
            colName: "lastMonthMinus" + j,
            label: dayjs(new Date(year, month - 1))
                .subtract(j, "month")
                .format("M月"),
        };
        twelveMonths.push(monthObj);
    }
    return twelveMonths.reverse();
};

//休業日数の計算
export const closingDaysFunc = (open_due_date: any, s_store_close_due_date: any) => {
    //表紙の開店予定日 - 出店条件のS店閉店予定日 = 休業日数
    const parseOpenDueDate = dayjs(open_due_date, "YYYY-MM-DD", true);
    const parseCloseDueDate = dayjs(s_store_close_due_date, "YYYY-MM-DD", true);
    //日付チェック
    const isCheckOpenDate = parseOpenDueDate.isValid();
    const isCheckCloseDate = parseCloseDueDate.isValid();
    //日付の引き算で日数を計算
    return isCheckOpenDate && isCheckCloseDate
        ? parseOpenDueDate.diff(parseCloseDueDate, "day")
        : "";
};

//avgCulcNippan
//各月の直近1年日販/前年比*100)の全月平均 値が入っていない月は平均対象外
export const avgCulcNippanFunc = (nippan: any[], onYear: any[]) => {
    let totalNippan = 0;
    let countNippan = 0;
    for (let i = 0; i < nippan.length; i++) {
        if (
            nippan[i] !== "" &&
            onYear[i] !== "" &&
            parseFloat(onYear[i]) !== 0
        ) {
            totalNippan += (parseInt(nippan[i]) / parseFloat(onYear[i])) * 100;
            countNippan++;
        }
    }
    return countNippan !== 0 ? totalNippan / countNippan : "";
};

//avgCulcPrevRatio
export const avgCulcPrevRatioFunc = (
    monthHeader: any[],
    onYear: any[],
    year_month: any
) => {
    //年月が入ってない場合は、avgCulcPrevRatioの値なし
    if (year_month === "") {
        return "";
    }
    const monthHeaderList: any[] = [];
    //月の配列
    monthHeader.map((data: any) => {
        monthHeaderList.push(data.label);
    });
    //該当月のインデックスを設定
    const startIndex = monthHeaderList.indexOf(year_month);
    let totalPrevRatio = 0;
    let countPrevRatio = 0;

    //該当月が見つかった場合、そのインデックス以降の前年比の平均を返す
    //該当月の値がない場合は0を設定し平均対象とする
    if (startIndex !== -1) {
        for (let i = startIndex; i < onYear.length; i++) {
            totalPrevRatio += onYear[i] !== "" ? parseFloat(onYear[i]) : 0;
            countPrevRatio++;
        }
        return countPrevRatio !== 0 ? totalPrevRatio / countPrevRatio : "";
    }

    return "";
};
