import * as React from 'react';

import {
    Box,
    Modal,
} from '@mui/material';

import { useUserContext } from "../../../user-provider";
import { userLogging } from "../../../utility/firestore";
import {
    getTrafficVolume
} from "../../../data/DataManage";
import AddCar from "./AddCar";
import AddPeople from "./AddPeople";
import { LOCAL_CONSTANT } from '../../Const';
import {
    useAppDispatch,
    useAppSelector
} from '../../../app/hooks';
import { setParam } from "./TrafficVolumeFunction";
import { updateListRegist } from '../../Common';
import { setSaveStatus } from "../../../features/longPageSlice";
import {
    setReduxReloadTrafficVolume,
    setTrafficVolumeClearDeleteParam
} from "../../../features/trafficVolumeSlice";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    height: "95%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

export interface addTrafficVolumeModalProps {
    props: {
        open: boolean,
        handleModal: React.Dispatch<React.SetStateAction<boolean>>,
        target: string;
        setReload: any;
        dataList: any,
    }
}

export const CAR_PEOPLE = '0';
export const CAR = '1';
export const PEOPLE = '2';

const AddTrafficVolume: React.FC<addTrafficVolumeModalProps> = ({ props }) => {
    const dispatch = useAppDispatch();
    const user = useUserContext();
    // 初期
    const [open, setOpen] = React.useState(false);
    const [trafficVolumeList, setTrafficVolumeList] = React.useState([])
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    // ロングページ保存用ステータス
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
    // 通行量 車通行量、人通行量
    const listUpdateTraffic = useAppSelector((state) => state.trafficVolume.updateList);
    // 通行量 削除パラメータ
    const listDeleteTrafficParam = useAppSelector((state) => state.trafficVolume.deleteParamterList);

    // 閉じる
    const handleClose = () => {
        // 閉じる
        setOpen(false)
        props.handleModal(false)
        setTrafficVolumeList([])
    };

    const handleCloseRegist = () => {
        // 閉じる
        setOpen(false)
        props.handleModal(false)
        setTrafficVolumeList([])
        // リロード
        props.setReload(true)
    };

    React.useEffect(() => {
        if (props.open) {
            // 追加モーダルを開く 通行量の更新と削除があれば実施
            setOpen(props.open);
            // 保存用のparams
            const params: any = [];
            // 通行量 車通行量、人通行量
            if (objLongPageSaveStatus.TrafficVolumeCarPeople) {
                params.push(setParam(objLongPageParam.planAreaId, listUpdateTraffic));
                userLogging(
                    LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_TRAFFIC_CAR_PEOPLE_UPDATE,
                    params,
                    user
                );
            }
            // 通行量 削除パラメータもある場合
            if (params.length > 0 || listDeleteTrafficParam.length > 0) {
                updateListRegist(params).then(() => {
                    dispatch(
                        setSaveStatus({...objLongPageSaveStatus, TrafficVolumeCarPeople: false})
                    );
                }).then(() => {
                    // 通行量のReduxを更新
                    dispatch(setReduxReloadTrafficVolume(listUpdateTraffic));
                    // 通行量 削除パラメータがある場合
                    if (listDeleteTrafficParam.length > 0) {
                        userLogging(
                            LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_TRAFFIC_CAR_PEOPLE_DELETE,
                            listDeleteTrafficParam,
                            user
                        );
                        updateListRegist(listDeleteTrafficParam);
                    }
                }).then(() => {
                    // 通行量 削除パラメータを初期化
                    dispatch(setTrafficVolumeClearDeleteParam());
                }).catch(e => {
                    console.log(e);
                })
            }
            callApiList()
        }
    }, [props.open])


    const callApiList = () => {
        // 候補地呼び出し
        callApi({
            plan_area_id: objLongPageParam.planAreaId,
            count_type: props.target === LOCAL_CONSTANT.VALUE.ADD_CAR
                ? [CAR_PEOPLE, CAR].join(',')
                : [CAR_PEOPLE, PEOPLE].join(',')
        })
            .then((res: any) => {
                setTrafficVolumeList(res)
            }).catch((e) => {
                console.log(e)
            }).finally(() => {
            })
    }

    const callApi = async (params: any) => {
        return await getTrafficVolume({
            mode: "getTrafficVolume",
            plan_area_id: params.plan_area_id,
            count_type: params.count_type,
        })
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        props.target === LOCAL_CONSTANT.VALUE.ADD_CAR &&
                        <AddCar props={{
                            trafficVolumeList: trafficVolumeList,
                            handleClose: handleClose,
                            handleCloseRegist: handleCloseRegist,
                            dataList: props.dataList,
                        }} />
                    }
                    {
                        props.target === LOCAL_CONSTANT.VALUE.ADD_PEOPLE &&
                        <AddPeople props={{
                            trafficVolumeList: trafficVolumeList,
                            handleClose: handleClose,
                            handleCloseRegist: handleCloseRegist,
                            dataList: props.dataList,
                        }} />
                    }
                </Box>
            </Modal>
        </>
    );
}


export default AddTrafficVolume;