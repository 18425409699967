import * as React from 'react';

import { putMarker } from "./viewDataFunction";

export const drawExistingSejConflictStore = (
    list: any,
    map: google.maps.Map,
) => {

    const tmpMarker: any[] = [];
    list.forEach((data: any) => {
        try {
            // marker
            let marker = null
            if (data.type === '0') {
                marker = putMarker(data, 'sej')
            } else if (['1', '2'].includes(data.type)) {
                marker = putMarker(data, 'conflict')
            } else if (['3'].includes(data.type)) {
                marker = putMarker(data, 'locationPlanArea')
            }
            if (marker) {
                marker.setMap(map);

                tmpMarker.push({
                    key: data.type === '0' ? data.store_code : data.id,
                    data: data,
                    marker: marker,
                    number: "",
                    numberMarker: "",
                    conflictMarker: "",
                    marketCutMarker: "",
                    editStore: null,
                })
            }
        } catch (error) {
            console.log("------------------------")
            console.log("表示エラー")
            console.log(error)
            console.log(data)
            console.log("------------------------")
        }
    })

    return tmpMarker
}

export interface ExistingSejConflictStoreProps {
    props: {
    }
}

const ExistingSejConflictStore: React.FC<ExistingSejConflictStoreProps> = ({ props }) => {

    return (
        <></>
    );

}


export default ExistingSejConflictStore;
