import { createSlice } from "@reduxjs/toolkit";
import { initDrawingObj } from "../data/type";

export const drawingSlice = createSlice({
    name: "drawing",
    initialState: {
        obj: initDrawingObj,
        showLongText: false,
        pdfPath: "",
        targetFileType: "",
        deleteFile: {
            recreate: "",
            file_name: "",
            uploadDate: "",
            uplowder: "",
            file_path: "",
            target: ""
        },
    },
    reducers: {
        setObjDrawing: (state, action) => {
            state.obj = action.payload;
        },
        setDrawingLongTextShow: (state, action) => {
            state.showLongText = action.payload;
        },
        updateAddCell: (state, action) => {
            state.obj.hand_drawn_layout = [
                ...state.obj.hand_drawn_layout,
                {
                    id: state.obj.hand_drawn_layout.length,
                    handDrawnLayoutFormula:
                        action.payload.handDrawnLayoutFormula,
                    handDrawnLayoutSubTotal:
                        action.payload.handDrawnLayoutSubTotal,
                },
            ];
        },
        updateDeleteCell: (state, action) => {
            state.obj.hand_drawn_layout = state.obj.hand_drawn_layout.filter(
                (row) => row.id !== action.payload.id
            );
        },
        updateSubTotalCell: (state, action) => {
            const index = action.payload.id;
            state.obj.hand_drawn_layout[index] = {
                ...state.obj.hand_drawn_layout[index],
                handDrawnLayoutSubTotal: action.payload.handDrawnLayoutSubTotal,
            };
        },
        updateFormlaCell: (state, action) => {
            const index = action.payload.id;
            state.obj.hand_drawn_layout[index] = {
                ...state.obj.hand_drawn_layout[index],
                handDrawnLayoutFormula: action.payload.handDrawnLayoutFormula,
            };
        },
        updateTotalCell: (state, action) => {
            state.obj.hand_drawn_layout_total =
                state.obj.hand_drawn_layout.reduce(
                    (total: any, row: any) =>
                        total + Number(row.handDrawnLayoutSubTotal),
                    0
                );
        },
        setDrawingPdf: (state, action) => {
            state.pdfPath = action.payload;
        },
        setTargetFileType: (state, action) => {
            state.targetFileType = action.payload;
        },
        setDeleteFile: (state, action) => {
            state.deleteFile = action.payload;
        },
    },
});

export const {
    setObjDrawing,
    setDrawingLongTextShow,
    updateAddCell,
    updateDeleteCell,
    updateSubTotalCell,
    updateFormlaCell,
    updateTotalCell,
    setDrawingPdf,
    setTargetFileType,
    setDeleteFile,
} = drawingSlice.actions;
export default drawingSlice.reducer;
