// ①周辺の国税調査（H27）データ取得
export const taxAudit = (res:any) => {
    const household_count2020 = Number(res[0].household_count2020);
    const total_population2020 = Number(res[0].total_population2020);
    const household_count2020_single = Number(res[0].household_count2020_single);
    const total_population2020_19to29 = Number(res[0].total_population2020_19to29);
    const total_population2020_65more = Number(res[0].total_population2020_65more);
    const all_industry_office_count = Number(res[0].all_industry_office_count);
    const all_industry_employee_count = Number(res[0].all_industry_employee_count);
    const household_count2020_divide1000 = household_count2020 / 1000;
    const total_population2020_divide1000 = total_population2020 / 1000;
    
    const DataObj = {
        taxAuditAdministrativeName: res[0].prefecture + res[0].city_name,  //行政名称
        taxAuditHouseholdCount: household_count2020_divide1000,            //世帯数 = 2020世帯数 / 1000
        taxAuditPopulation: total_population2020_divide1000,               //人口 = 2020人口総数 / 1000
        taxAuditAveragePopulation: divideIfNotZero(total_population2020_divide1000, household_count2020_divide1000), //平均人口 = 世帯数が空白なら空白
                                                                                                                     //          世帯数が空白でないなら 人口 / 世帯数	
        taxAuditSingleHouseholdRatio: divideIfNotZero(household_count2020_single, (household_count2020/100)),        //単身比(%) = 2020世帯数(1人世帯) / (2020世帯数/100) 
        taxAuditAgeGroup20Ratio: divideIfNotZero(total_population2020_19to29, (total_population2020/100)),           //20代比(%) = 2020人口(19～29歳)総数 / (2020人口総数/100)	
        taxAuditAgeGroup65MoreRatio: divideIfNotZero(total_population2020_65more, (total_population2020/100)),       //65上比(%) = 2020人口(65歳以上)総数 / (2020人口総数/100)
        taxAuditOffice: all_industry_office_count,                         //事業所 = 2016全産業事業所数
        taxAuditEmployees: all_industry_employee_count,                    //従業員 = 2016全産業従業者数
        taxAuditAverageEmployees: divideIfNotZero(all_industry_employee_count,all_industry_office_count) //平均人員 = 従業員 / 事業所
    }
    return DataObj;
};

//②車5分商圏
export const car5MinMp = (res:any) => {
    const residences_count = Number(res[0].residences_count);                                                                                 //商圏世帯数 = residences_count
    const proration_population_total_count = Number(res[0].proration_population_total_count);
    const car5_min_single_household_ratio = divideIfNotZero(
                                                Number(res[0].proration_single_household_ratio),
                                                Number(res[0].proration_household_count)) * 100;                                              //単身比率 =  (proration_single_household_ratio / proration_household_count) * 100
    const car5_min_single_household_count = residences_count * car5_min_single_household_ratio / 100;                                         //単身世帯数 = 商圏世帯数 * 単身比率 / 100
    const car5_min_population = residences_count * divideIfNotZero(
                                                    Number(res[0].proration_household_count),
                                                    proration_population_total_count);                                                        //人口 = residences_count *（proration_household_count / proration_population_total_count）
    const car5_min_age_group20_ratio = divideIfNotZero(
                                            Number(res[0].proration_age_group_20population),
                                            proration_population_total_count) * 100;                                                          //20代比率 = (proration_age_group_20population / proration_population_total_count）* 100
    const car5_min_age_group20_total = car5_min_population * car5_min_age_group20_ratio / 100;                                                //20代計 = 人口 * 20代比率 / 100
    const car5_min_age_group30_ratio = divideIfNotZero(
                                            Number(res[0].proration_age_group_30population)
                                            ,proration_population_total_count) * 100;                                                         //30代比率 = （proration_age_group_30population / proration_population_total_count）* 100
    const car5_min_age_group30_total = car5_min_population * car5_min_age_group30_ratio / 100;                                                //30代計 = 人口 * 30代比率 / 100
    const car5_min_age_group50_more_ratio = divideIfNotZero(
                                                Number(res[0].proration_age_group_50more_population),
                                                proration_population_total_count) * 100;                                                      //50歳以上比率 = (proration_age_group_50more_population / proration_population_total_count）* 100
    const car5_min_age_group50_more_total =  car5_min_population * car5_min_age_group50_more_ratio / 100;                                     //50歳以上計 = 人口 * 50歳以上比率 / 100
    const car5_min_age_group65_more_ratio =  divideIfNotZero(
                                                Number(res[0].proration_age_group_65more_population),
                                                proration_population_total_count) * 100;                                                      //65歳以上比率 = （proration_age_group_65more_population / proration_population_total_count) * 100
    const car5_min_age_group65_more_total = car5_min_population * car5_min_age_group65_more_ratio / 100;                                      //65歳以上計 = 人口 * 65歳以上比率 / 100
    const car5_min_office_count = Number(res[0].office_cont);                                                                                 //事業所数 = office_cont                                              
    const proration_office_count = Number(res[0].proration_office_count); 
    const car5_min_employees_count = car5_min_office_count * divideIfNotZero(Number(res[0].proration_employees_count),proration_office_count);//従業員数 = 事業所*（proration_employees_count/proration_office_count）
    const proration_retail_count = Number(res[0].proration_retail_count);                                                                     //引分事業所 = proration_retail_count
    const proration_retail_employees_count = Number(res[0].proration_retail_employees_count);                                                 //引分従業員 = proration_retail_employees_count
    const car5_min_deduction_office = car5_min_office_count - proration_retail_count;                                                         //差引事業所 = 事業所-引分事業所                                        
    const car5_min_deduction_employees = car5_min_employees_count - proration_retail_employees_count;                                         //差引従業員 = 従業員数-引分従業員
    const income_up_to_300 = Number(res[0].income_up_to_300);                                                                                 //～300万
    const income_up_to_500 = Number(res[0].income_up_to_500);                                                                                 //～500万
    const income_up_to_700 = Number(res[0].income_up_to_700);                                                                                 //～700万
    const income_up_to_1000 = Number(res[0].income_up_to_1000);                                                                               //～1000万
    const income_over1000 = Number(res[0].income_over1000);                                                                                   //1000万～
    const income_up_sum = income_up_to_300 + income_up_to_500 + income_up_to_700 + income_up_to_1000 + income_over1000;                       //年収階級の合計
    
    const DataObj = {
        car5MinTradeAreaHouseholdCount: residences_count,                       //商圏世帯数
        car5MinSingleHouseholdCount: car5_min_single_household_count,           //単身世帯数
        car5MinSingleHouseholdRatio: car5_min_single_household_ratio,           //単身比率
        car5MinPopulation: car5_min_population,                                 //人口
        car5MinAgeGroup20Total: car5_min_age_group20_total,                     //20代計
        car5MinAgeGroup20Ratio: car5_min_age_group20_ratio,                     //20代比率
        car5MinAgeGroup30Total: car5_min_age_group30_total,                     //30代計
        car5MinAgeGroup30Ratio: car5_min_age_group30_ratio,                     //30代比率
        car5MinAgeGroup50MoreTotal: car5_min_age_group50_more_total,            //50歳以上計
        car5MinAgeGroup50MoreRatio: car5_min_age_group50_more_ratio,            //50歳以上比率
        car5MinAgeGroup65MoreTotal: car5_min_age_group65_more_total,            //65歳以上計
        car5MinAgeGroup65MoreRatio: car5_min_age_group65_more_ratio,            //65歳以上比率
        car5MinOfficeCount: car5_min_office_count,                              //事業所数
        car5MinEmployeesCount: car5_min_employees_count,                        //従業員数
        car5MinAllocationOffice: proration_retail_count,                        //引分事業所
        car5MinAllocationEmployees: proration_retail_employees_count,           //引分従業員
        car5MinDeductionOffice: car5_min_deduction_office,                      //差引事業所
        car5MinDeductionEmployees: car5_min_deduction_employees,                //差引従業員
        car5MinIncomeUpTo300: divideIfNotZero(income_up_to_300,income_up_sum),  //年収～300万 ※年収階級の合計
        car5MinIncomeUpTo500: divideIfNotZero(income_up_to_500,income_up_sum),  //～500万     ※年収階級の合計
        car5MinIncomeUpTo700: divideIfNotZero(income_up_to_700,income_up_sum),  //～700万     ※年収階級の合計
        car5MinIncomeUpTo1000: divideIfNotZero(income_up_to_1000,income_up_sum),//～1000万    ※年収階級の合計
        car5MinIncomeOver1000: divideIfNotZero(income_over1000,income_up_sum)   //1000万～ 　 ※年収階級の合計    
    };
    return DataObj;
};

//③勾配加味徒歩5分商圏データと④商圏データAPI取得
export const initMpData = (res:any) => {
    const DataList:any[] = [];
    res.map((data:any, index:number) => {

        //location_trade_areaにデータがない場合の調整
        let trade_area_seq:any = '';
        let reality_survey_household_count:any = '';
        let reality_survey_office:any = '';
        let reality_survey_employed_workers:any = '';
        if (typeof data.trade_area_seq == "undefined" || data.trade_area_seq === '') trade_area_seq = String(index+1);
        else trade_area_seq = data.trade_area_seq;
        if (typeof data.reality_survey_household_count !== "undefined") reality_survey_household_count = data.reality_survey_household_count;
        if (typeof data.reality_survey_office !== "undefined") reality_survey_office = data.reality_survey_office;
        if (typeof data.reality_survey_employed_workers !== "undefined") reality_survey_employed_workers = data.reality_survey_employed_workers;

        const DataObj = {
            id: index,
            proration_retail_count: data.proration_retail_count,                     //引分事業所
            proration_retail_employees_count: data.proration_retail_employees_count, //引分従業員
            income_up_to_300: data.income_up_to_300,                                 //～300万
            income_up_to_500: data.income_up_to_500,                                 //～500万
            income_up_to_700: data.income_up_to_700,                                 //～700万
            income_up_to_1000: data.income_up_to_700,                                //～1000万
            income_over1000: data.income_over1000,                                   //1000万～
            oaza_name: data.oaza_name,                                               //大字名
            section_name: data.section_name,                                         //字名
            single_household_ratio: data.single_household_ratio,                     //単独世帯数の比率
            popilation_total: data.popilation_total,                                 //人口総数
            household_count: data.household_count,                                   //世帯数
            age_group_20ratio: data.age_group_20ratio,                               //20代比率
            age_group_30ratio: data.age_group_30ratio,                               //30代比率
            age_group_50moreratio: data.age_group_50moreratio,                       //50才以上比率
            age_group_65moreratio: data.age_group_65moreratio,                       //65才以上比率
            mp_data_office_count: data.mp_data_office_count,                         //location_excel_04_mp_data 事業所数
            employees_count: data.employees_count,                                   //従業者数
            residences_count: data.residences_count,                                 //住居戸数
            mp_data2_office_count: data.mp_data2_office_count,                       //location_excel_05_mp_data2 事業所数
            trade_area_seq: trade_area_seq,                                          //商圏sequence
            k_chocode: data.k_chocode,                                               //k_chocode
            reality_survey_household_count: reality_survey_household_count,          //実査世帯数
            reality_survey_office: reality_survey_office,                            //実査事業所
            reality_survey_employed_workers: reality_survey_employed_workers,        //実査就業者
        }
        DataList.push(DataObj);
    })

    return DataList;
};

//③勾配加味徒歩5分商圏データと④商圏データ 画面表示用にデータ加工
export const mpData = (res:any) => {
    const DataList:any[] = [];
    let popilationCalc = 0;
    let singleHouseholdCalc = 0;

    res.map((data:any, index:number) => {

        //location_trade_areaにデータがない場合の調整
        let trade_area_seq:any = '';
        let reality_survey_household_count:any = '';
        let reality_survey_office:any = '';
        let reality_survey_employed_workers:any = '';
        if (typeof data.trade_area_seq == "undefined" || data.trade_area_seq === '') trade_area_seq = String(index+1);
        else trade_area_seq = data.trade_area_seq;
        if (typeof data.reality_survey_household_count !== "undefined") reality_survey_household_count = data.reality_survey_household_count;
        if (typeof data.reality_survey_office !== "undefined") reality_survey_office = data.reality_survey_office;
        if (typeof data.reality_survey_employed_workers !== "undefined") reality_survey_employed_workers = data.reality_survey_employed_workers;

        if (reality_survey_household_count !== '0' && reality_survey_household_count !== '') {
            //APIで取得したデータをテーブル表示するように変換 各値について小数点第一位を四捨五入して整数
            popilationCalc = Number(populationRealitySurveyFunc(reality_survey_household_count, data.residences_count, data.popilation_total, data.household_count));
            singleHouseholdCalc = reality_survey_household_count * (data.single_household_ratio / 100);
        } else {
            popilationCalc = Number(populationFunc(data.residences_count, data.popilation_total, data.household_count));
            singleHouseholdCalc = data.residences_count * (data.single_household_ratio / 100);
        }
        const DataObj = {
            id: index,
            gradientWalk5MinAllocationOffice: data.proration_retail_count,              //引分事業所
            gradientWalk5MinAllocationEmployees: data.proration_retail_employees_count, //引分従業員
            gradientWalk5MinIncomeUpTo300: data.income_up_to_300,                       //～300万
            gradientWalk5MinIncomeUpTo500: data.income_up_to_500,                       //～500万
            gradientWalk5MinIncomeUpTo700: data.income_up_to_700,                       //～700万
            gradientWalk5MinIncomeUpTo1000: data.income_up_to_700,                      //～1000万
            gradientWalk5MinIncomeOver1000: data.income_over1000,                       //1000万～
            townDistrict: data.oaza_name + data.section_name,                          //町丁字
            householdCount: data.residences_count,                                     //世帯数
            reality_survey_household_count: reality_survey_household_count,               //実査世帯数
            singleHousehold: singleHouseholdCalc,                                      //単身世帯
            population: popilationCalc,                                                //人口
            ageGroup20: popilationCalc * (data.age_group_20ratio / 100),               //20代     
            ageGroup30: popilationCalc * (data.age_group_30ratio / 100),               //30代
            ageGroup50: popilationCalc * (data.age_group_50moreratio / 100),           //50代
            ageGroup65More: popilationCalc * (data.age_group_65moreratio / 100),       //65上
            office: officeFunc(Number(data.mp_data_office_count),Number(data.mp_data2_office_count)),  //事業所
            employees: officeFunc(Number(data.mp_data_office_count),Number(data.mp_data2_office_count)) * divideIfNotZero(data.employees_count,data.mp_data_office_count), //従業員
            tradeAreaSeq: trade_area_seq,                                              //商圏sequence
            kChocode: data.k_chocode,                                                  //k_chocode
            reality_survey_office: reality_survey_office,                                //実査事業所
            reality_survey_employed_workers: reality_survey_employed_workers,             //実査就業者
        }
        DataList.push(DataObj);
    })
    return DataList;
};

//割り算
export const divideIfNotZero = (dividend: any, divisor: any): any => {
   return divisor === 0 ? '' : dividend / divisor;
};

//③勾配加味徒歩5分商圏 ④商圏データ 合計関数
export const calculateColumnTotal = (colName: string, rowData: any) => {
    if (colName === 'gradientWalk5MinAllocationOffice') {           //引分事業所
        return rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinAllocationOffice), 0)
    } else if (colName === 'gradientWalk5MinAllocationEmployees') {  //引分従業員
        return rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinAllocationEmployees), 0)
    } else if (colName === 'gradientWalk5MinIncomeUpTo300') {       //～300万
        return rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeUpTo300), 0)
    } else if (colName === 'gradientWalk5MinIncomeUpTo500') {       //～500万
        return rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeUpTo500), 0)
    } else if (colName === 'gradientWalk5MinIncomeUpTo700') {       //～700万
        return rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeUpTo700), 0)
    } else if (colName === 'gradientWalk5MinIncomeUpTo1000') {      //～1000万
        return rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeUpTo1000), 0)
    } else if (colName === 'gradientWalk5MinIncomeOver1000') {      //1000万～
        return rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeOver1000), 0)
    } else if (colName === 'gradientWalk5MinIncomeSum') {           //300～over1000の合計
        return rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeUpTo300), 0)
            +  rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeUpTo500), 0)
            +  rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeUpTo700), 0)
            +  rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeUpTo1000), 0)
            +  rowData.reduce((total:any, row:any) => total + Number(row.gradientWalk5MinIncomeOver1000), 0)
    } else if (colName === 'householdCount') {                     //世帯数
        return rowData.reduce((total:any, row:any) => total + Number(row.householdCount), 0);
    } else if (colName === 'singleHousehold') {                    //単身世帯
        return rowData.reduce((total:any, row:any) => total + Number(row.singleHousehold), 0);
    } else if (colName === 'population') {                         //人口
        return rowData.reduce((total:any, row:any) => total + Number(row.population), 0);
    } else if (colName === 'ageGroup20') {                         //20代
        return rowData.reduce((total:any, row:any) => total + Number(row.ageGroup20), 0);
    } else if (colName === 'ageGroup30') {                         //30代
        return rowData.reduce((total:any, row:any) => total + Number(row.ageGroup30), 0);
    } else if (colName === 'ageGroup50') {                         //50代
        return rowData.reduce((total:any, row:any) => total + Number(row.ageGroup50), 0);
    } else if (colName === 'ageGroup65More') {                     //65上
        return rowData.reduce((total:any, row:any) => total + Number(row.ageGroup65More), 0);
    } else if (colName === 'office') {                             //事業所
        return rowData.reduce((total:any, row:any) => total + Number(row.office), 0);
    } else if (colName === 'employees') {                          //従業員
        return rowData.reduce((total:any, row:any) => total + Number(row.employees), 0);
    } else if (colName === 'reality_survey_household_count') {        //実査世帯数                                                    
        const emptyRowData = rowData.filter((row:any) => row.reality_survey_household_count === '' || row.reality_survey_household_count === '0');  //実査世帯数 空白行か0の行を取得　
        return emptyRowData.reduce((total:any, row:any) => total + Number(row.householdCount), 0) + rowData.reduce((total:any, row:any) => total + Number(row.reality_survey_household_count), 0); //実査世帯数が入力されていない世帯数の行の合計 + 入力されている実査世帯数の合計
    } else if (colName === 'reality_survey_office') {                //実査事業所
        const emptyRowData = rowData.filter((row:any) => row.reality_survey_office === '' || row.reality_survey_office === '0');                  //実査事業所 空白行か0の行を取得　
        return emptyRowData.reduce((total:any, row:any) => total + Number(row.office), 0) + rowData.reduce((total:any, row:any) => total + Number(row.reality_survey_office), 0); //実査事業所が入力されていない世帯数の行の合計 + 入力されている実査事業所の合計
    } else if (colName === 'reality_survey_employed_workers') {       //実査就業者
        const emptyRowData = rowData.filter((row:any) => row.reality_survey_employed_workers === '' || row.reality_survey_employed_workers === '0'); //実査就業者 空白行か0の行を取得
        return emptyRowData.reduce((total:any, row:any) => total + Number(row.employees), 0) + rowData.reduce((total:any, row:any) => total + Number(row.reality_survey_employed_workers), 0); //実査就業者が入力されていない世帯数の行の合計 + 入力されている実査就業者の合計
    }
};

//④商圏データ 事業所の計算関数
export const officeFunc = (officeCount: number, officeCount2: number) => {
    //location_excel_04_mp_data.事業所 < mp_data1. k_chocodeと一致するmp_data2. k_chocodeの事業所数の場合、location_excel_04_mp_data.事業所
    if (officeCount < officeCount2) {  
        return officeCount;
    } else {
        return officeCount2;
    }
};

//④商圏データ 人口の計算関数
//世帯数が1未満の場合、0
//世帯数が1以上の場合、世帯数*（mp_data.人口総数/ mp_data.世帯数）
export const populationFunc = (residencesCount: number, popilation: number, householdCount: number) => {
    if (residencesCount < 1) {
        return 0;
    } else if (residencesCount >= 1) {
        return residencesCount * divideIfNotZero(popilation,householdCount)
    } 
};

//④商圏データ 人口の計算関数 実査世帯数
//世帯数が1以上かつ実査世帯数が入力されている場合、実査世帯数*（mp_data.人口総数/ mp_data.世帯数）
export const populationRealitySurveyFunc = (
        reality_survey_household_count: any,
        householdCount: any,
        mpDataPopilation: any,
        mpDataHouseholdCount: any,
    ) => {
    if (householdCount > 1 && reality_survey_household_count !== '') {
        return reality_survey_household_count * divideIfNotZero(mpDataPopilation,mpDataHouseholdCount);
    } else {
        return 0;
    } 
};

//④商圏データ登録API
export const upsertTradeAreaParam = (listTradeArea:any, plan_area_id:any, branch:any, excel_sequence:any) => {
    
    if (excel_sequence !== '') {
        //Location_baseのlocation_excel_sequenceがある場合更新
        const DataList:any[] = [];
        listTradeArea.map((data:any) => {
            const DataObj = {
                trade_area_seq:data.tradeAreaSeq,                                  //商圏sequence
                k_chocode: data.kChocode,                                          //k_chocode
                reality_survey_household_count:data.reality_survey_household_count,   //実査世帯数
                reality_survey_office:data.reality_survey_office,                    //実査事業所
                reality_survey_employed_workers:data.reality_survey_employed_workers  //実査就業者
            };
            DataList.push(DataObj);
        })
    
        const params = {
            mode: "regist",
            endPoint: "/location/TradeArea/v1/regist",
            query: {
                plan_area_id: plan_area_id,
                branch: branch,
                excel_sequence: Number(excel_sequence),
                data: DataList
            }
        }
        return params;
    } else {
        return;
    }
}


//商圏密度と立地の関数で使用
let householdCountSum = 0
let employeesSum = 0

//商圏密度 関数
export const tradeAreaDensityFunc = (status: string, rowData: any) => {
    if (status === 'beforeModified') {//修正前
        householdCountSum = rowData.reduce((total:any, row: any) => total + Number(row.householdCount), 0);
        employeesSum =  rowData.reduce((total:any, row:any) => total + Number(row.employees), 0);
    } else if (status === 'afterModified') {//修正後
        const householdEmptyRowData = rowData.filter((row:any) => row.reality_survey_household_count === '');//実査世帯数空白行を取得　
        householdCountSum = householdEmptyRowData.reduce((total:any, row:any) => total + Number(row.householdCount), 0) + rowData.reduce((total:any, row:any) => total + Number(row.reality_survey_household_count), 0); //実査世帯数が入力されていない世帯数の行の合計 + 入力されている実査世帯数の合計
        const EmployeEmptyRowData = rowData.filter((row:any) => row.reality_survey_employed_workers === '');//実査就業者空白行を取得　
        employeesSum = EmployeEmptyRowData.reduce((total:any, row:any) => total + Number(row.employees), 0) + rowData.reduce((total:any, row:any) => total + Number(row.reality_survey_employed_workers), 0); //実査就業者が入力されていない世帯数の行の合計 + 入力されている実査就業者の合計
    }
    
    if (0 <= householdCountSum && householdCountSum <= 100) {
        if (0 <= employeesSum && employeesSum <= 300) return '1'
        else if (301 <= employeesSum && employeesSum <= 900) return '2'
        else if (901 <= employeesSum && employeesSum <= 1500) return '3'
        else if (1501 <= employeesSum && employeesSum <= 3000) return '4'
        else if (3001 <= employeesSum && employeesSum <= 6000) return '5'
        else if (6001 <= employeesSum) return '6'
    } else if (101 <= householdCountSum && householdCountSum <= 300) {
        if (0 <= employeesSum && employeesSum <= 300) return '2'
        else if (301 <= employeesSum && employeesSum <= 900) return '3'
        else if (901 <= employeesSum && employeesSum <= 1500) return '4'
        else if (1501 <= employeesSum && employeesSum <= 3000) return '4'
        else if (3001 <= employeesSum && employeesSum <= 6000) return '5'
        else if (6001 <= employeesSum) return '6'
    } else if (301 <= householdCountSum && householdCountSum <= 600) {
        if (0 <= employeesSum && employeesSum <= 300) return '3'
        else if (301 <= employeesSum && employeesSum <= 900) return '4'
        else if (901 <= employeesSum && employeesSum <= 1500) return '4'
        else if (1501 <= employeesSum && employeesSum <= 3000) return '6'
        else if (3001 <= employeesSum && employeesSum <= 6000) return '7'
        else if (6001 <= employeesSum) return '8'
    } else if (601 <= householdCountSum && householdCountSum <= 900) {
        if (0 <= employeesSum && employeesSum <= 300) return '4'
        else if (301 <= employeesSum && employeesSum <= 900) return '4'
        else if (901 <= employeesSum && employeesSum <= 1500) return '4'
        else if (1501 <= employeesSum && employeesSum <= 3000) return '7'
        else if (3001 <= employeesSum && employeesSum <= 6000) return '8'
        else if (6001 <= employeesSum) return '9'
    } else if (901 <= householdCountSum && householdCountSum <= 1500) {
        if (0 <= employeesSum && employeesSum <= 300) return '5'
        else if (301 <= employeesSum && employeesSum <= 900) return '6'
        else if (901 <= employeesSum && employeesSum <= 1500) return '7'
        else if (1501 <= employeesSum && employeesSum <= 3000) return '8'
        else if (3001 <= employeesSum && employeesSum <= 6000) return '9'
        else if (6001 <= employeesSum) return '10'
    } else if (1501 <= householdCountSum) {
        if (0 <= employeesSum && employeesSum <= 300) return '6'
        else if (301 <= employeesSum && employeesSum <= 900) return '7'
        else if (901 <= employeesSum && employeesSum <= 1500) return '8'
        else if (1501 <= employeesSum && employeesSum <= 3000) return '9'
        else if (3001 <= employeesSum && employeesSum <= 6000) return '10'
        else if (6001 <= employeesSum) return '11'
    }
};


//立地 関数定数
const ROAD_SIDE = "ロードサイド立地"
const OFFICE = "事業所立地"
const OFFICE_DOWNTOWN = "オフィス・繁華街立地"
const GENERAL_HOUSE = "一般住宅立地"
const COMPOSITE = "複合立地"
const RESIDENCE_DENSE = "住宅密集立地"

//立地 関数
export const locationFunc = (status: string, rowData: any) => {
    if (status === 'beforeModified') {//修正前
        householdCountSum = rowData.reduce((total:any, row:any) => total + Number(row.householdCount), 0);
        employeesSum =  rowData.reduce((total:any, row:any) => total + Number(row.employees), 0);
    } else if (status === 'afterModified') {//修正後
        const householdEmptyRowData = rowData.filter((row:any) => row.reality_survey_household_count === '' || row.reality_survey_household_count === '0'); //実査世帯数 空白行と0行を取得　
        householdCountSum = householdEmptyRowData.reduce((total:any, row:any) => total + Number(row.householdCount), 0) + rowData.reduce((total:any, row:any) => total + Number(row.reality_survey_household_count), 0); //実査世帯数が入力されていない世帯数の行の合計 + 入力されている実査世帯数の合計
        const EmployeEmptyRowData = rowData.filter((row:any) => row.reality_survey_employed_workers === '' || row.reality_survey_employed_workers === '0'); //実査就業者 空白行と0行を取得　
        employeesSum = EmployeEmptyRowData.reduce((total:any, row:any) => total + Number(row.employees), 0) + rowData.reduce((total:any, row:any) => total + Number(row.reality_survey_employed_workers), 0); //実査就業者が入力されていない世帯数の行の合計 + 入力されている実査就業者の合計
    }

    if (0 <= householdCountSum && householdCountSum <= 100) {
        if (0 <= employeesSum && employeesSum <= 300) return ROAD_SIDE
        else if (301 <= employeesSum && employeesSum <= 900) return ROAD_SIDE
        else if (901 <= employeesSum && employeesSum <= 1500) return OFFICE
        else if (1501 <= employeesSum && employeesSum <= 3000) return OFFICE
        else if (3001 <= employeesSum && employeesSum <= 6000) return OFFICE_DOWNTOWN
        else if (6001 <= employeesSum) return OFFICE_DOWNTOWN
    } else if (101 <= householdCountSum && householdCountSum <= 300) {
        if (0 <= employeesSum && employeesSum <= 300) return ROAD_SIDE
        else if (301 <= employeesSum && employeesSum <= 900) return ROAD_SIDE
        else if (901 <= employeesSum && employeesSum <= 1500) return OFFICE
        else if (1501 <= employeesSum && employeesSum <= 3000) return OFFICE
        else if (3001 <= employeesSum && employeesSum <= 6000) return OFFICE_DOWNTOWN
        else if (6001 <= employeesSum) return OFFICE_DOWNTOWN
    } else if (301 <= householdCountSum && householdCountSum <= 600) {
        if (0 <= employeesSum && employeesSum <= 300) return GENERAL_HOUSE
        else if (301 <= employeesSum && employeesSum <= 900) return GENERAL_HOUSE
        else if (901 <= employeesSum && employeesSum <= 1500) return COMPOSITE
        else if (1501 <= employeesSum && employeesSum <= 3000) return COMPOSITE
        else if (3001 <= employeesSum && employeesSum <= 6000) return COMPOSITE
        else if (6001 <= employeesSum) return COMPOSITE
    } else if (601 <= householdCountSum && householdCountSum <= 900) {
        if (0 <= employeesSum && employeesSum <= 300) return GENERAL_HOUSE
        else if (301 <= employeesSum && employeesSum <= 900) return GENERAL_HOUSE
        else if (901 <= employeesSum && employeesSum <= 1500) return COMPOSITE
        else if (1501 <= employeesSum && employeesSum <= 3000) return COMPOSITE
        else if (3001 <= employeesSum && employeesSum <= 6000) return COMPOSITE
        else if (6001 <= employeesSum) return COMPOSITE
    } else if (901 <= householdCountSum && householdCountSum <= 1500) {
        if (0 <= employeesSum && employeesSum <= 300) return RESIDENCE_DENSE
        else if (301 <= employeesSum && employeesSum <= 900) return RESIDENCE_DENSE
        else if (901 <= employeesSum && employeesSum <= 1500) return COMPOSITE
        else if (1501 <= employeesSum && employeesSum <= 3000) return COMPOSITE
        else if (3001 <= employeesSum && employeesSum <= 6000) return COMPOSITE
        else if (6001 <= employeesSum) return COMPOSITE
    } else if (1501 <= householdCountSum) {
        if (0 <= employeesSum && employeesSum <= 300) return RESIDENCE_DENSE
        else if (301 <= employeesSum && employeesSum <= 900) return RESIDENCE_DENSE
        else if (901 <= employeesSum && employeesSum <= 1500) return COMPOSITE
        else if (1501 <= employeesSum && employeesSum <= 3000) return COMPOSITE
        else if (3001 <= employeesSum && employeesSum <= 6000) return COMPOSITE
        else if (6001 <= employeesSum) return COMPOSITE
    }
};