export const getStaffNoList = (target: string, params: any) => {
    let staffNoList: any = [];
    let jsonStaffMasterObj = [];

    switch (target) {
        case "requestEmail_5mOutsideMarketCut_D002":
            jsonStaffMasterObj = JSON.parse(params.staffMasterObj);
            jsonStaffMasterObj.map((e: any) => {
                if (
                    e.department_code_oa === "992002" &&
                    e.job_category_code_oa === "230"
                ) {
                    staffNoList.push(e.staff_number);
                }
            });
            staffNoList.push(params.userData.staff_number);
            break;
        case "requestEmail_5mOutsideMarketCut_D004":
            jsonStaffMasterObj = JSON.parse(params.staffMasterObj);
            jsonStaffMasterObj.map((e: any) => {
                if (
                    e.department_code_oa === "992002" &&
                    ["101", "102"].includes(e.job_category_code_oa)
                ) {
                    staffNoList.push(e.staff_number);
                }
            });
            break;
        case "requestEmail_5mOutsideMarketCut_D006":
            staffNoList.push(params.planAreaInfoData.RM_code);
            staffNoList.push(params.userData.staff_number);
            break;
        case "requestEmail_5mOutsideMarketCut_D007":
            jsonStaffMasterObj = JSON.parse(params.staffMasterObj);
            jsonStaffMasterObj.map((e: any) => {
                if (
                    e.department_code_oa ===
                        params.planAreaInfoData.top_department_code_oa &&
                    ["101", "102"].includes(e.job_category_code_oa)
                ) {
                    staffNoList.push(e.staff_number);
                }
            });
            break;
        case "returnEmail_5mOutsideMarketcut":
            staffNoList.push(params.userData.staff_number);
            break;
    }

    return staffNoList;
};
