import * as React from "react";

import { useUserContext } from "../../user-provider";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setSaveStatus } from "../../features/longPageSlice";
import {
    setTrafficVolume,
    setTrafficVolumeUpdateDelete
} from "../../features/trafficVolumeSlice";
import { setN15AddMarket } from "../../features/n15AddMarketSlice";
import {
    setObjLocationTraffic5mcut,
    setInitSelectList,
    setSelectList,
    setSelectList2,
} from "../../features/locationTraffic5mcutSlice";
import { LOCAL_CONSTANT } from "../Const";
import {
    Box,
    Button,
    FormLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import { locationTrafficTotal } from "../subPage/TrafficVolume/TrafficVolumeFunction";
import TrafficVolumeCar from "../subPage/TrafficVolume/TrafficVolumeCar";
import TrafficVolumePeople from "../subPage/TrafficVolume/TrafficVolumePeople";
import OtherTraffic from "../subPage/TrafficVolume/OtherTraffic";
import AddTrafficVolume from "../subPage/TrafficVolume/AddTrafficVolume";
import { useUtilityContext } from "../../utility-provider";
import {
    getLocationTraffic,
    getN15AddMarket,
    getLongPageData,
} from "../../data/DataManage";

import MessageDialog from "../components/MessageDialog";
import { setPermissionFunc } from "../components/PermissionFunc";
import LocationTraffic5mcut from "../subPage/TrafficVolume/LocationTraffic5mcut";

export interface resultDialogProps {
    props: {};
}

const list = () => {
    return [
        {
            header:
                LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC +
                "（" +
                LOCAL_CONSTANT.LABEL.IN_FIVE +
                "）",
            target: LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN,
        },
        {
            header:
                LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC +
                "（" +
                LOCAL_CONSTANT.LABEL.OUT_FIVE +
                "）",
            target: LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT,
        },
        {
            header:
                LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC +
                "（" +
                LOCAL_CONSTANT.LABEL.IN_OUT_FIVE +
                "）",
            target: LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT,
        },
    ];
};

interface locationTrafficType {
    keyName: String;
    data: any;
}

const TrafficVolume: React.FC<resultDialogProps> = ({ props }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [target, setTarget] = React.useState<string>("");
    const utilityCtx = useUtilityContext();
    const [reload, setReload] = React.useState<boolean>(false);
    const [locationTraffic, setLocationTraffic] = React.useState<locationTrafficType[]>([]);
    const [dataCar, setDataCar] = React.useState([]);
    const [dataPeople1, setDataPeople1] = React.useState([]);
    const [dataPeople2, setDataPeople2] = React.useState([]);
    const [dataPeople3, setDataPeople3] = React.useState([]);
    const [dataPeople4, setDataPeople4] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);

    const [nightRatio, setNightRatio] = React.useState(0);
    const [holidaysRatio, setHolidaysRatio] = React.useState(0);
    const [nightRatioValue, setNightRatioValue] = React.useState(0);
    const [holidaysRatioValue, setHolidaysRatioValue] = React.useState(0);

    //パラメータオブジェクト
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    const objTrafficVolume = useAppSelector((state) => state.trafficVolume.obj);
    const objN15AddMarket = useAppSelector((state) => state.n15AddMarket);
    const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);
    const dispatch = useAppDispatch();

    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [userType, setUserType] = React.useState("");
    const user = useUserContext();
    const [userData, setUserData] = React.useState<any>({});

    const [separateValue, setSeparateValue] = React.useState<string>("")
    const [peopleSeparateValue, setPeopleSeparateValue] = React.useState<string>("")
    const [cutSurveyValue, setCutSurveyValue] = React.useState<string>("")
    const [codeMaster, setCodeMaster] = React.useState<any>();

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster")
        if (res) {
            setCodeMaster(JSON.parse(res))
            const d = JSON.parse(res)
            dispatch(setInitSelectList(d['others_facility'])) //その他施設 施設プルダウンリスト初期値
            dispatch(setSelectList(d['others_facility']))     //その他施設 3行目施設プルダウンリスト
            dispatch(setSelectList2(d['others_facility']))    //その他施設 4行目施設プルダウンリスト
        }
    }, [])

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
            setUserData(JSON.parse(user.userData));
        }
    }, [user]);

    const [message, setMessage] = React.useState<string>("")
    const [hasButton, setHasButton] = React.useState<boolean>(false)

    const onClickHandle = (target: string) => {
        let tmpList: any = []
        locationTraffic?.map((row: any) => {
            if (target === LOCAL_CONSTANT.VALUE.ADD_CAR) {
                if (["0", "1", "2", "4"].includes(row.traffic_type)) {
                    tmpList.push(row)
                }
            } else if (target === LOCAL_CONSTANT.VALUE.ADD_PEOPLE) {
                if (["6", "7", "8", "9", "10", "12", "13"].includes(row.traffic_type)) {
                    tmpList.push(row)
                }
            }
        })

        setDataList(tmpList)
        setTarget(target)
        setOpen(true)
    }

    const callApi = async (target: string) => {
        if (target === 'locationTraffic') {
            // テスト中固定値
            return await getLocationTraffic({
                mode: "getLocationTraffic",
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
            })
        }
        if (target === 'getN15AddMarket') {
            return await getN15AddMarket({
                mode: "getN15AddMarket",
                plan_area_id: objLongPageParam.planAreaId,
                location_type: objLocationBase.location_type
            })
        }
        if (target === 'getLocationTraffic5mcut') {
            const param: any = [
                {
                    mode: 'getLocationTraffic5mcut',
                    plan_area_id: objLongPageParam.planAreaId,
                    branch: objLongPageParam.branch,
                },
            ];
            return await getLongPageData(param)
        }
    }

    React.useEffect(() => {
        setLocationTraffic(objTrafficVolume)
    }, [objTrafficVolume])

    React.useEffect(() => {
        if (objN15AddMarket.length > 0) {
            setNightRatio(objN15AddMarket[0]['population_ordinary_night_others'] / objN15AddMarket[0]['population_ordinary_daytime_others']);
            setHolidaysRatio(objN15AddMarket[0]['population_holiday_others'] / objN15AddMarket[0]['population_ordinary_others']);
            //人流表示(%)
            setNightRatioValue(Math.round(objN15AddMarket[0]['population_ordinary_night_others']) / Math.round(objN15AddMarket[0]['population_ordinary_daytime_others']));
            setHolidaysRatioValue(Math.round(objN15AddMarket[0]['population_holiday_others']) / Math.round(objN15AddMarket[0]['population_ordinary_others']));
        }
    }, [objN15AddMarket])

    React.useEffect(() => {
        setSeparateValue(objLocationTraffic5mcut.car_another_survey)
        setPeopleSeparateValue(objLocationTraffic5mcut.people_another_survey)
        setCutSurveyValue(objLocationTraffic5mcut.cut_survey)
    }, [objLocationTraffic5mcut])

    React.useEffect(() => {
        if (reload) {
            trafficReload()
        }
    }, [reload])

    const trafficReload = () => {
        try {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            // 車通行量と人通行量 追加モーダルで追加後と入替 通行量 呼び出し
            callApi('locationTraffic').then((res: any) => {
                dispatch(setTrafficVolume(locationTrafficTotal(res)));
                // 通行量更新用削除用 Reduxを更新
                dispatch(setTrafficVolumeUpdateDelete(locationTrafficTotal(res)));
                callApi('getN15AddMarket').then((res: any) => {
                    dispatch(setN15AddMarket(res));
                    setReload(false)
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                }).catch((e) => {
                    console.log(e)
                    setReload(false)
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                })
            }).catch((e) => {
                console.log(e)
                setReload(false)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
        } catch (e) {
            console.log(e)
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            setReload(false)
        }
    }

    React.useEffect(() => {
        const car_TRAFFICTYPE_0: any = [];
        const car_TRAFFICTYPE_1: any = [];
        const car_TRAFFICTYPE_2: any = [];
        const car_TRAFFICTYPE_4: any = [];
        const people_TRAFFICTYPE_6: any = [];
        const people_TRAFFICTYPE_7: any = [];
        const people_TRAFFICTYPE_8: any = [];
        const people_TRAFFICTYPE_9: any = [];
        const people_TRAFFICTYPE_10: any = [];
        const people_TRAFFICTYPE_12: any = [];
        const people_TRAFFICTYPE_13: any = [];
        if (locationTraffic) {
            locationTraffic.map((row: any) => {
                switch (row.traffic_type) {
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal:
                        car_TRAFFICTYPE_0.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal:
                        car_TRAFFICTYPE_1.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal:
                        car_TRAFFICTYPE_2.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal:
                        car_TRAFFICTYPE_4.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.typeVal:
                        people_TRAFFICTYPE_6.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.typeVal:
                        people_TRAFFICTYPE_7.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.typeVal:
                        people_TRAFFICTYPE_8.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.typeVal:
                        people_TRAFFICTYPE_9.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.typeVal:
                        people_TRAFFICTYPE_10.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.typeVal:
                        people_TRAFFICTYPE_12.push(row)
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.typeVal:
                        people_TRAFFICTYPE_13.push(row)
                        break;
                }
            })
        }
        let carTmpList: any = []
        carTmpList.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal, data: car_TRAFFICTYPE_0 })
        carTmpList.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal, data: car_TRAFFICTYPE_1 })
        carTmpList.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal, data: car_TRAFFICTYPE_2 })
        carTmpList.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal, data: car_TRAFFICTYPE_1 })
        carTmpList.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal, data: car_TRAFFICTYPE_4 })
        carTmpList.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal, data: car_TRAFFICTYPE_1 })
        setDataCar(carTmpList)
        const peopleTmpList1: any = []
        const peopleTmpList2: any = []
        const peopleTmpList3: any = []
        const peopleTmpList4: any = []

        peopleTmpList1.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.typeVal, data: people_TRAFFICTYPE_6 })
        peopleTmpList1.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.typeVal, data: people_TRAFFICTYPE_8 })
        setDataPeople1(peopleTmpList1)

        peopleTmpList2.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.typeVal, data: people_TRAFFICTYPE_7 })
        peopleTmpList2.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.typeVal, data: people_TRAFFICTYPE_9 })
        peopleTmpList2.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.typeVal, data: people_TRAFFICTYPE_12 })
        setDataPeople2(peopleTmpList2)

        peopleTmpList3.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.typeVal, data: people_TRAFFICTYPE_10 })
        peopleTmpList3.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.typeVal, data: people_TRAFFICTYPE_8 })
        setDataPeople3(peopleTmpList3)

        peopleTmpList4.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.typeVal, data: people_TRAFFICTYPE_13 })
        peopleTmpList4.push({ keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.name, trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.typeVal, data: people_TRAFFICTYPE_8 })
        setDataPeople4(peopleTmpList4)
    }, [locationTraffic])

    const ratio = (key: string) => {
        let ratioValue = 0
        if (key === 'night') {
            ratioValue = Math.round(nightRatio * 10000) / 100
        } else if (key === 'holidays') {
            ratioValue = Math.round(holidaysRatio * 10000) / 100
        }

        return ratioValue.toString() + '%'
    }



    const getLocationTraffic5mcut = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            // 通行量 呼び出し
            callApi('getLocationTraffic5mcut').then((res: any) => {
                if (res) {
                    dispatch(setObjLocationTraffic5mcut(res.getLocationTraffic5mcut[0]));
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }).catch((e) => {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
        } catch (e) {
            console.log(e)
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            setReload(false)
        }
    }
    const closeMessageDialog = () => {
        setMessage("")
        setHasButton(false)
        setMessageDialogOpen(false)
        getLocationTraffic5mcut()
    }

    const handleSelectedChange = (e: SelectChangeEvent<string>, labelName: string) => {
        if (labelName === 'car_another_survey') {
            setSeparateValue(e.target.value)
        } else if (labelName === 'people_another_survey') {
            setPeopleSeparateValue(e.target.value)
        }
        if (objLocationTraffic5mcut) {
            dispatch(setObjLocationTraffic5mcut({ ...objLocationTraffic5mcut, [labelName]: e.target.value }));
            // 更新フラグ
            dispatch(setSaveStatus({ ...objLongPageSaveStatus, TrafficVolume: true }));
        }
    }

    return (
        <div style={{ width: "100%", margin: "30px" }}>

            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE,
            }}
            />
            <Box><h3>{LOCAL_CONSTANT.LABEL.CAR_TRAFFIC}</h3></Box>
            <Box sx={{ display: 'flex', padding: '10px' }}>
                <FormLabel sx={{ padding: '14px 0' }}>別途調査必要時（該当を選択）</FormLabel>
                <Select
                    onChange={(e) => handleSelectedChange(e, "car_another_survey")}
                    value={separateValue}
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'car_another_survey')}
                >
                    {
                        codeMaster?.separate_investigation?.map((row: any) => (
                            <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                        ))
                    }
                </Select>
            </Box>
            <Box>
                <Button
                    sx={{ margin: '20px 0' }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'add_car')}
                    onClick={() => { onClickHandle(LOCAL_CONSTANT.VALUE.ADD_CAR) }}
                >{LOCAL_CONSTANT.LABEL.ADD}</Button>
            </Box>
            {dataCar.map((row: any) => (
                <Box sx={{ width: '96%' }} key={row.trafficType}>
                    {/* 車通行量 ユーザ種別がRFCの場合は
                            ・夜（モバ空）　20:00　～　21:30
                            ・土曜、休祝日又は行楽地のトップシーズン（モバ空）　10:30　～　16:30
                        を非表示
                    */}
                    {!((userType === "z003" && row.trafficType.toString() === "3") ||
                        (userType === "z003" && row.trafficType.toString() === "5")
                    ) && 
                        <TrafficVolumeCar
                            props={{ keyName: row.keyName, trafficType: row.trafficType, trafficData: row.data, setReload: setReload, nightRatio: nightRatio, holidaysRatio: holidaysRatio, nightRatioValue: nightRatioValue, holidaysRatioValue: holidaysRatioValue, userType: userType }}
                        />
                    }
                </Box>
            ))}
            {
                list().map((listData: any) => (
                    <Box sx={{ width: '96%' }} key={listData.target} id={listData.target + '-Container'}>
                        <Box key={listData.target + '-header'} id={listData.target + '-header'}>
                            {listData.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN &&
                                <Box><h3>{listData.header}</h3></Box>
                            }
                            {(listData.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT || listData.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT) &&
                                objLocationTraffic5mcut.cut_survey === '1' &&
                                <Box><h3>{listData.header}</h3></Box>
                            }
                            {
                                listData.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN &&
                                <Box sx={{ display: 'flex', padding: '10px' }}>
                                    <FormLabel sx={{ padding: '14px 0' }}>別途調査必要時（該当を選択）</FormLabel>
                                    <Select
                                        onChange={(e) => handleSelectedChange(e, "people_another_survey")}
                                        value={peopleSeparateValue}
                                        disabled={
                                            (
                                                objLocationTraffic5mcut.approval_confirm_flag === "1" ||
                                                objLocationTraffic5mcut.approval_research_mgr_flag === "1" ||
                                                objLocationTraffic5mcut.approval_research_gm_flag === "1" ||
                                                objLocationTraffic5mcut.approval_mgr_flag === "1" ||
                                                objLocationTraffic5mcut.approval_gm_flag === "1"
                                            ) ? true : 
                                            setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'people_another_survey')
                                        }
                                    >
                                        {
                                            codeMaster?.separate_investigation?.map((row: any) => (
                                                <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormLabel sx={{ padding: '14px' }}>5m外通行量調査</FormLabel>
                                    <Select
                                        onChange={(e) => handleSelectedChange(e, "cut_survey")}
                                        value={cutSurveyValue}
                                        disabled={
                                            (
                                                objLocationTraffic5mcut.approval_confirm_flag === "1" ||
                                                objLocationTraffic5mcut.approval_research_mgr_flag === "1" ||
                                                objLocationTraffic5mcut.approval_research_gm_flag === "1" ||
                                                objLocationTraffic5mcut.approval_mgr_flag === "1" ||
                                                objLocationTraffic5mcut.approval_gm_flag === "1"
                                            ) ? true : 
                                            setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'cut_survey')
                                        }
                                    >
                                        {
                                            codeMaster?.common_yesnone_kana?.map((row: any) => (
                                                <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Box>
                            }
                            {
                                listData.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN &&
                                <Button
                                    sx={{ margin: '20px 0' }}
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    disabled={
                                        (
                                            objLocationTraffic5mcut.approval_confirm_flag === "1" ||
                                            objLocationTraffic5mcut.approval_research_mgr_flag === "1" ||
                                            objLocationTraffic5mcut.approval_research_gm_flag === "1" ||
                                            objLocationTraffic5mcut.approval_mgr_flag === "1" ||
                                            objLocationTraffic5mcut.approval_gm_flag === "1"
                                        ) ? true : setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'add_people')
                                    }
                                    onClick={() => { onClickHandle(LOCAL_CONSTANT.VALUE.ADD_PEOPLE) }}
                                >{LOCAL_CONSTANT.LABEL.ADD}</Button>
                            }
                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} key={listData.target + '-left-right'}>
                                <Box sx={{ width: '49%' }} key={listData.target + '-left'} id={listData.target + '-left'}>
                                    {dataPeople1.map((row: any) => (
                                        <TrafficVolumePeople
                                            key={row.trafficType + '-left'}
                                            props={{
                                                keyName: row.keyName,
                                                trafficType: row.trafficType,
                                                trafficData: row.data,
                                                target: listData.target,
                                                setReload: setReload,
                                                nightRatio: nightRatio,
                                                holidaysRatio: holidaysRatio,
                                                nightRatioValue: nightRatioValue,
                                                holidaysRatioValue: holidaysRatioValue,
                                                userType: userType
                                            }}
                                        />
                                    ))}
                                </Box>
                                <Box sx={{ width: '49%' }} key={listData.target + '-right'} id={listData.target + '-right'}>
                                    {dataPeople2.map((row: any) => (
                                        <TrafficVolumePeople
                                            key={row.trafficType + '-right'}
                                            props={{
                                                keyName: row.keyName,
                                                trafficType: row.trafficType,
                                                trafficData: row.data,
                                                target: listData.target,
                                                setReload: setReload,
                                                nightRatio: nightRatio,
                                                holidaysRatio: holidaysRatio,
                                                nightRatioValue: nightRatioValue,
                                                holidaysRatioValue: holidaysRatioValue,
                                                userType: userType
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} key={listData.target + '-n'}>
                                {dataPeople3.map((row: any) => (
                                    <Box sx={{ width: '49%' }} key={row.trafficType}>
                                        {/* 人通行量 ユーザ種別がRFCの場合は夜（モバ空）　20:00　～　21:30を非表示 */}
                                        {!(userType === "z003" && row.trafficType.toString() === "11")
                                            && 
                                                <TrafficVolumePeople
                                                    props={{
                                                        keyName: row.keyName,
                                                        trafficType: row.trafficType,
                                                        trafficData: row.data,
                                                        target: listData.target,
                                                        setReload: setReload,
                                                        nightRatio: nightRatio,
                                                        holidaysRatio: holidaysRatio,
                                                        nightRatioValue: nightRatioValue,
                                                        holidaysRatioValue: holidaysRatioValue,
                                                        userType: userType
                                                    }}
                                                />
                                        }
                                    </Box>
                                ))}
                            </Box>
                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} key={listData.target + '-holyDays'}>
                                {dataPeople4.map((row: any) => (
                                    <Box sx={{ width: '49%' }} key={row.trafficType}>
                                        {/* 人通行量 ユーザ種別がRFCの場合は土曜、休祝日又は行楽地のトップシーズン（モバ空）　10:30　～　16:30を非表示 */}
                                        {!(userType === "z003" && row.trafficType.toString() === "14") 
                                            && 
                                                <TrafficVolumePeople
                                                    props={{
                                                        keyName: row.keyName,
                                                        trafficType: row.trafficType,
                                                        trafficData: row.data,
                                                        target: listData.target,
                                                        setReload: setReload,
                                                        nightRatio: nightRatio,
                                                        holidaysRatio: holidaysRatio,
                                                        nightRatioValue: nightRatioValue,
                                                        holidaysRatioValue: holidaysRatioValue,
                                                        userType: userType
                                                    }}
                                                />
                                        }
                                    </Box>
                                ))}
                            </Box>
                            {listData.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN &&
                                objLocationTraffic5mcut.cut_survey === '1' &&
                                <LocationTraffic5mcut props={{}} />
                            }
                        </Box>
                    </Box>
                ))
            }
            <OtherTraffic props={{ userType: userType }} />
            <AddTrafficVolume props={{ open: open, handleModal: setOpen, target: target, setReload: setReload, dataList: dataList }} />

        </div>
    );

}


export default TrafficVolume;
