import * as React from "react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useUserContext } from "../../user-provider";
import PdfViewer from "../../utility/PdfViewer";
import { useUtilityContext } from "../../utility-provider";
import { apiRequest } from "../../api/ApiCall";
import {
    setObjDrawing,
    setDrawingPdf,
    setTargetFileType,
    setDeleteFile,
} from "../../features/drawingSlice";
import {
    upsertDrawingPdfParam,
} from "../subPage/Drawing/DrawingFunction";
import {
    Box,
    Button,
    Grid,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import { LOCAL_CONSTANT } from "../Const";
import { userLogging } from "../../utility/firestore";
import ObstaclesEtc from "../subPage/Drawing/ObstaclesEtc";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {
    getFileMetadata,
    uploadFile,
    deleteUploadFile,
} from "../../utility/storage";
import MessageDialog from "../components/MessageDialog";
import { DeleteConfirmationModal } from "../ResearchReport/DeleteConfirmationModal";
import { setPermissionFunc } from "../components/PermissionFunc";
import { staffName } from "../Common";
import {
    DrawingStringTypeList,
    initDrawingCommentTextFieldObj,
} from "../../data/type";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DeficiencyCommentRegistModal from "../components/DeficiencyCommentRegistModal";
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    iconHover,
} from "../components/ChatBubbleAndEditIconStyle";
import { commonFileDownload } from "../../page/Common";
import dayjs from "dayjs";

const headerCell = {
    backgroundColor: "#D9D9D9",
    fontSize: "12px",
    color: "black",
    borderBottom: "1px solid black",
    fontWeight: "Bold",
};

const excelListTemplate = () => {
    return [
        JSON.parse(JSON.stringify(init_layout)),
        JSON.parse(JSON.stringify(init_placement)),
        JSON.parse(JSON.stringify(init_designer_placement)),
    ];
};

const init_layout = {
    recreate: "none",
    file_name: LOCAL_CONSTANT.LABEL.LAYOUT_DIAGRAM,
    uploadDate: "",
    uplowder: "",
    file_path: "",
    target: "layout",
};
const init_placement = {
    recreate: "none",
    file_name: LOCAL_CONSTANT.LABEL.PLACEMENT_DIAGRAM,
    uploadDate: "",
    uplowder: "",
    file_path: "",
    target: "placement",
};
const init_designer_placement = {
    recreate: "none",
    file_name: LOCAL_CONSTANT.LABEL.DESIGNER_PLACEMENT_DIAGRAM,
    uploadDate: "",
    uplowder: "",
    file_path: "",
    target: "designer_placement",
};

export interface resultDialogProps {
    props: {
    };
};

const Drawing: React.FC<resultDialogProps> = ({ props }) => {
    const objDrawing = useAppSelector((state) => state.drawing.obj);

    //ユーザーIDを取得
    const [userId, setUserId] = useState<string>("");
    const [userType, setUserType] = useState("");
    const user = useUserContext();

    const utilityCtx = useUtilityContext();

    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] =
        React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);

    //プレビュー
    const previewPdfPath = useAppSelector((state) => state.drawing.pdfPath);
    // ファイルアップロード対象
    const targetFileType = useAppSelector(
        (state) => state.drawing.targetFileType
    );
    // ファイルデリート対象
    const objDeleteFile = useAppSelector((state) => state.drawing.deleteFile);

    //候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //タスクIDを取得するため
    const objLocationBase = useAppSelector(
        (state) => state.longPage.locationBase
    );

    const dispatch = useAppDispatch();

    //モーダル開閉フラグ(削除確認)
    const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
    const [path, setPath] = useState<string>("");
    const [date, setDate] = useState<string>("");
    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] =
        useState(false);
    //不備コメントモーダル内、TextFieldのコメント
    const [drawingCommentTextFieldObj, setDrawingCommentTextFieldObj] =
        useState<DrawingStringTypeList>(initDrawingCommentTextFieldObj);

    const [excelList, setExcelList] = useState<any[]>(excelListTemplate);

    useEffect(() => {
        if (excelList.length > 0 && objDrawing) {
            if (objDrawing.layout_file_path !== "") {
                whiteExcelList(
                    0,
                    objDrawing.layout_file_path,
                    objDrawing.layout_file_user
                );
            }
            if (objDrawing.placement_file_path !== "") {
                whiteExcelList(
                    1,
                    objDrawing.placement_file_path,
                    objDrawing.placement_file_user
                );
            }
            if (objDrawing.designer_placement_file_path !== "") {
                whiteExcelList(
                    2,
                    objDrawing.designer_placement_file_path,
                    objDrawing.designer_placement_file_user
                );
            }
        }
    }, [objDrawing]);

    const handlePreviewChange = (pdfPath: string) => {
        //表示中のPDFレコードを押下時非表示
        if (pdfPath !== "") {
            const path =
                "gs://" +
                process.env.REACT_APP_FIREBASE_STORAGE_BUCKET +
                "/" +
                objLongPageParam.planAreaId +
                "/" +
                objLongPageParam.branch +
                pdfPath;
            if (previewPdfPath === path) {
                dispatch(setDrawingPdf(""));
            } else {
                dispatch(setDrawingPdf(path));
            }
        }
    };

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    const handleClickFile = (target: string) => {
        dispatch(setTargetFileType(target));
        const fileElem = document.getElementById("drawingFileElem");
        if (fileElem) {
            fileElem.click();
        }
    };

    const handleChangeFile = (newFile: any) => {
        fileUpload(newFile.target.files[0]);
        newFile.target.value = "";
    };

    //ファイル保存
    const fileDownload = (file_path: string) => {
        const filePath =
            "/" +
            objLongPageParam.planAreaId +
            "/" +
            objLongPageParam.branch +
            file_path;
        (async () => {
            try {
                getFileMetadata(filePath).then((meta: any) => {
                    commonFileDownload(meta.fullPath, meta.name);
                });
            } catch (e) {
                console.log(e);
            }
        })();
    };
    
    //ファイル存在チェック
    const checkFileName = (file: any) => {
        let flag = false
        excelList.map((val:any)=>{
            if(val.file_name === file.name){
                flag = true
            }
        })
        return flag;
    };

    // アップロード
    const fileUpload = (file: any) => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_PDF);
                setMessageDialogOpen(true);
                let fileName = file.name;
                //同名のファイルがアップされている場合はアップロードファイルに日付追加
                if(checkFileName(file)){
                    const date = new Date();
                    const nowDate = date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, "0") + date.getDate().toString().padStart(2, "0") + 
                                    date.getHours().toString().padStart(2, "0") + date.getMinutes().toString().padStart(2, "0") + date.getSeconds().toString().padStart(2, "0"); 
                    fileName = file.name.replace(".pdf", "") + "_" +  nowDate +".pdf";
                }
                const filePath = "/drawing/" + fileName;
                const copyData = JSON.parse(JSON.stringify(objDrawing));
                let target_column = "";
                if (["layout"].includes(targetFileType)) {
                    copyData.layout_file_path = filePath;
                    copyData.layout_file_user = userId;
                    target_column = LOCAL_CONSTANT.LABEL.LAYOUT_DIAGRAM + LOCAL_CONSTANT.LABEL.UPLOAD
                } else if (["placement"].includes(targetFileType)) {
                    copyData.placement_file_path = filePath;
                    copyData.placement_file_user = userId;
                    target_column = LOCAL_CONSTANT.LABEL.PLACEMENT_DIAGRAM + LOCAL_CONSTANT.LABEL.UPLOAD
                } else if (["designer_placement"].includes(targetFileType)) {
                    copyData.designer_placement_file_path = filePath;
                    copyData.designer_placement_file_user = userId;
                    target_column = LOCAL_CONSTANT.LABEL.DESIGNER_PLACEMENT_DIAGRAM + LOCAL_CONSTANT.LABEL.UPLOAD
                }
                await uploadFile(
                    file,
                    objLongPageParam.planAreaId +
                    "/" +
                    objLongPageParam.branch +
                    "/drawing/" +
                    fileName
                );
                const file_log = {
                    plan_area_id: objLongPageParam.planAreaId,
                    branch: objLongPageParam.branch,
                    log_type: "19",
                    target_table: "location_drawing",
                    target_column: target_column,
                    target_before_value: "",
                    target_after_value: "",
                };
                //DB保存
                let params = upsertDrawingPdfParam(
                    copyData,
                    objLongPageParam.planAreaId,
                    objLongPageParam.branch,
                    file_log
                );
                updateRegist(
                    params,
                    LOCAL_CONSTANT.FUNCTION_NAME.DRAWING_PDF_CREATE
                );
                //Redux保存
                dispatch(setObjDrawing(copyData));
                if (["layout"].includes(targetFileType)) {
                    whiteExcelList(0, filePath, userId);
                } else if (["placement"].includes(targetFileType)) {
                    whiteExcelList(1, filePath, userId);
                } else if (["designer_placement"].includes(targetFileType)) {
                    whiteExcelList(2, filePath, userId);
                }
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD_PDF);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_UPLOAD_PDF);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    const whiteExcelList = (index: number, file_path: string, user: string) => {
        const filePath =
            "/" +
            objLongPageParam.planAreaId +
            "/" +
            objLongPageParam.branch +
            file_path;
        getFileMetadata(filePath).then((meta: any) => {
            setExcelList((prevList) => {
                const updateList = [...prevList];
                updateList[index] = {
                    ...updateList[index],
                    file_name : meta.name,
                    uploadDate : dayjs(meta.updated).format(
                        "YYYY/MM/DD HH:mm:ss"
                    ),
                    uplowder : user,
                    file_path : file_path
                };
                return updateList;
            });
        });
    };

    const handleDeleteConfModalOpen = (val: any) => {
        dispatch(setDeleteFile(val));
        setDeleteConfModalOpen(true);
    };

    //PDF削除
    const handleDeleteChange = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_PDF);
                setMessageDialogOpen(true);
                const copyData = JSON.parse(JSON.stringify(objDrawing));

                let target_column = "";
                if (["layout"].includes(objDeleteFile.target)) {
                    copyData.layout_file_path = "";
                    copyData.layout_file_user = "";
                    target_column = LOCAL_CONSTANT.LABEL.LAYOUT_DIAGRAM + LOCAL_CONSTANT.LABEL.DELETE
                } else if (["placement"].includes(objDeleteFile.target)) {
                    copyData.placement_file_path = "";
                    copyData.placement_file_user = "";
                    target_column = LOCAL_CONSTANT.LABEL.PLACEMENT_DIAGRAM + LOCAL_CONSTANT.LABEL.DELETE
                } else if (
                    ["designer_placement"].includes(objDeleteFile.target)
                ) {
                    copyData.designer_placement_file_path = "";
                    copyData.designer_placement_file_user = "";
                    target_column = LOCAL_CONSTANT.LABEL.DESIGNER_PLACEMENT_DIAGRAM + LOCAL_CONSTANT.LABEL.DELETE
                }
                //CloudStorageから削除対象のPDF情報を取得
                const path =
                    objLongPageParam.planAreaId +
                    "/" +
                    objLongPageParam.branch +
                    objDeleteFile.file_path;
                const deleteFile = await getFileMetadata(path);
                await deleteUploadFile(deleteFile, path);
                // //DB保存
                const file_log = {
                    plan_area_id: objLongPageParam.planAreaId,
                    branch: objLongPageParam.branch,
                    log_type: "19",
                    target_table: "location_drawing",
                    target_column: target_column,
                    target_before_value: "",
                    target_after_value: "",
                };
                let params = upsertDrawingPdfParam(
                    copyData,
                    objLongPageParam.planAreaId,
                    objLongPageParam.branch,
                    file_log
                );
                updateRegist(
                    params,
                    LOCAL_CONSTANT.FUNCTION_NAME.DRAWING_PDF_DELETE
                );
                // //Redux保存
                dispatch(setObjDrawing(copyData));
                if (["layout"].includes(objDeleteFile.target)) {
                    excelList[0] = JSON.parse(JSON.stringify(init_layout));
                } else if (["placement"].includes(objDeleteFile.target)) {
                    excelList[1] = JSON.parse(JSON.stringify(init_placement));
                } else if (
                    ["designer_placement"].includes(objDeleteFile.target)
                ) {
                    excelList[2] = JSON.parse(
                        JSON.stringify(init_designer_placement)
                    );
                }
                //プレビューを戻す
                dispatch(setDrawingPdf(""));
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_PDF);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_PDF);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    const updateRegist = (params: any, function_name: string) => {
        userLogging(function_name, params, user);
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    // 吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (columnName: string, labelName: string) => {
        setColumnNameStr(columnName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setDrawingCommentTextFieldObj({
            ...drawingCommentTextFieldObj,
            [labelName]: "",
        }); //テキストフィールドのコメントを初期化
    };

    // モーダル内でのコメント取得
    const handleCommentChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        setDrawingCommentTextFieldObj({
            ...drawingCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };

    // モーダル内の保存ボタンをクリックしたときコメントを保存
    const handleSaveComment = (columnName: string, labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_drawing",
                column_name: columnName,
                comment: drawingCommentTextFieldObj[labelName],
            },
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
            }
        })();
    };

    //ユーザーIDを取得タイミングによる画面制御
    useEffect(() => {
        if (JSON.parse(user.userData || "null") !== null) {
            setUserId(JSON.parse(user.userData || "null").staff_number);
            setUserType(JSON.parse(user.userData || "null").user_type);
        }
    }, [user]);

    return (
        <Box style={{ width: "100%", margin: "30px" }}>
            <Box>
                <MessageDialog
                    props={{
                        open: messageDialogOpen,
                        handleModal: closeMessageDialog,
                        body: message,
                        hasButton: hasButton,
                        buttonBody: LOCAL_CONSTANT.LABEL.CLOSE,
                    }}
                />
                <input
                    id="drawingFileElem"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleChangeFile}
                    accept=".pdf"
                />
                <Button
                    sx={{ margin: "20px 10px 20px 0" }}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={(e) => {
                        handleClickFile("layout");
                    }}
                    disabled={setPermissionFunc(
                        userType,
                        objLocationBase.task_id,
                        LOCAL_CONSTANT.NAVI.DRAWING,
                        "uploadButton"
                    )}
                >
                    {LOCAL_CONSTANT.LABEL.LAYOUT_DIAGRAM}
                </Button>
                <Button
                    sx={{ margin: "20px 10px" }}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={(e) => {
                        handleClickFile("placement");
                    }}
                    disabled={setPermissionFunc(
                        userType,
                        objLocationBase.task_id,
                        LOCAL_CONSTANT.NAVI.DRAWING,
                        "uploadButton"
                    )}
                >
                    {LOCAL_CONSTANT.LABEL.PLACEMENT_DIAGRAM}
                </Button>
                <Button
                    sx={{ margin: "20px 10px" }}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={(e) => {
                        handleClickFile("designer_placement");
                    }}
                    disabled={setPermissionFunc(
                        userType,
                        objLocationBase.task_id,
                        LOCAL_CONSTANT.NAVI.DRAWING,
                        "uploadButton"
                    )}
                >
                    {LOCAL_CONSTANT.LABEL.DESIGNER_PLACEMENT_DIAGRAM}
                </Button>
                {/* 不備コメント、吹き出しアイコンボタン (リサーチのみ表示)*/}
                {userType === "z001" && (
                    <>
                        <IconButton
                            disabled={setPermissionFunc(
                                userType,
                                objLocationBase.task_id,
                                LOCAL_CONSTANT.NAVI.DRAWING,
                                "drawing_upload"
                            )}
                            onClick={() => {
                                handleChatBubbleClick(
                                    "drawing_upload",
                                    "drawingUpload"
                                );
                            }}
                            sx={iconHover}
                        >
                            <Box
                                sx={chatBubbleCircleSx(
                                    setPermissionFunc(
                                        userType,
                                        objLocationBase.task_id,
                                        LOCAL_CONSTANT.NAVI.DRAWING,
                                        "drawing_upload"
                                    )
                                )}
                            >
                                <ChatBubbleIcon sx={chatBubbleIconSx} />
                            </Box>
                        </IconButton>
                    </>
                )}
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ paddingRight: "40px" }}>
                    <TableContainer
                        sx={{ width: "100%", border: "1px solid black" }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={headerCell}>
                                        {LOCAL_CONSTANT.LABEL.FILE_NAME}
                                    </TableCell>
                                    <TableCell sx={headerCell}>
                                        {LOCAL_CONSTANT.LABEL.UPLOAD_DATE}
                                    </TableCell>
                                    <TableCell sx={headerCell}>
                                        {LOCAL_CONSTANT.LABEL.UPLOADER}
                                    </TableCell>
                                    <TableCell sx={headerCell}></TableCell>
                                    <TableCell sx={headerCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {excelList.map((val, index) => (
                                    <TableRow className="tableRow" key={index}>
                                        <TableCell onClick={() => handlePreviewChange(val.file_path)}>
                                            {val.file_name}
                                        </TableCell>
                                        <TableCell onClick={() => handlePreviewChange(val.file_path)}>
                                            {val.uploadDate}
                                        </TableCell>
                                        <TableCell onClick={() => handlePreviewChange(val.file_path)}>
                                            {staffName(val.uplowder)}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                sx={{ color: "black", display: val.file_path != "" ? "block" : "none", }}
                                                onClick={() => fileDownload(val.file_path)}
                                            ><DownloadIcon /></IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                sx={{ color: "black", display: val.file_path != "" ? "block" : "none", }}
                                                onClick={() => handleDeleteConfModalOpen(val)}
                                            ><DeleteIcon /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {/* プレビュー */}
            {previewPdfPath !== "" && (
                <Box sx={{ paddingTop: "30px" }}>
                    <PdfViewer props={{ pdfPath: previewPdfPath }} />
                </Box>
            )}

            {/* 削除確認モーダル */}
            <DeleteConfirmationModal
                modalOpen={deleteConfModalOpen}
                setModalOpen={setDeleteConfModalOpen}
                deleteMethod={handleDeleteChange}
            />

            <ObstaclesEtc props={{}} />

            {/*不備コメント モーダル画面 */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj: drawingCommentTextFieldObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment,
                }}
            />
        </Box>
    );
};

export default Drawing;
