import React from "react";

import {
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    Button,
    Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { apiRequest } from "../../../api/ApiCall";
import { useUtilityContext } from '../../../utility-provider';
import MessageDialog from '../../components/MessageDialog';
import { LOCAL_CONSTANT } from "../../Const";
import { setMarketCut } from '../../../features/conflictSlice';

export interface disapprovalMarketCutModalProps {
    props: {
        open: boolean,
        handleModal: any,
        planAreaId: string | null,
        branch: string | null,
    }
}

const DisapprovalMarketCutModal: React.FC<disapprovalMarketCutModalProps> = ({ props }) => {
    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();

    const [open, setOpen] = React.useState(false);
    // 
    const marketCut = useAppSelector((state) => state.conflict.market_cut);

    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);
    const [countStart, setCountStart] = React.useState(false)
    const [countTimer, setCountTimer] = React.useState<number>(5)

    React.useEffect(() => {
        setOpen(props.open);
        if (props.open) {
            setCountStart(true)
        }
    }, [props.open])

    const handleClose = () => {
        setCountTimer(5)
        setCountStart(false)
        props.handleModal(false)
    }

    const closeMessageDialog = () => {
        handleClose()
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    const onRegist = () => {
        (async () => {
            try {
                if (utilityCtx.showSpinner) {
                    utilityCtx.showSpinner()
                }
                // メッセージモーダル表示
                setHasButton(false);
                setMessage("商圏カット承認を解除中です");
                setMessageDialogOpen(true);
                const params = {
                    mode: "regist",
                    endPoint: "/location/Conflict/v1/disapproval-locationConflictMarketCut",
                    query: {
                        plan_area_id: props.planAreaId,
                        branch: props.branch,
                        log_type: '18',
                        target_table: '商圏カット',
                        target_column: "承認解除",
                        target_before_value: "",
                        target_after_value: "",
                    }
                };
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }

                dispatch(
                    setMarketCut({
                        ...marketCut,
                        ['approval_confirm_flag']: '',
                        ['approval_confirm_user']: '',
                        ['approval_confirm_department']: '',
                        ['approval_confirm_date']: '',
                        ['approval_confirm_stamp']: '',
                        ['approval_research_mgr_flag']: '',
                        ['approval_research_mgr_user']: '',
                        ['approval_research_mgr_department']: '',
                        ['approval_research_mgr_date']: '',
                        ['approval_research_mgr_stamp']: '',
                        ['approval_research_gm_flag']: '',
                        ['approval_research_gm_user']: '',
                        ['approval_research_gm_department']: '',
                        ['approval_research_gm_date']: '',
                        ['approval_research_gm_stamp']: '',
                        ['approval_mgr_flag']: '',
                        ['approval_mgr_user']: '',
                        ['approval_mgr_department']: '',
                        ['approval_mgr_date']: '',
                        ['approval_mgr_stamp']: '',
                        ['approval_gm_flag']: '',
                        ['approval_gm_user']: '',
                        ['approval_gm_department']: '',
                        ['approval_gm_date']: '',
                        ['approval_gm_stamp']: ''
                    }))
                setHasButton(true);
                setMessage("商圏カット承認を解除しました");
            } catch (e) {
                console.log(e);
                setHasButton(true);
                setMessage("商圏カット承認を解除に失敗しました");
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    // 計測カウントダウン
    React.useEffect(() => {
        const countDownInterval = setInterval(() => {
            if (countStart) {
                if (countTimer === 1) {
                    clearInterval(countDownInterval)
                    setCountStart(false)
                } else if (countTimer && countTimer > 1) {
                    setCountTimer(countTimer - 1)
                }
            } else {
                clearInterval(countDownInterval)
            }
        }, 1000)
        return () => {
            clearInterval(countDownInterval)
        }
    }, [countTimer, countStart])

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText>商圏カット承認を解除してよろしいですか</DialogContentText>
                    <DialogContentText sx={{ color: 'red' }}>※確認担当への事前連絡が必要です</DialogContentText>
                    <DialogContentText sx={{ color: 'red' }}>※この操作は元に戻す事ができません</DialogContentText>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0' }}>
                        <Box>
                            <Button
                                variant="outlined" color="success" size="medium"
                                onClick={handleClose}
                            >キャンセル</Button>
                        </Box>
                        {
                            countStart &&
                            <Box>
                                <Button
                                    variant="contained" color="error" size="medium"
                                >{countTimer}</Button>
                            </Box>
                        }
                        {
                            !countStart &&
                            <Box>
                                <Button
                                    variant="contained" color="error" size="medium"
                                    onClick={onRegist}
                                >商圏カット承認を外す</Button>
                            </Box>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
            }} />
        </div>
    );
}

export default DisapprovalMarketCutModal;
