import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import { LOCAL_CONSTANT } from "../../Const";
import {
    Box,
    Typography,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
    FormControl,
} from "@mui/material";
import {
    setSelecterDisplay,
    conflictBusinessType,
    handleChangeValue,
    checkHidenColumn,
} from "./AddConflictFunction";
import {
    changeConflictTextColor,
    changeBGColor
} from "../../../data/tableFunc";

export interface ownStoreProps {
    props: {
        type: string;
        ownStoreList: any
        storeData: any;
        editStore: any;
        codeMaster: any;
        editStoreDisabled: boolean;
        draw: boolean;
        setDraw: any;
    };
}

export const OwnStore: React.FC<ownStoreProps> = ({ props }) => {

    // 表示色変更用BQログデータ
    const objRrBranchLog = useAppSelector((state) => state.longPage.rrBranchLog);

    // 競合自店 項目動的対応 非活性 
    const typographyOwnStyle = (isUserType: boolean, objRrBranchLog: any, labelName: string, storeData: any) => {
        return {
            width: "180px",
            minHeight: "50px",
            height: "50px",
            padding: "10px 0",
            border: "1px solid black",
            color: changeConflictTextColor(
                objRrBranchLog,
                LOCAL_CONSTANT.NAVI.CONFLICT,
                labelName,
                storeData.conflict_type,
                storeData.conflict_sequence
            ),
            backgroundColor: changeBGColor(isUserType, storeData[labelName])
        }
    };


    // 競合自店 項目動的対応 活性
    const textFiledOwnStyle = (isUserType: boolean, objRrBranchLog: any, labelName: string, storeData: any) => {
        return {
            minHeight: "50px",
            height: "50px",
            minWidth: "180px",
            width: "180px",
            border: "1px solid black",
            color: changeConflictTextColor(
                objRrBranchLog,
                LOCAL_CONSTANT.NAVI.CONFLICT,
                labelName,
                storeData.conflict_type,
                storeData.conflict_sequence
            ),
            backgroundColor: changeBGColor(isUserType, storeData[labelName]),
            "& input": {
                padding: "14px 0",
            }
        }
    };

    // 競合自店 プルダウン 項目動的対応 活性
    const selectOwnStyle = (isUserType: boolean, objRrBranchLog: any, labelName: string, storeData: any) => {
        return {
            minHeight: "50px",
            height: "50px",
            minWidth: "180px",
            width: "180px",
            border: "1px solid black",
            color: changeConflictTextColor(
                objRrBranchLog,
                LOCAL_CONSTANT.NAVI.CONFLICT,
                labelName,
                storeData.conflict_type,
                storeData.conflict_sequence
            ),
            backgroundColor: changeBGColor(isUserType, storeData[labelName])
        }
    };

    const storeDataVal = (key: string) => {
        let res = "";
        if (props.storeData !== undefined) {
            res = props.storeData[key];
        }
        return res;
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        data: any,
        labelName: string
    ) => {
        data = handleChangeValue(e, data, labelName)
        data["conflict_business_type"] = conflictBusinessType(
            data,
            data["business_type"]
        );
        props.setDraw(!props.draw);
    };

    const handleSelectedChange = (
        e: SelectChangeEvent<string>,
        data: any,
        labelName: string
    ) => {
        data[labelName] = e.target.value;
        if (labelName === "location") {
            const side_road_type = ["0", "1", "2", "3"];
            data["side_road_type"] = side_road_type[data[labelName]];
        }
        props.setDraw(!props.draw);
    };


    return (
        <>
            <Box
                sx={{
                    minWidth: "100%",
                    width: "100%",
                    textAlign: "center",
                    border: "1px solid black",
                }}
            >
                {props.ownStoreList.map((row: any) => (
                    <Box key={row.key}>
                        {row.cols === 0 && (
                            <Box
                                sx={{ display: "flex" }}
                                key={row.key + "-" + row.cols}
                            >
                                <Typography
                                    sx={{
                                        width: "200px",
                                        minHeight: "50px",
                                        height: "50px",
                                        padding: "10px 0",
                                        border: "1px solid black",
                                        backgroundColor: "#D9D9D9",
                                        fontSize: row.fontSize,
                                    }}
                                >
                                    {row.labelName}
                                </Typography>
                                <Typography
                                    sx={{
                                        width: "180px",
                                        minHeight: "50px",
                                        height: "50px",
                                        padding: "10px 0",
                                        border: "1px solid black",
                                        backgroundColor: "#D9D9D9",
                                        color: "black",
                                    }}
                                >
                                    {row.keyName}
                                </Typography>
                            </Box>
                        )}
                        {row.cols === 1 && (
                            <Box
                                sx={{
                                    display: checkHidenColumn(
                                        "CVSLabel",
                                        row.key,
                                        props.type
                                    )
                                        ? "none"
                                        : "flex",
                                }}
                                key={row.key + "-" + row.cols}
                            >
                                {row.requireStatus === 0 && (
                                    <Typography
                                        sx={{
                                            width: "200px",
                                            minHeight: "50px",
                                            height: "50px",
                                            padding: "10px 0",
                                            border: "1px solid black",
                                            fontSize: row.fontSize,
                                        }}
                                    >
                                        {row.labelName}
                                    </Typography>
                                )}
                                {row.requireStatus === 1 && (
                                    <Typography
                                        sx={{
                                            width: "200px",
                                            minHeight: "50px",
                                            height: "50px",
                                            padding: "10px 0",
                                            border: "1px solid black",
                                            fontSize: row.fontSize,
                                        }}
                                    >
                                        {row.labelName}
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "10px",
                                            }}
                                        >
                                            ＊
                                        </span>
                                    </Typography>
                                )}
                                {row.requireStatus === 2 && (
                                    <Typography
                                        sx={{
                                            width: "200px",
                                            minHeight: "50px",
                                            height: "50px",
                                            padding: "10px 0",
                                            border: "1px solid black",
                                            fontSize: row.fontSize,
                                        }}
                                    >
                                        {row.labelName}
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "10px",
                                            }}
                                        >
                                            ＊＊
                                        </span>
                                    </Typography>
                                )}
                                {row.target === "gray-data" && (
                                    <Typography
                                        sx={{
                                            minWidth: "180px",
                                            width: "180px",
                                            minHeight: "50px",
                                            height: "50px",
                                            padding: "10px 0",
                                            border: "1px solid black",
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.5)",
                                        }}
                                    >
                                        {row.value}
                                    </Typography>
                                )}
                                {row.target === "data" && (
                                    <Typography
                                        sx={{
                                            minWidth: "180px",
                                            width: "180px",
                                            minHeight: "50px",
                                            height: "50px",
                                            padding: "10px 0",
                                            border: "1px solid black",
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.06)",
                                        }}
                                    >
                                        {row.value}
                                    </Typography>
                                )}
                                {props.storeData !== undefined &&
                                    row.target === "db-value" && (
                                        <Typography
                                            sx={{
                                                minWidth: "180px",
                                                width: "180px",
                                                minHeight: "50px",
                                                height: "50px",
                                                padding: "10px 0",
                                                border: "1px solid black",
                                                backgroundColor:
                                                    "rgba(0, 0, 0, 0.06)",
                                            }}
                                        >
                                            {setSelecterDisplay(
                                                props.codeMaster,
                                                row.keyName,
                                                storeDataVal(row.keyName)
                                            )}
                                        </Typography>
                                    )}
                                {props.editStore === false &&
                                    props.storeData !== undefined &&
                                    row.target === "db-text" && (
                                        <Typography
                                            sx={typographyOwnStyle(
                                                true,
                                                objRrBranchLog,
                                                row.keyName,
                                                props.storeData
                                            )}
                                        >
                                            {storeDataVal(row.keyName)}
                                        </Typography>
                                    )}
                                {props.editStore === true &&
                                    row.target === "db-text" && (
                                        <TextField
                                            disabled={props.editStoreDisabled}
                                            sx={textFiledOwnStyle(
                                                !props.editStore,
                                                objRrBranchLog,
                                                row.keyName,
                                                props.storeData
                                            )}
                                            onChange={(e) => {
                                                handleChange(
                                                    e,
                                                    props.storeData,
                                                    row.keyName
                                                );
                                            }}
                                            value={storeDataVal(
                                                row.keyName
                                            )}
                                        ></TextField>
                                    )}
                                {props.editStore === false &&
                                    props.storeData !== undefined &&
                                    row.target === "db-select" && (
                                        <Typography
                                            sx={typographyOwnStyle(
                                                !props.editStore,
                                                objRrBranchLog,
                                                row.keyName,
                                                props.storeData
                                            )}
                                        >
                                            {setSelecterDisplay(
                                                props.codeMaster,
                                                row.keyName,
                                                props.storeData[row.keyName]
                                            )}
                                        </Typography>
                                    )}
                                {props.editStore === true &&
                                    row.target === "db-select" && (
                                        <FormControl>
                                            <Select
                                                onChange={(e) =>
                                                    handleSelectedChange(
                                                        e,
                                                        props.storeData,
                                                        row.keyName
                                                    )
                                                }
                                                sx={selectOwnStyle(
                                                    !props.editStore,
                                                    objRrBranchLog,
                                                    row.keyName,
                                                    props.storeData
                                                )}
                                                value={
                                                    props.storeData[row.keyName]
                                                }
                                            >
                                                {props.codeMaster?.[row.SELECT_KEY].map(
                                                    (data: any) => (
                                                        <MenuItem
                                                            key={
                                                                data.code_value
                                                            }
                                                            value={
                                                                data.code_value
                                                            }
                                                        >
                                                            {
                                                                data.code_display
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                            </Box>
                        )}
                        {row.cols === 2 && (
                            <Box
                                sx={{ display: "flex" }}
                                key={row.key + "-" + row.cols}
                            >
                                <Box
                                    sx={{ width: "200px", display: "flex" }}
                                >
                                    <Typography
                                        sx={{
                                            width: "20%",
                                            padding: "10px 6px",
                                            border: "1px solid black",
                                            writingMode: "vertical-lr",
                                        }}
                                    >
                                        {row.labelName}
                                    </Typography>
                                    <Box sx={{ width: "80%" }}>
                                        {row.colsLabel.map((label: any) =>
                                            label.requireStatus === 0 ? (
                                                <Typography
                                                    sx={{
                                                        minHeight: "50px",
                                                        height: "50px",
                                                        padding: "10px 0",
                                                        border: "1px solid black",
                                                        fontSize:
                                                            row.fontSize,
                                                    }}
                                                    key={
                                                        row.key +
                                                        "-" +
                                                        row.cols +
                                                        "-" +
                                                        label.key
                                                    }
                                                >
                                                    {label.labelName}
                                                </Typography>
                                            ) : label.requireStatus ===
                                                1 ? (
                                                <Typography
                                                    sx={{
                                                        minHeight: "50px",
                                                        height: "50px",
                                                        padding: "10px 0",
                                                        border: "1px solid black",
                                                        fontSize:
                                                            row.fontSize,
                                                    }}
                                                    key={
                                                        row.key +
                                                        "-" +
                                                        row.cols +
                                                        "-" +
                                                        label.key
                                                    }
                                                >
                                                    {label.labelName}
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize:
                                                                "10px",
                                                        }}
                                                    >
                                                        ＊
                                                    </span>
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        minHeight: "50px",
                                                        height: "50px",
                                                        padding: "10px 0",
                                                        border: "1px solid black",
                                                        fontSize:
                                                            row.fontSize,
                                                    }}
                                                    key={
                                                        row.key +
                                                        "-" +
                                                        row.cols +
                                                        "-" +
                                                        label.key
                                                    }
                                                >
                                                    {label.labelName}
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize:
                                                                "10px",
                                                        }}
                                                    >
                                                        ＊＊
                                                    </span>
                                                </Typography>
                                            )
                                        )}
                                    </Box>
                                </Box>
                                <Box sx={{ width: "180px" }}>
                                    {row.colsLabel.map((label: any) => (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                            }}
                                            key={
                                                row.key +
                                                "-" +
                                                row.cols +
                                                "-" +
                                                label.key
                                            }
                                        >
                                            <Typography
                                                sx={{
                                                    minHeight: "50px",
                                                    height: "50px",
                                                    width: "100%",
                                                    padding: "10px 0",
                                                    border: "1px solid black",
                                                    backgroundColor:
                                                        "rgba(0, 0, 0, 0.06)",
                                                }}
                                            >
                                                {label.value}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default OwnStore