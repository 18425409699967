import { changeTextColor, changeBGColor } from "../../data/tableFunc";

// TextFieldスタイル調整

// テキストフィールドが非活性で項目色動的対応しない
export const inactiveTextSx = {
    "& .MuiFilledInput-input": {
        textAlign: "center", //入力した値を中央寄せ
        paddingTop: "17px",
        paddingBottom: "17px",
    },
    "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium":
        {
            marginTop: "0px",
        },
    //disabledがtrueのとき背景がグレー
    "& .Mui-disabled.MuiInputBase-root": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        "&: hover": {
            backgroundColor: "rgba(0, 0, 0, 0.06)",
        },
    },
    "& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled:before": {
        borderBottomStyle: "solid",
    },
};

// テキストフィールドが非活性で項目色動的対応する
export const inactiveBlueTextSx = (
    objRrBranchLog: any,
    pageName: string,
    labelName: string
) => {
    return {
        "& .MuiFilledInput-input": {
            color: changeTextColor(true, objRrBranchLog, pageName, labelName), //非活性がtrue 活性がfalse
            textAlign: "center", //入力した値を中央寄せ
            paddingTop: "17px",
            paddingBottom: "17px",
        },
        "& .MuiFilledInput-input.Mui-disabled": {
            WebkitTextFillColor: changeTextColor(
                true,
                objRrBranchLog,
                pageName,
                labelName
            ),
        },
        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium":
            {
                marginTop: "0px",
            },
        //disabledがtrueのとき背景がグレー
        "& .Mui-disabled.MuiInputBase-root": {
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            "&: hover": {
                backgroundColor: "rgba(0, 0, 0, 0.06)",
            },
        },
        "& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled:before": {
            borderBottomStyle: "solid",
        },
    };
};

//カレンダーテキストフィールド
//表紙ページ(契約予定日、開店予定日）
//出店条件ページ(実績取得期間)
export const calendarTextSx = (
    isUserType: boolean,
    objRrBranchLog: any,
    pageName: string,
    labelName: string,
    value: any
) => {
    return {
        "& .MuiFilledInput-input": {
            color: changeTextColor(
                isUserType,
                objRrBranchLog,
                pageName,
                labelName
            ),
            textAlign: "center", //入力した値を中央寄せ
            paddingTop: "17px",
            paddingBottom: "17px",
        },
        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium":
            {
                marginTop: "0px",
            },
        //readOnlyがtrueのとき背景がグレー系、色を変える
        "& .MuiInputBase-root.MuiFilledInput-root": {
            backgroundColor: changeBGColor(isUserType, value),
        },
    };
};

//ユーザー権限種別が活性の場合にテキストフィールドの色を変える
//出店条件ページの実績取得期間の項目のみに反映される​
export const activeInactiveTextSx = (isUserType: boolean)  => {
    return {
        '& .MuiFilledInput-input' : {
            color: isUserType ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0)',
            textAlign: 'center', //入力した値を中央寄せ
            paddingTop: '17px',
            paddingBottom: '17px'
        },
        '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium': {
            marginTop: '0px'
        },
        //readOnlyがtrueのとき背景がグレー系、色を変える
        '& .MuiInputBase-root.MuiFilledInput-root' : {
            backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6', 
        }
    }
};

//ロングテキストフィールド スタイル調整
//図面ページのその他 来店・進入の障害物にチェックを入れた時　下にロングテキストのみに反映される
//TODO 要動的変更修正
export const activeInactiveLongTextSx = (isUserType: boolean, rows: number) => {
    return {
        "& .MuiFilledInput-root": {
            paddingTop: "8px",
            height: rows === 1 ? "58px" : "", //ロングテキストが1行の場合は高さを58px 空白の場合は行数分の高さになる
            color: isUserType ? "rgba(0, 0, 0, 0.38)" : "rgba(0, 0, 0)",
            backgroundColor: isUserType ? "rgba(0, 0, 0, 0.06)" : "#FFFCD6", //trueのとき背景がグレー系、色を変える
        },
    };
};
