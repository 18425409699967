//寄付きページAPI取得
export const donationData = (res: any) => {
    const DataObj = {
        driving_lane_positive_frontage: res[0].driving_lane_positive_frontage, //走行車線 正 前面
        driving_lane_positive_sideway_a: res[0].driving_lane_positive_sideway_a, //走行車線 正 側道A
        driving_lane_positive_sideway_b: res[0].driving_lane_positive_sideway_b, //走行車線 正 側道B
        driving_lane_positive_backstreet:
            res[0].driving_lane_positive_backstreet, //走行車線 正 裏道
        driving_lane_opposite_frontage: res[0].driving_lane_opposite_frontage, //走行車線 反 前面
        driving_lane_opposite_sideway_a: res[0].driving_lane_opposite_sideway_a, //走行車線 反 側道A
        driving_lane_opposite_sideway_b: res[0].driving_lane_opposite_sideway_b, //走行車線 反 側道B
        driving_lane_opposite_backstreet:
            res[0].driving_lane_opposite_backstreet, //走行車線 反 裏道
        median_strip_select: res[0].median_strip_select, //中央分離帯 有無（左から1つめ）
        median_strip_select2: res[0].median_strip_select2, //中央分離帯 有無（左から2つめ）
        median_strip_select3: res[0].median_strip_select3, //中央分離帯 有無（左から3つめ）
        median_strip_select4: res[0].median_strip_select4, //中央分離帯 有無（左から4つめ）
        median_strip_height:
            res[0].median_strip_select === "0"
                ? ""
                : res[0].median_strip_height, //中央分離帯 高さ（左から1つめ）
        median_strip_height2:
            res[0].median_strip_select2 === "0"
                ? ""
                : res[0].median_strip_height2, //中央分離帯 高さ（左から2つめ）
        median_strip_height3:
            res[0].median_strip_select3 === "0"
                ? ""
                : res[0].median_strip_height3, //中央分離帯 高さ（左から3つめ）
        median_strip_height4:
            res[0].median_strip_select4 === "0"
                ? ""
                : res[0].median_strip_height4, //中央分離帯 高さ（左から4つめ）
        sidewalk_select: res[0].sidewalk_select, //歩道 有無（左から1つめ）
        sidewalk_select2: res[0].sidewalk_select2, //歩道 有無（左から2つめ）
        sidewalk_select3: res[0].sidewalk_select3, //歩道 有無（左から3つめ）
        sidewalk_select4: res[0].sidewalk_select4, //歩道 有無（左から4つめ）
        sidewalk_height:
            res[0].sidewalk_select === "0" ? "" : res[0].sidewalk_height, //歩道 高さ（左から1つめ）
        sidewalk_height2:
            res[0].sidewalk_select2 === "0" ? "" : res[0].sidewalk_height2, //歩道 高さ（左から2つめ）
        sidewalk_height3:
            res[0].sidewalk_select3 === "0" ? "" : res[0].sidewalk_height3, //歩道 高さ（左から3つめ）
        sidewalk_height4:
            res[0].sidewalk_select4 === "0" ? "" : res[0].sidewalk_height4, //歩道 高さ（左から4つめ）
        road_width_door: res[0].road_width_door, //車道幅員 ドア（左から1つめ）
        road_width_door2: res[0].road_width_door2, //車道幅員 ドア（左から2つめ）
        road_width_door3: res[0].road_width_door3, //車道幅員 ドア（左から3つめ）
        road_width_door4: res[0].road_width_door4, //車道幅員 ドア（左から4つめ）
        road_width_forward: res[0].road_width_forward, //車道幅員 先（左から1つめ）
        road_width_forward2: res[0].road_width_forward2, //車道幅員 先（左から2つめ）
        road_width_forward3: res[0].road_width_forward3, //車道幅員 先（左から3つめ）
        road_width_forward4: res[0].road_width_forward4, //車道幅員 先（左から4つめ）
        road_width_front_location: res[0].road_width_front_location, //車道幅員 手前（左から1つめ）
        road_width_front_location2: res[0].road_width_front_location2, //車道幅員 手前（左から2つめ）
        road_width_front_location3: res[0].road_width_front_location3, //車道幅員 手前（左から3つめ）
        road_width_front_location4: res[0].road_width_front_location4, //車道幅員 手前（左から4つめ）
        slope_climb: res[0].slope_climb, //坂道（長さ1ｍ当り） 登（左から1つめ）
        slope_climb2: res[0].slope_climb2, //坂道（長さ1ｍ当り） 登（左から2つめ）
        slope_climb3: res[0].slope_climb3, //坂道（長さ1ｍ当り） 登（左から3つめ）
        slope_climb4: res[0].slope_climb4, //坂道（長さ1ｍ当り） 登（左から4つめ）
        slope_descend: res[0].slope_descend, //坂道（長さ1ｍ当り） 降（左から1つめ）
        slope_descend2: res[0].slope_descend2, //坂道（長さ1ｍ当り） 降（左から2つめ）
        slope_descend3: res[0].slope_descend3, //坂道（長さ1ｍ当り） 降（左から3つめ）
        slope_descend4: res[0].slope_descend4, //坂道（長さ1ｍ当り） 降（左から4つめ）
        traffic_signal_etc_forward_select:
            res[0].traffic_signal_etc_forward_select, //信号・踏切 先 有無（左から1つめ）
        traffic_signal_etc_forward_select2:
            res[0].traffic_signal_etc_forward_select2, //信号・踏切 先 有無（左から2つめ）
        traffic_signal_etc_forward_select3:
            res[0].traffic_signal_etc_forward_select3, //信号・踏切 先 有無（左から3つめ）
        traffic_signal_etc_forward_select4:
            res[0].traffic_signal_etc_forward_select4, //信号・踏切 先 有無（左から4つめ）
        traffic_signal_etc_forward_distance:
            res[0].traffic_signal_etc_forward_select === "0"
                ? ""
                : res[0].traffic_signal_etc_forward_distance, //信号・踏切 先 距離（左から1つめ）
        traffic_signal_etc_forward_distance2:
            res[0].traffic_signal_etc_forward_select2 === "0"
                ? ""
                : res[0].traffic_signal_etc_forward_distance2, //信号・踏切 先 距離（左から2つめ）
        traffic_signal_etc_forward_distance3:
            res[0].traffic_signal_etc_forward_select3 === "0"
                ? ""
                : res[0].traffic_signal_etc_forward_distance3, //信号・踏切 先 距離（左から3つめ）
        traffic_signal_etc_forward_distance4:
            res[0].traffic_signal_etc_forward_select4 === "0"
                ? ""
                : res[0].traffic_signal_etc_forward_distance4, //信号・踏切 先 距離（左から4つめ）
        traffic_signal_etc_front_location_select:
            res[0].traffic_signal_etc_front_location_select, //信号・踏切 手前 有無（左から1つめ）
        traffic_signal_etc_front_location_select2:
            res[0].traffic_signal_etc_front_location_select2, //信号・踏切 手前 有無（左から2つめ）
        traffic_signal_etc_front_location_select3:
            res[0].traffic_signal_etc_front_location_select3, //信号・踏切 手前 有無（左から3つめ）
        traffic_signal_etc_front_location_select4:
            res[0].traffic_signal_etc_front_location_select4, //信号・踏切 手前 有無（左から4つめ）
        traffic_signal_etc_front_location_distance:
            res[0].traffic_signal_etc_front_location_select === "0"
                ? ""
                : res[0].traffic_signal_etc_front_location_distance, //信号・踏切 手前 距離（左から1つめ）
        traffic_signal_etc_front_location_distance2:
            res[0].traffic_signal_etc_front_location_select2 === "0"
                ? ""
                : res[0].traffic_signal_etc_front_location_distance2, //信号・踏切 手前 距離（左から2つめ）
        traffic_signal_etc_front_location_distance3:
            res[0].traffic_signal_etc_front_location_select3 === "0"
                ? ""
                : res[0].traffic_signal_etc_front_location_distance3, //信号・踏切 手前 距離（左から3つめ）
        traffic_signal_etc_front_location_distance4:
            res[0].traffic_signal_etc_front_location_select4 === "0"
                ? ""
                : res[0].traffic_signal_etc_front_location_distance4, //信号・踏切 手前 距離（左から4つめ）
        visibility_factor_building: res[0].visibility_factor_building, //視界性 建物（左から1つめ）
        visibility_factor_building2: res[0].visibility_factor_building2, //視界性 建物（左から2つめ）
        visibility_factor_building3: res[0].visibility_factor_building3, //視界性 建物（左から3つめ）
        visibility_factor_building4: res[0].visibility_factor_building4, //視界性 建物（左から4つめ）
        visibility_factor_sign_post: res[0].visibility_factor_sign_post, //視界性 サインポール（左から1つめ）
        visibility_factor_sign_post2: res[0].visibility_factor_sign_post2, //視界性 サインポール（左から2つめ）
        visibility_factor_sign_post3: res[0].visibility_factor_sign_post3, //視界性 サインポール（左から3つめ）
        visibility_factor_sign_post4: res[0].visibility_factor_sign_post4, //視界性 サインポール（左から4つめ）
        parking_area: res[0].parking_area, //有効駐車場面積 面積（㎡）
        parkingtsubo_area: res[0].parkingtsubo_area, //有効駐車場面積 面積（坪）
        parking_capacity: res[0].parking_capacity, //有効駐車場面積 駐車可能台数
        parking_tangent_line_frontage: res[0].parking_tangent_line_frontage, //駐車場接線 前面
        parking_tangent_line_sideway_a: res[0].parking_tangent_line_sideway_a, //駐車場接線 側道A
        parking_tangent_line_sideway_b: res[0].parking_tangent_line_sideway_b, //駐車場接線 側道B
        parking_tangent_line_backstreet: res[0].parking_tangent_line_backstreet, //駐車場接線 裏道
        no1_entrance_frontage: res[0].no1_entrance_frontage, //出入口 No.1 進入口 前面
        no1_entrance_sideway_a: res[0].no1_entrance_sideway_a, //出入口 No.1 進入口 側道A
        no1_entrance_sideway_b: res[0].no1_entrance_sideway_b, //出入口 No.1 進入口 側道B
        no1_entrance_backstreet: res[0].no1_entrance_backstreet, //出入口 No.1 進入口 裏道
        no1_depth_frontage: res[0].no1_depth_frontage, //出入口 No.1 奥行き 前面
        no1_depth_sideway_a: res[0].no1_depth_sideway_a, //出入口 No.1 奥行き 側道A
        no1_depth_sideway_b: res[0].no1_depth_sideway_b, //出入口 No.1 奥行き 側道B
        no1_depth_backstreet: res[0].no1_depth_backstreet, //出入口 No.1 奥行き 裏道
        no2_entrance_frontage: res[0].no2_entrance_frontage, //出入口 No.2 進入口 前面
        no2_entrance_sideway_a: res[0].no2_entrance_sideway_a, //出入口 No.2 進入口 側道A
        no2_entrance_sideway_b: res[0].no2_entrance_sideway_b, //出入口 No.2 進入口 側道B
        no2_entrance_backstreet: res[0].no2_entrance_backstreet, //出入口 No.2 進入口 裏道
        no2_depth_frontage: res[0].no2_depth_frontage, //出入口 No.2 奥行き 前面
        no2_depth_sideway_a: res[0].no2_depth_sideway_a, //出入口 No.2 奥行き 側道A
        no2_depth_sideway_b: res[0].no2_depth_sideway_b, //出入口 No.2 奥行き 側道B
        no2_depth_backstreet: res[0].no2_depth_backstreet, //出入口 No.2 奥行き 裏道
        complete_schedule_year: res[0].complete_schedule_year, //未開通道路・開発地の場合 完了予定 年
        complete_schedule_month: res[0].complete_schedule_month, //未開通道路・開発地の場合 完了予定 月
    };
    return DataObj;
};

//寄付きページAPI登録
export const upsertDonationParam = (
    objDonation: any,
    plan_area_id: any,
    branch: any
) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Donation/v1/regist",
        query: {
            plan_area_id: plan_area_id,
            branch: branch,
            driving_lane_positive_frontage:
                objDonation.driving_lane_positive_frontage, //走行車線 正 前面
            driving_lane_positive_sideway_a:
                objDonation.driving_lane_positive_sideway_a, //走行車線 正 側道A
            driving_lane_positive_sideway_b:
                objDonation.driving_lane_positive_sideway_b, //走行車線 正 側道B
            driving_lane_positive_backstreet:
                objDonation.driving_lane_positive_backstreet, //走行車線 正 裏道
            driving_lane_opposite_frontage:
                objDonation.driving_lane_opposite_frontage, //走行車線 反 前面
            driving_lane_opposite_sideway_a:
                objDonation.driving_lane_opposite_sideway_a, //走行車線 反 側道A
            driving_lane_opposite_sideway_b:
                objDonation.driving_lane_opposite_sideway_b, //走行車線 反 側道B
            driving_lane_opposite_backstreet:
                objDonation.driving_lane_opposite_backstreet, //走行車線 反 裏道
            median_strip_select: objDonation.median_strip_select, //中央分離帯 有無（左から1つめ）
            median_strip_select2: objDonation.median_strip_select2, //中央分離帯 有無（左から2つめ）
            median_strip_select3: objDonation.median_strip_select3, //中央分離帯 有無（左から3つめ）
            median_strip_select4: objDonation.median_strip_select4, //中央分離帯 有無（左から4つめ）
            median_strip_height:
                objDonation.median_strip_select === "0"
                    ? ""
                    : objDonation.median_strip_height, //中央分離帯 高さ（左から1つめ）
            median_strip_height2:
                objDonation.median_strip_select2 === "0"
                    ? ""
                    : objDonation.median_strip_height2, //中央分離帯 高さ（左から2つめ）
            median_strip_height3:
                objDonation.median_strip_select3 === "0"
                    ? ""
                    : objDonation.median_strip_height3, //中央分離帯 高さ（左から3つめ）
            median_strip_height4:
                objDonation.median_strip_select4 === "0"
                    ? ""
                    : objDonation.median_strip_height4, //中央分離帯 高さ（左から4つめ）
            sidewalk_select: objDonation.sidewalk_select, //歩道 有無（左から1つめ）
            sidewalk_select2: objDonation.sidewalk_select2, //歩道 有無（左から2つめ）
            sidewalk_select3: objDonation.sidewalk_select3, //歩道 有無（左から3つめ）
            sidewalk_select4: objDonation.sidewalk_select4, //歩道 有無（左から4つめ）
            sidewalk_height:
                objDonation.sidewalk_select === "0"
                    ? ""
                    : objDonation.sidewalk_height, //歩道 高さ（左から1つめ）
            sidewalk_height2:
                objDonation.sidewalk_select2 === "0"
                    ? ""
                    : objDonation.sidewalk_height2, //歩道 高さ（左から2つめ）
            sidewalk_height3:
                objDonation.sidewalk_select3 === "0"
                    ? ""
                    : objDonation.sidewalk_height3, //歩道 高さ（左から3つめ）
            sidewalk_height4:
                objDonation.sidewalk_select4 === "0"
                    ? ""
                    : objDonation.sidewalk_height4, //歩道 高さ（左から4つめ）
            road_width_door: objDonation.road_width_door, //車道幅員 ドア（左から1つめ）
            road_width_door2: objDonation.road_width_door2, //車道幅員 ドア（左から2つめ）
            road_width_door3: objDonation.road_width_door3, //車道幅員 ドア（左から3つめ）
            road_width_door4: objDonation.road_width_door4, //車道幅員 ドア（左から4つめ）
            road_width_forward: objDonation.road_width_forward, //車道幅員 先（左から1つめ）
            road_width_forward2: objDonation.road_width_forward2, //車道幅員 先（左から2つめ）
            road_width_forward3: objDonation.road_width_forward3, //車道幅員 先（左から3つめ）
            road_width_forward4: objDonation.road_width_forward4, //車道幅員 先（左から4つめ）
            road_width_front_location: objDonation.road_width_front_location, //車道幅員 手前（左から1つめ）
            road_width_front_location2: objDonation.road_width_front_location2, //車道幅員 手前（左から2つめ）
            road_width_front_location3: objDonation.road_width_front_location3, //車道幅員 手前（左から3つめ）
            road_width_front_location4: objDonation.road_width_front_location4, //車道幅員 手前（左から4つめ）
            slope_climb: objDonation.slope_climb, //坂道（長さ1ｍ当り） 登（左から1つめ）
            slope_climb2: objDonation.slope_climb2, //坂道（長さ1ｍ当り） 登（左から2つめ）
            slope_climb3: objDonation.slope_climb3, //坂道（長さ1ｍ当り） 登（左から3つめ）
            slope_climb4: objDonation.slope_climb4, //坂道（長さ1ｍ当り） 登（左から4つめ）
            slope_descend: objDonation.slope_descend, //坂道（長さ1ｍ当り） 降（左から4つめ）
            slope_descend2: objDonation.slope_descend2, //坂道（長さ1ｍ当り） 降（左から2つめ）
            slope_descend3: objDonation.slope_descend3, //坂道（長さ1ｍ当り） 降（左から3つめ）
            slope_descend4: objDonation.slope_descend4, //坂道（長さ1ｍ当り） 降（左から4つめ）
            traffic_signal_etc_forward_select:
                objDonation.traffic_signal_etc_forward_select, //信号・踏切 先 有無（左から1つめ）
            traffic_signal_etc_forward_select2:
                objDonation.traffic_signal_etc_forward_select2, //信号・踏切 先 有無（左から2つめ）
            traffic_signal_etc_forward_select3:
                objDonation.traffic_signal_etc_forward_select3, //信号・踏切 先 有無（左から3つめ）
            traffic_signal_etc_forward_select4:
                objDonation.traffic_signal_etc_forward_select4, //信号・踏切 先 有無（左から4つめ）
            traffic_signal_etc_forward_distance:
                objDonation.traffic_signal_etc_forward_select === "0"
                    ? ""
                    : objDonation.traffic_signal_etc_forward_distance, //信号・踏切 先 距離（左から1つめ）
            traffic_signal_etc_forward_distance2:
                objDonation.traffic_signal_etc_forward_select2 === "0"
                    ? ""
                    : objDonation.traffic_signal_etc_forward_distance2, //信号・踏切 先 距離（左から2つめ）
            traffic_signal_etc_forward_distance3:
                objDonation.traffic_signal_etc_forward_select3 === "0"
                    ? ""
                    : objDonation.traffic_signal_etc_forward_distance3, //信号・踏切 先 距離（左から3つめ）
            traffic_signal_etc_forward_distance4:
                objDonation.traffic_signal_etc_forward_select4 === "0"
                    ? ""
                    : objDonation.traffic_signal_etc_forward_distance4, //信号・踏切 先 距離（左から4つめ）
            traffic_signal_etc_front_location_select:
                objDonation.traffic_signal_etc_front_location_select, //信号・踏切 手前 有無（左から1つめ）
            traffic_signal_etc_front_location_select2:
                objDonation.traffic_signal_etc_front_location_select2, //信号・踏切 手前 有無（左から2つめ）
            traffic_signal_etc_front_location_select3:
                objDonation.traffic_signal_etc_front_location_select3, //信号・踏切 手前 有無（左から3つめ）
            traffic_signal_etc_front_location_select4:
                objDonation.traffic_signal_etc_front_location_select4, //信号・踏切 手前 有無（左から4つめ）
            traffic_signal_etc_front_location_distance:
                objDonation.traffic_signal_etc_front_location_select === "0"
                    ? ""
                    : objDonation.traffic_signal_etc_front_location_distance, //信号・踏切 手前 距離（左から1つめ）
            traffic_signal_etc_front_location_distance2:
                objDonation.traffic_signal_etc_front_location_select2 === "0"
                    ? ""
                    : objDonation.traffic_signal_etc_front_location_distance2, //信号・踏切 手前 距離（左から2つめ）
            traffic_signal_etc_front_location_distance3:
                objDonation.traffic_signal_etc_front_location_select3 === "0"
                    ? ""
                    : objDonation.traffic_signal_etc_front_location_distance3, //信号・踏切 手前 距離（左から3つめ）
            traffic_signal_etc_front_location_distance4:
                objDonation.traffic_signal_etc_front_location_select4 === "0"
                    ? ""
                    : objDonation.traffic_signal_etc_front_location_distance4, //信号・踏切 手前 距離（左から4つめ）
            visibility_factor_building: objDonation.visibility_factor_building, //視界性 建物（左から1つめ）
            visibility_factor_building2:
                objDonation.visibility_factor_building2, //視界性 建物（左から2つめ）
            visibility_factor_building3:
                objDonation.visibility_factor_building3, //視界性 建物（左から3つめ）
            visibility_factor_building4:
                objDonation.visibility_factor_building4, //視界性 建物（左から4つめ）
            visibility_factor_sign_post:
                objDonation.visibility_factor_sign_post, //視界性 サインポール（左から1つめ）
            visibility_factor_sign_post2:
                objDonation.visibility_factor_sign_post2, //視界性 サインポール（左から2つめ）
            visibility_factor_sign_post3:
                objDonation.visibility_factor_sign_post3, //視界性 サインポール（左から3つめ）
            visibility_factor_sign_post4:
                objDonation.visibility_factor_sign_post4, //視界性 サインポール（左から4つめ）
            parking_area: objDonation.parking_area, //有効駐車場面積 面積（㎡）
            parkingtsubo_area: tsuboFunc(objDonation.parking_area), //有効駐車場面積 面積（坪）
            parking_capacity: objDonation.parking_capacity, //有効駐車場面積 駐車可能台数
            parking_tangent_line_frontage:
                objDonation.parking_tangent_line_frontage, //駐車場接線 前面
            parking_tangent_line_sideway_a:
                objDonation.parking_tangent_line_sideway_a, //駐車場接線 側道A
            parking_tangent_line_sideway_b:
                objDonation.parking_tangent_line_sideway_b, //駐車場接線 側道B
            parking_tangent_line_backstreet:
                objDonation.parking_tangent_line_backstreet, //駐車場接線 裏道
            no1_entrance_frontage: objDonation.no1_entrance_frontage, //出入口 No.1 進入口 前面
            no1_entrance_sideway_a: objDonation.no1_entrance_sideway_a, //出入口 No.1 進入口 側道A
            no1_entrance_sideway_b: objDonation.no1_entrance_sideway_b, //出入口 No.1 進入口 側道B
            no1_entrance_backstreet: objDonation.no1_entrance_backstreet, //出入口 No.1 進入口 裏道
            no1_depth_frontage: objDonation.no1_depth_frontage, //出入口 No.1 奥行き 前面
            no1_depth_sideway_a: objDonation.no1_depth_sideway_a, //出入口 No.1 奥行き 側道A
            no1_depth_sideway_b: objDonation.no1_depth_sideway_b, //出入口 No.1 奥行き 側道B
            no1_depth_backstreet: objDonation.no1_depth_backstreet, //出入口 No.1 奥行き 裏道
            no2_entrance_frontage: objDonation.no2_entrance_frontage, //出入口 No.2 進入口 前面
            no2_entrance_sideway_a: objDonation.no2_entrance_sideway_a, //出入口 No.2 進入口 側道A
            no2_entrance_sideway_b: objDonation.no2_entrance_sideway_b, //出入口 No.2 進入口 側道B
            no2_entrance_backstreet: objDonation.no2_entrance_backstreet, //出入口 No.2 進入口 裏道
            no2_depth_frontage: objDonation.no2_depth_frontage, //出入口 No.2 奥行き 前面
            no2_depth_sideway_a: objDonation.no2_depth_sideway_a, //出入口 No.2 奥行き 側道A
            no2_depth_sideway_b: objDonation.no2_depth_sideway_b, //出入口 No.2 奥行き 側道B
            no2_depth_backstreet: objDonation.no2_depth_backstreet, //出入口 No.2 奥行き 裏道
            complete_schedule_year: objDonation.complete_schedule_year, //未開通道路・開発地の場合 完了予定 年
            complete_schedule_month: objDonation.complete_schedule_month, //未開通道路・開発地の場合 完了予定 月
        },
    };
    return params;
};

//㎡から坪へ変換
export const tsuboFunc = (total: string) => {
    return total === "" ? "" : String(Number(total) * 0.3025);
};
