import dayjs from 'dayjs';
export const coverData = (res:any) => {
    const DataObj = {
        create_date:res[0].create_date,                                          //作成日 graphsqlの呼び出しのときのみ
        reception_no:res[0].reception_no,                                        //受付NO
        store_no:res[0].store_no,                                                //店NO
        survey_name:res[0].survey_name,                                          //名称
        address:res[0].address,                                                 //所在地
        // address_detail:res[0].address_detail,                                 //所在地詳細 検討中
        contract_due_date:dayjs(res[0].contract_due_date).format('YYYY-MM-DD') === 'Invalid Date' ? '' : dayjs(res[0].contract_due_date).format('YYYY-MM-DD'),   //契約予定日
        open_due_date:dayjs(res[0].open_due_date).format('YYYY-MM-DD') === 'Invalid Date' ? '' : dayjs(res[0].open_due_date).format('YYYY-MM-DD'),               //開店予定日 
        prefectures_code:res[0].prefectures_code,                                //市区町村コード
        new_area_no:res[0].new_area_no,                                           //新エリアNO
        branch_no:res[0].branch_no,                                              //枝NO
        existing_store_design:res[0].existing_store_design,                       //既存店図面の面積・進入口が現状と差異ないことを確認後、チェック記入
        s_and_b_case:res[0].s_and_b_case,                                          //S&Bの場合 車・人共通比根拠シート
        conflict_ruins_case:res[0].conflict_ruins_case,                           //競合跡地の場合 競合日販推定表
        target_contract_not_opened:res[0].target_contract_not_opened,              //調査対象範囲内に契約済未開店のセブン-イレブン
        survey_creator:res[0].survey_creator,                                    //調査書作成者
        confirmer:res[0].confirmer                                              //確認者
    };
    return DataObj;
};

//表紙ページAPI登録
export const upsertCoverParam = (objCover: any, plan_area_id:any, branch:any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Cover/v1/regist",
        query: {
            plan_area_id:plan_area_id,
            branch:branch,
            reception_no:objCover.reception_no,
            store_no:objCover.store_no,
            survey_name:objCover.survey_name,
            address:objCover.address,
            contract_due_date:objCover.contract_due_date  ==='Invalid Date' ? '' : objCover.contract_due_date,
            open_due_date:objCover.open_due_date === 'Invalid Date' ? '' : objCover.open_due_date,
            prefectures_code:objCover.prefectures_code,
            new_area_no:objCover.new_area_no,
            branch_no:objCover.branch_no,
            existing_store_design:objCover.existing_store_design,
            s_and_b_case:objCover.s_and_b_case,
            conflict_ruins_case:objCover.conflict_ruins_case,
            target_contract_not_opened:objCover.target_contract_not_opened,
            survey_creator:objCover.survey_creator,
            confirmer:objCover.confirmer
        }
    }
    return params
}