import dayjs from "dayjs";
import { GridColDef } from "@mui/x-data-grid";

//テーブル項目
export const columns: GridColDef[] = [
    {
        field: "conversion_create_date",
        headerName: "依頼受領日時",
        width: 160,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
    },
    {
        field: "task_create_date",
        headerName: "タスク変更時間",
        width: 160,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
    },
    {
        field: "elapsedTime",
        headerName: "経過時間",
        width: 140,
        headerAlign: "center",
        disableColumnMenu: true,
        sortComparator: (v1, v2) => {
            const elapsed1 = parseElapsedTimeToMinutes(v1);
            const elapsed2 = parseElapsedTimeToMinutes(v2);
            return elapsed1 - elapsed2;
        },
    },
    {
        field: "type",
        headerName: "調査書種別",
        width: 110,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "plan_area_department_name",
        headerName: "部",
        width: 140,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "plan_area_district_name",
        headerName: "地区",
        width: 100,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "plan_area_staff_name",
        headerName: "RFC",
        width: 120,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "plan_area_id",
        headerName: "候補地No",
        width: 100,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "plan_area_name",
        headerName: "候補地名",
        width: 220,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "assembly",
        headerName: "組立",
        width: 70,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "pattern",
        headerName: "パターン",
        width: 80,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "branch",
        headerName: "枝",
        width: 60,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "status_id",
        headerName: "ステータス",
        width: 140,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "pic_type",
        headerName: "担当",
        width: 90,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "pic_name",
        headerName: "担当者名",
        width: 120,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "task_name",
        headerName: "タスク",
        width: 100,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "daily_sales_year_1",
        headerName: "初年度予測",
        width: 100,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "daily_sales_year_2",
        headerName: "2年度予測",
        width: 100,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "daily_sales_year_3",
        headerName: "3年度予測",
        width: 100,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "daily_sales_year_4",
        headerName: "4年度予測",
        width: 100,
        headerAlign: "center",
        disableColumnMenu: true,
    },
    {
        field: "daily_sales_year_5",
        headerName: "5年度予測",
        width: 100,
        headerAlign: "center",
        disableColumnMenu: true,
    },
];

export interface portalTypeList {
    id: string;
    conversion_create_date: string;
    task_create_date: string;
    elapsedTime: string;
    elapsedFlag: string;
    type: string;
    department: string;
    district: string;
    rfc: string;
    plan_area_id: string;
    candidateSiteName: string;
    assembly: string;
    pattern: string;
    branch: string;
    status_id: string;
    pic_type: string;
    pic_name: string;
    task_id: string;
    task_name: string;
    reception_no: string;
    plan_area_name: string;
    plan_area_staff_number: string;
    plan_area_staff_name: string;
    plan_area_district_code: string;
    plan_area_district_name: string;
    plan_area_department_code: string;
    plan_area_department_name: string;
    location_sales_forecast: string;
    reception_book_list: string;
}

export interface dataTypeList {
    id: string;
    conversion_create_date: string;
    task_create_date: string;
    elapsedTime: string;
    elapsedFlag: string;
    type: string;
    department: string;
    district: string;
    rfc: string;
    plan_area_id: string;
    candidateSiteName: string;
    assembly: string;
    pattern: string;
    branch: string;
    status_id: string;
    pic_type: string;
    pic_name: string;
    task_id: string;
    task_name: string;
    reception_no: string;
    plan_area_name: string;
    plan_area_staff_number: string;
    plan_area_staff_name: string;
    plan_area_district_code: string;
    plan_area_district_name: string;
    plan_area_department_code: string;
    plan_area_department_name: string;
    location_sales_forecast: string;
    reception_book_list: string;
    daily_sales_year_1: string;
    daily_sales_year_2: string;
    daily_sales_year_3: string;
    daily_sales_year_4: string;
    daily_sales_year_5: string;
}

//テーブル表示用オブジェクト生成
export const findLatestBranch = (dataObj: dataTypeList[]) => {
    //グループ化
    const groupeByKeys: Record<string, dataTypeList[]> = {};

    //候補地No毎に切り分け
    dataObj.forEach((obj) => {
        const key = obj.plan_area_id;
        if (!groupeByKeys[key]) {
            groupeByKeys[key] = [];
        }
        groupeByKeys[key].push(obj);
    });

    const resultObj: dataTypeList[] = [];
    //一番新しい枝のレコードのみ特定して返却
    for (const key in groupeByKeys) {
        if (groupeByKeys.hasOwnProperty(key)) {
            const values = groupeByKeys[key];
            const latestBranch = values.sort((a, b) => Number(b.branch.charCodeAt(0)) - Number(a.branch.charCodeAt(0)))[0];
            let keyBranch = "1";
            if (Number(latestBranch.branch) > 1) {
                keyBranch = (Number(latestBranch.branch) - 1).toString();
            }
            values.map((row) => {
                if (row.branch === keyBranch) {
                    const locationSFObj = JSON.parse(row.location_sales_forecast);
                    locationSFObj.map((jsonObj: any) => {
                        if (jsonObj) {
                            if (Number(latestBranch.branch) > 1) {
                                jsonObj.map((data: any) => {
                                    if (data.branch === keyBranch && data.temp_flag === "0") {
                                        // 前枝（n-1、枝2の時は枝1の、枝5の時は枝4）のlocation_base．daily_sales_year_1～5を表示
                                        latestBranch.daily_sales_year_1 = data.daily_sales_year_1;
                                        latestBranch.daily_sales_year_2 = data.daily_sales_year_2;
                                        latestBranch.daily_sales_year_3 = data.daily_sales_year_3;
                                        latestBranch.daily_sales_year_4 = data.daily_sales_year_4;
                                        latestBranch.daily_sales_year_5 = data.daily_sales_year_5;
                                    }
                                });
                            } else {
                                if (latestBranch.task_id === "C008") {
                                    // 枝1の場合、C008の時だけ表示する
                                    jsonObj.map((data: any) => {
                                        if (data.branch === keyBranch && data.temp_flag === "1") {
                                            latestBranch.daily_sales_year_1 = data.daily_sales_year_1 ? data.daily_sales_year_1 + "（仮）" : "";
                                            latestBranch.daily_sales_year_2 = data.daily_sales_year_2 ? data.daily_sales_year_2 + "（仮）" : "";
                                            latestBranch.daily_sales_year_3 = data.daily_sales_year_3 ? data.daily_sales_year_3 + "（仮）" : "";
                                            latestBranch.daily_sales_year_4 = data.daily_sales_year_4 ? data.daily_sales_year_4 + "（仮）" : "";
                                            latestBranch.daily_sales_year_5 = data.daily_sales_year_5 ? data.daily_sales_year_5 + "（仮）" : "";
                                        }
                                    });
                                } else {
                                    latestBranch.daily_sales_year_1 = "";
                                    latestBranch.daily_sales_year_2 = "";
                                    latestBranch.daily_sales_year_3 = "";
                                    latestBranch.daily_sales_year_4 = "";
                                    latestBranch.daily_sales_year_5 = "";
                                }
                            }
                        }
                    });
                }
            });
            resultObj.push(latestBranch);
        }
    }
    resultObj.sort((a: any, b: any) => {
        return a.id - b.id;
    });
    return resultObj;
};

export //テーブル表示用オブジェクト 表示用データへ変換
const dataOptimization = (res: dataTypeList[], picTypeList: any[]) => {
    //担当IDから担当へ変更
    const setPicTypeCodedDisplay = (pic_type: string) => {
        let pic_type_code_display = pic_type;
        picTypeList.map((row: any) => {
            if (row.value === pic_type) {
                pic_type_code_display = row.label;
            }
        });
        return pic_type_code_display;
    };
    //コード値からパターン名へ変換
    const setPatternCodeDisplay = (pattern: string) => {
        let pattern_code_display = pattern; //戻り値
        const codeMasterObj = localStorage.getItem("codeMaster");
        if (codeMasterObj !== null) {
            const jsonCodeMasterObj = JSON.parse(codeMasterObj);
            const patternList = jsonCodeMasterObj.pattern; //コードマスタからパターンのリスト取得
            patternList.map((row: any) => {
                if (row.code_value === pattern) {
                    pattern_code_display = row.code_display;
                }
            });
        }
        return pattern_code_display;
    };
    const dataList: dataTypeList[] = [];
    res.map((data) => {
        const dataObj = {
            id: data.id,
            conversion_create_date: data.conversion_create_date,
            task_create_date: data.task_create_date,
            elapsedTime: data.elapsedTime,
            elapsedFlag: data.elapsedFlag,
            type: data.type,
            department: data.department,
            district: data.district,
            rfc: data.rfc,
            plan_area_id: data.plan_area_id,
            candidateSiteName: data.candidateSiteName,
            assembly: data.assembly,
            pattern: setPatternCodeDisplay(data.pattern),
            branch: data.branch,
            status_id: data.status_id,
            pic_type: setPicTypeCodedDisplay(data.pic_type),
            pic_name: data.pic_name,
            task_id: data.task_id,
            task_name: data.task_name,
            reception_no: data.reception_no,
            plan_area_name: data.plan_area_name,
            plan_area_staff_number: data.plan_area_staff_number,
            plan_area_staff_name: data.plan_area_staff_name,
            plan_area_district_code: data.plan_area_district_code,
            plan_area_district_name: data.plan_area_district_name,
            plan_area_department_code: data.plan_area_department_code,
            plan_area_department_name: data.plan_area_department_name,
            location_sales_forecast: data.location_sales_forecast,
            reception_book_list: data.reception_book_list,
            daily_sales_year_1: data.daily_sales_year_1,
            daily_sales_year_2: data.daily_sales_year_2,
            daily_sales_year_3: data.daily_sales_year_3,
            daily_sales_year_4: data.daily_sales_year_4,
            daily_sales_year_5: data.daily_sales_year_5,
        };
        dataList.push(dataObj);
    });
    return dataList;
};

//経過時間の日、時間、分を分に変換する関数 ソート時に使用
export const parseElapsedTimeToMinutes = (elapsedTime: string) => {
    let days = 0,
        hours = 0,
        minutes = 0;
    const dayMatch = elapsedTime.match(/(\d+)日/);
    if (dayMatch) days = parseInt(dayMatch[1], 10);
    const hourMatch = elapsedTime.match(/(\d+)時間/);
    if (hourMatch) hours = parseInt(hourMatch[1], 10);
    const minuteMatch = elapsedTime.match(/(\d+)分/);
    if (minuteMatch) minutes = parseInt(minuteMatch[1], 10);
    return days * 24 * 60 + hours * 60 + minutes;
};
