import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import { LOCAL_CONSTANT } from "../../Const";
import {
    Box,
    Typography,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
    FormControl,
} from "@mui/material";
import {
    checkListDisabled,
    hasConflictSubject,
    closingTime,
    displayClosingTime,
    conflictBusinessType,
    conflict_business_type_trans_business_type,
    handleChangeValue,
    checkHidenColumn,
} from "./AddConflictFunction";
import {
    SM_DRUG_MASTER_DATA,
} from "../../ResearchReport/Conflict";
import {
    changeConflictTextColor,
    changeBGColor
} from "../../../data/tableFunc";
import { setSelecterDisplay } from "../../Common";


const hours = [
    { code_value: "00", code_display: "0" },
    { code_value: "01", code_display: "1" },
    { code_value: "02", code_display: "2" },
    { code_value: "03", code_display: "3" },
    { code_value: "04", code_display: "4" },
    { code_value: "05", code_display: "5" },
    { code_value: "06", code_display: "6" },
    { code_value: "07", code_display: "7" },
    { code_value: "08", code_display: "8" },
    { code_value: "09", code_display: "9" },
    { code_value: "10", code_display: "10" },
    { code_value: "11", code_display: "11" },
    { code_value: "12", code_display: "12" },
    { code_value: "13", code_display: "13" },
    { code_value: "14", code_display: "14" },
    { code_value: "15", code_display: "15" },
    { code_value: "16", code_display: "16" },
    { code_value: "17", code_display: "17" },
    { code_value: "18", code_display: "18" },
    { code_value: "19", code_display: "19" },
    { code_value: "20", code_display: "20" },
    { code_value: "21", code_display: "21" },
    { code_value: "22", code_display: "22" },
    { code_value: "23", code_display: "23" },
];
const minutes = [
    { code_value: "00", code_display: "0" },
    { code_value: "15", code_display: "15" },
    { code_value: "30", code_display: "30" },
    { code_value: "45", code_display: "45" },
];

export interface conflictListDetailsProps {
    props: {
        detailList: any;
        conflictData: any;
        type: string;
        codeMaster: any;
        editConflict: boolean;
        editDisabled: boolean;
        draw: boolean;
        setDraw: any;
        targetRadius: any;
        ownStore: any;
    };
}

export const ConflictListDetails: React.FC<conflictListDetailsProps> = ({ props }) => {

    // 表示色変更用BQログデータ
    const objRrBranchLog = useAppSelector((state) => state.longPage.rrBranchLog);
    const objDonation = useAppSelector((state) => state.donation.obj);

    // 競合店 項目動的対応 非活性
    const typographyStyle = (isUserType: boolean, objRrBranchLog: any, labelName: string, conflictData: any) => {
        return {
            minHeight: "50px",
            height: "50px",
            minWidth: "240px",
            width: "240px",
            padding: "10px 0",
            border: "1px solid black",
            color: changeConflictTextColor(
                objRrBranchLog,
                LOCAL_CONSTANT.NAVI.CONFLICT,
                labelName,
                conflictData.conflict_type,
                conflictData.conflict_sequence
            ),
            backgroundColor: changeBGColor(isUserType, conflictData[labelName]),
        }
    };

    // 競合自店 項目動的対応 活性
    const textFiledStyle = (objRrBranchLog: any, labelName: string, conflictData: any, disabled: string) => {
        const isUserType = checkEditDisabled(conflictData, disabled);
        return {
            minHeight: "50px",
            height: "50px",
            minWidth: "240px",
            width: "240px",
            border: "1px solid black",
            color: changeConflictTextColor(
                objRrBranchLog,
                LOCAL_CONSTANT.NAVI.CONFLICT,
                labelName,
                conflictData.conflict_type,
                conflictData.conflict_sequence
            ),
            backgroundColor: changeBGColor(isUserType, conflictData[labelName]),
            "& input": {
                padding: "14px 0",
            }
        }
    };

    // 競合店 プルダウン 項目動的対応　活性
    const selectStyle = (objRrBranchLog: any, labelName: string, conflictData: any, disabled: string) => {
        const isUserType = checkEditDisabled(conflictData, disabled);
        return {
            minHeight: "50px",
            height: "50px",
            minWidth: "240px",
            width: "240px",
            border: "1px solid black",
            color: changeConflictTextColor(
                objRrBranchLog,
                LOCAL_CONSTANT.NAVI.CONFLICT,
                labelName,
                conflictData.conflict_type,
                conflictData.conflict_sequence
            ),
            backgroundColor: changeBGColor(isUserType, conflictData[labelName])
        }
    };

    // 競合店 プルダウン 項目動的対応 閉店時間
    const selectClosingTimeStyle = (objRrBranchLog: any, labelName: string, conflictData: any, disabled: string) => {
        const isUserType = checkEditDisabled(conflictData, disabled);
        return {
            width: "50%",
            border: "1px solid black",
            color: changeConflictTextColor(
                objRrBranchLog,
                LOCAL_CONSTANT.NAVI.CONFLICT,
                labelName,
                conflictData.conflict_type,
                conflictData.conflict_sequence
            ),
            backgroundColor: changeBGColor(isUserType, conflictData[labelName])
        }
    };

    const checkEditDisabled = (data: any, columnDisabled: string) => {
        let disabled = false;
        if (props.editDisabled) {
            disabled = true;
        } else {
            if (columnDisabled !== "") {
                disabled = checkListDisabled(data, columnDisabled);
            }
        }
        return disabled;
    };


    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        data: any,
        labelName: string
    ) => {
        data = handleChangeValue(e, data, labelName)
        data["conflict_business_type"] = conflictBusinessType(
            data,
            data["business_type"]
        );
        props.setDraw(!props.draw);
    };

    const handleSelectedChange = (
        e: SelectChangeEvent<string>,
        data: any,
        labelName: string
    ) => {
        data[labelName] = e.target.value;
        if (labelName === "location") {
            const side_road_type = ["0", "1", "2", "3"];
            data["side_road_type"] = side_road_type[data[labelName]];
        }
        props.setDraw(!props.draw);
    };

    const handleSelectedClosingTime = (
        e: SelectChangeEvent<string>,
        key: string,
        data: any,
        labelName: string
    ) => {
        let hours = data[labelName].slice(0, 2);
        let minutes = data[labelName].slice(2, 4);
        if (key === "hours") {
            data[labelName] = e.target.value + minutes;
        } else {
            data[labelName] = hours + e.target.value;
        }
        props.setDraw(!props.draw);
    };


    const displayLocationConflictType = (data: any) => {
        if (props.type === SM_DRUG_MASTER_DATA) {
            data["business_type"] = conflict_business_type_trans_business_type(
                data["conflict_business_type"]
            );
        }

        data.location_conflict_type = hasConflictSubject(
            data,
            data,
            props.targetRadius,
            props.type,
            props.ownStore,
            "list",
            objDonation,
        );

        return setSelecterDisplay(
            props.codeMaster["location_conflict_type"],
            data.location_conflict_type
        );
    };

    const selectClosingTime = (date: any, key: string) => {
        const result = closingTime(date);
        if (key === "hours") {
            return result.hours;
        }
        return result.minutes;
    };


    return (
        <>
            {props.detailList.map((column: any) => (
                <Box
                    sx={{
                        display: checkHidenColumn(
                            "columnList",
                            column.key,
                            props.type
                        )
                            ? "none"
                            : "flex",
                    }}
                    key={column.key}
                >
                    {props.conflictData.map(
                        (conflict: any, conflictIndex: any) => (
                            <Box
                                key={
                                    conflictIndex +
                                    "-" +
                                    column.key +
                                    "-" +
                                    column.keyName +
                                    "-" +
                                    conflict.conflict_sequence
                                }
                            >
                                {/** 競合店データを表示 */}
                                {column.type ===
                                    "codemaster-value" && (
                                        <Typography
                                            sx={typographyStyle(
                                                true,
                                                objRrBranchLog,
                                                column.keyName,
                                                conflict
                                            )}
                                        >
                                            {setSelecterDisplay(
                                                props.codeMaster[column.keyName],
                                                conflict[column.keyName]
                                            )}
                                        </Typography>
                                    )}
                                {column.type === "label" && (
                                    <Typography
                                        sx={{
                                            minHeight: "50px",
                                            height: "50px",
                                            minWidth: "240px",
                                            width: "240px",
                                            padding: "10px 0",
                                            border: "1px solid black",
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.06)",
                                        }}
                                    >
                                        {
                                            conflict[
                                            column.keyName
                                            ]
                                        }
                                    </Typography>
                                )}
                                {props.editConflict === false &&
                                    column.type === "round" && (
                                        <Typography
                                            sx={typographyStyle(
                                                true,
                                                objRrBranchLog,
                                                column.keyName,
                                                conflict
                                            )}
                                        >
                                            {conflict[
                                                column.keyName
                                            ] === ""
                                                ? ""
                                                : Math.round(
                                                    conflict[
                                                    column
                                                        .keyName
                                                    ] * 100
                                                ) / 100}
                                        </Typography>
                                    )}
                                {props.editConflict === true &&
                                    column.type === "round" && (
                                        <TextField
                                            disabled={checkEditDisabled(
                                                conflict,
                                                column.disabled
                                            )}
                                            sx={textFiledStyle(
                                                objRrBranchLog,
                                                column.keyName,
                                                conflict,
                                                column.disabled
                                            )}
                                            onChange={(e) => {
                                                handleChange(
                                                    e,
                                                    conflict,
                                                    column.keyName
                                                );
                                            }}
                                            value={
                                                conflict[
                                                column
                                                    .keyName
                                                ]
                                            }
                                        ></TextField>
                                    )}
                                {column.type ===
                                    "location_conflict_type" && (
                                        <Typography
                                            sx={typographyStyle(
                                                true,
                                                objRrBranchLog,
                                                column.keyName,
                                                conflict
                                            )}
                                        >
                                            {
                                                props.editConflict === false &&
                                                setSelecterDisplay(
                                                    props.codeMaster["location_conflict_type"],
                                                    conflict.location_conflict_type
                                                )
                                            }
                                            {
                                                props.editConflict === true &&
                                                displayLocationConflictType(conflict)
                                            }
                                        </Typography>
                                    )}
                                {props.editConflict === false &&
                                    column.type === "text" && (
                                        <Typography
                                            sx={typographyStyle(
                                                true,
                                                objRrBranchLog,
                                                column.keyName,
                                                conflict
                                            )}
                                        >
                                            {
                                                conflict[
                                                column
                                                    .keyName
                                                ]
                                            }
                                        </Typography>
                                    )}
                                {props.editConflict === true &&
                                    column.type === "text" && (
                                        <TextField
                                            disabled={checkEditDisabled(
                                                conflict,
                                                column.disabled
                                            )}
                                            sx={textFiledStyle(
                                                objRrBranchLog,
                                                column.keyName,
                                                conflict,
                                                column.disabled
                                            )}
                                            onChange={(e) => {
                                                handleChange(
                                                    e,
                                                    conflict,
                                                    column.keyName
                                                );
                                            }}
                                            value={
                                                conflict[
                                                column
                                                    .keyName
                                                ]
                                            }
                                        ></TextField>
                                    )}
                                {props.editConflict === false &&
                                    column.type ===
                                    "time-label" && (
                                        <>
                                            {conflict[
                                                "business_hours"
                                            ] === "" && (
                                                    <Typography
                                                        sx={{
                                                            minHeight:
                                                                "50px",
                                                            height: "50px",
                                                            minWidth:
                                                                "240px",
                                                            width: "240px",
                                                            padding:
                                                                "10px 0",
                                                            border: "1px solid black",
                                                            backgroundColor:
                                                                "rgba(0, 0, 0, 0.5)",
                                                        }}
                                                    ></Typography>
                                                )}
                                            {conflict[
                                                "business_hours"
                                            ] === "0" && (
                                                    <Typography
                                                        sx={typographyStyle(
                                                            true,
                                                            objRrBranchLog,
                                                            column.keyName,
                                                            conflict
                                                        )}
                                                    >
                                                        {displayClosingTime(
                                                            conflict[
                                                            column
                                                                .keyName
                                                            ],
                                                            true
                                                        )}
                                                    </Typography>
                                                )}
                                            {conflict[
                                                "business_hours"
                                            ] === "1" && (
                                                    <Typography
                                                        sx={typographyStyle(
                                                            true,
                                                            objRrBranchLog,
                                                            column.keyName,
                                                            conflict
                                                        )}
                                                    >
                                                        {displayClosingTime(
                                                            conflict[
                                                            column
                                                                .keyName
                                                            ],
                                                            true
                                                        )}
                                                    </Typography>
                                                )}
                                        </>
                                    )}
                                {props.editConflict === true &&
                                    column.type ===
                                    "time-label" && (
                                        <FormControl
                                            disabled={checkEditDisabled(
                                                conflict,
                                                column.disabled
                                            )}
                                        >
                                            <Box
                                                sx={{
                                                    minHeight:
                                                        "50px",
                                                    height: "50px",
                                                    minWidth:
                                                        "240px",
                                                    width: "240px",
                                                }}
                                            >
                                                <Select
                                                    sx={selectClosingTimeStyle(
                                                        objRrBranchLog,
                                                        column.keyName,
                                                        conflict,
                                                        column.disabled
                                                    )}
                                                    disabled={
                                                        conflict[
                                                            "business_hours"
                                                        ] ===
                                                            "1"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(
                                                        e
                                                    ) =>
                                                        handleSelectedClosingTime(
                                                            e,
                                                            "hours",
                                                            conflict,
                                                            column.keyName
                                                        )
                                                    }
                                                    value={selectClosingTime(
                                                        conflict[column.keyName],
                                                        "hours"
                                                    )}
                                                >
                                                    {hours.map(
                                                        (
                                                            row: any
                                                        ) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                                <Select
                                                    sx={selectClosingTimeStyle(
                                                        objRrBranchLog,
                                                        column.keyName,
                                                        conflict,
                                                        column.disabled
                                                    )}
                                                    disabled={
                                                        conflict[
                                                            "business_hours"
                                                        ] ===
                                                            "1"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(
                                                        e
                                                    ) =>
                                                        handleSelectedClosingTime(
                                                            e,
                                                            "minutes",
                                                            conflict,
                                                            column.keyName
                                                        )
                                                    }
                                                    value={selectClosingTime(
                                                        conflict[
                                                        column
                                                            .keyName
                                                        ],
                                                        "minutes"
                                                    )}
                                                >
                                                    {minutes.map((row: any) => (
                                                        <MenuItem
                                                            key={row.code_value}
                                                            value={row.code_value}
                                                        >
                                                            {row.code_display}
                                                        </MenuItem>
                                                    )
                                                    )}
                                                </Select>
                                            </Box>
                                        </FormControl>
                                    )}
                                {props.editConflict === false &&
                                    column.type ===
                                    "select" && (
                                        <Typography
                                            sx={typographyStyle(
                                                true,
                                                objRrBranchLog,
                                                column.keyName,
                                                conflict
                                            )}
                                        >
                                            {setSelecterDisplay(
                                                props.codeMaster[column.SELECT_KEY],
                                                conflict[column.keyName]
                                            )}
                                        </Typography>
                                    )}
                                {props.editConflict === true &&
                                    column.type ===
                                    "select" && (
                                        <FormControl
                                            disabled={checkEditDisabled(
                                                conflict,
                                                column.disabled
                                            )}
                                        >
                                            <Select
                                                onChange={(e) =>
                                                    handleSelectedChange(
                                                        e,
                                                        conflict,
                                                        column.keyName
                                                    )
                                                }
                                                sx={selectStyle(
                                                    objRrBranchLog,
                                                    column.keyName,
                                                    conflict,
                                                    column.disabled
                                                )}
                                                value={
                                                    conflict[
                                                    column
                                                        .keyName
                                                    ]
                                                }
                                            >
                                                {props.codeMaster?.[column.SELECT_KEY].map(
                                                    (data: any) => (
                                                        <MenuItem
                                                            key={
                                                                data.code_value
                                                            }
                                                            value={
                                                                data.code_value
                                                            }
                                                        >
                                                            {
                                                                data.code_display
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                            </Box>
                        )
                    )}
                </Box>
            ))}
        </>
    )
}

export default ConflictListDetails