import { createSlice } from "@reduxjs/toolkit";
import {
    initTrafficList,
    initTrafficDeleteParamList
} from "../data/type";

//Sliceの作成
export const trafficVolumeSlice = createSlice({
    name: "trafficVolume",
    initialState: {
        obj: [],
        updateList: initTrafficList,
        deleteList: initTrafficList,
        deleteParamterList: initTrafficDeleteParamList
    },
    //toolKitを利用した値変更処理
    reducers: {
        setTrafficVolume: (state, action) => {
            state.obj = action.payload;
        },
        setReduxReloadTrafficVolume: (state, action) => {
            const reduxReloadTrafficVolume: any = [];
            action.payload.map((row: any) => {
                reduxReloadTrafficVolume.push({
                    plan_area_id: row.plan_area_id.toString(),
                    branch: row.branch.toString(),
                    traffic_type: row.traffic_type.toString(),
                    traffic_type_seq: row.traffic_type_seq.toString(),
                    create_date: row.create_date.toString(),
                    update_date: row.update_date.toString(),
                    delete_flag: row.delete_flag.toString(),
                    counter_uuid: row.counter_uuid.toString(),
                    count_date: row.count_date.toString(),
                    count_time: row.count_time.toString(),
                    front_traffic_jam_time: row.front_traffic_jam_time.toString(),
                    side_a_traffic_jam_time: row.side_a_traffic_jam_time.toString(),
                    count_type: row.count_type.toString(),
                    car_front_standard: (Number(row.car_front_total) - (Number(row.car_front_light) + Number(row.car_front_large))),
                    car_front_light: Number(row.car_front_light),
                    car_front_large: Number(row.car_front_large),
                    car_side_a_standard: (Number(row.car_side_a_total) - (Number(row.car_side_a_light) + Number(row.car_side_a_large))),
                    car_side_a_light: Number(row.car_side_a_light),
                    car_side_a_large: Number(row.car_side_a_large),
                    car_side_b_standard: (Number(row.car_side_b_total) - (Number(row.car_side_b_light) + Number(row.car_side_b_large))),
                    car_side_b_light: Number(row.car_side_b_light),
                    car_side_b_large: Number(row.car_side_b_large),
                    car_back_standard: (Number(row.car_back_total) - (Number(row.car_back_light) + Number(row.car_back_large))),
                    car_back_light: Number(row.car_back_light),
                    car_back_large: Number(row.car_back_large),
                    people_5m_in_male_65over: Number(row.people_5m_in_male_65over),
                    people_5m_in_female_65over: Number(row.people_5m_in_female_65over),
                    people_5m_in_male_other: Number(row.people_5m_in_male_other),
                    people_5m_in_female_other: Number(row.people_5m_in_female_other),
                    people_5m_in_student: Number(row.people_5m_in_student),
                    people_5m_in_bicycle: Number(row.people_5m_in_bicycle),
                    people_5m_out_male_65over: Number(row.people_5m_out_male_65over),
                    people_5m_out_female_65over: Number(row.people_5m_out_female_65over),
                    people_5m_out_male_other: Number(row.people_5m_out_male_other),
                    people_5m_out_female_other: Number(row.people_5m_out_female_other),
                    people_5m_out_student: Number(row.people_5m_out_student),
                    people_5m_out_bicycle: Number(row.people_5m_out_bicycle),
                    //以下4つの項目は通行量テーブルにない
                    car_front_total: Number(row.car_front_total),
                    car_side_a_total: Number(row.car_side_a_total),
                    car_side_b_total: Number(row.car_side_b_total),
                    car_back_total: Number(row.car_back_total),
                });
            });
            state.obj = reduxReloadTrafficVolume;
        },
        // 通行量　調査日、調査時間のReduxを更新 5m内外の調査書日と調査時間を同一するため
        setReduxReloadDateTimeTrafficVolume: (state, action) => {
            const reduxReloadTrafficVolume: any = [];
            state.updateList.map((row: any) => {
                if (row.traffic_type.toString() === action.payload.traffic_type.toString() &&
                    row.traffic_type_seq.toString() === action.payload.traffic_type_seq.toString()
                ) {
                    reduxReloadTrafficVolume.push({
                        plan_area_id: action.payload.plan_area_id.toString(),
                        branch: action.payload.branch.toString(),
                        traffic_type: action.payload.traffic_type.toString(),
                        traffic_type_seq: action.payload.traffic_type_seq.toString(),
                        create_date: action.payload.create_date.toString(),
                        update_date: action.payload.update_date.toString(),
                        delete_flag: action.payload.delete_flag.toString(),
                        counter_uuid: action.payload.counter_uuid.toString(),
                        count_date: action.payload.count_date.toString(),
                        count_time: action.payload.count_time.toString(),
                        front_traffic_jam_time: action.payload.front_traffic_jam_time.toString(),
                        side_a_traffic_jam_time: action.payload.side_a_traffic_jam_time.toString(),
                        count_type: action.payload.count_type.toString(),
                        car_front_standard: (Number(action.payload.car_front_total) - (Number(action.payload.car_front_light) + Number(action.payload.car_front_large))),
                        car_front_light: Number(action.payload.car_front_light),
                        car_front_large: Number(action.payload.car_front_large),
                        car_side_a_standard: (Number(action.payload.car_side_a_total) - (Number(action.payload.car_side_a_light) + Number(action.payload.car_side_a_large))),
                        car_side_a_light: Number(action.payload.car_side_a_light),
                        car_side_a_large: Number(action.payload.car_side_a_large),
                        car_side_b_standard: (Number(action.payload.car_side_b_total) - (Number(action.payload.car_side_b_light) + Number(action.payload.car_side_b_large))),
                        car_side_b_light: Number(action.payload.car_side_b_light),
                        car_side_b_large: Number(action.payload.car_side_b_large),
                        car_back_standard: (Number(action.payload.car_back_total) - (Number(action.payload.car_back_light) + Number(action.payload.car_back_large))),
                        car_back_light: Number(action.payload.car_back_light),
                        car_back_large: Number(action.payload.car_back_large),
                        people_5m_in_male_65over: Number(action.payload.people_5m_in_male_65over),
                        people_5m_in_female_65over: Number(action.payload.people_5m_in_female_65over),
                        people_5m_in_male_other: Number(action.payload.people_5m_in_male_other),
                        people_5m_in_female_other: Number(action.payload.people_5m_in_female_other),
                        people_5m_in_student: Number(action.payload.people_5m_in_student),
                        people_5m_in_bicycle: Number(action.payload.people_5m_in_bicycle),
                        people_5m_out_male_65over: Number(action.payload.people_5m_out_male_65over),
                        people_5m_out_female_65over: Number(action.payload.people_5m_out_female_65over),
                        people_5m_out_male_other: Number(action.payload.people_5m_out_male_other),
                        people_5m_out_female_other: Number(action.payload.people_5m_out_female_other),
                        people_5m_out_student: Number(action.payload.people_5m_out_student),
                        people_5m_out_bicycle: Number(action.payload.people_5m_out_bicycle),
                        //以下4つの項目は通行量テーブルにない
                        car_front_total: Number(action.payload.car_front_total),
                        car_side_a_total: Number(action.payload.car_side_a_total),
                        car_side_b_total: Number(action.payload.car_side_b_total),
                        car_back_total: Number(action.payload.car_back_total),
                    });
                } else {
                    reduxReloadTrafficVolume.push({
                        plan_area_id: row.plan_area_id.toString(),
                        branch: row.branch.toString(),
                        traffic_type: row.traffic_type.toString(),
                        traffic_type_seq: row.traffic_type_seq.toString(),
                        create_date: row.create_date.toString(),
                        update_date: row.update_date.toString(),
                        delete_flag: row.delete_flag.toString(),
                        counter_uuid: row.counter_uuid.toString(),
                        count_date: row.count_date.toString(),
                        count_time: row.count_time.toString(),
                        front_traffic_jam_time: row.front_traffic_jam_time.toString(),
                        side_a_traffic_jam_time: row.side_a_traffic_jam_time.toString(),
                        count_type: row.count_type.toString(),
                        car_front_standard: (Number(row.car_front_total) - (Number(row.car_front_light) + Number(row.car_front_large))),
                        car_front_light: Number(row.car_front_light),
                        car_front_large: Number(row.car_front_large),
                        car_side_a_standard: (Number(row.car_side_a_total) - (Number(row.car_side_a_light) + Number(row.car_side_a_large))),
                        car_side_a_light: Number(row.car_side_a_light),
                        car_side_a_large: Number(row.car_side_a_large),
                        car_side_b_standard: (Number(row.car_side_b_total) - (Number(row.car_side_b_light) + Number(row.car_side_b_large))),
                        car_side_b_light: Number(row.car_side_b_light),
                        car_side_b_large: Number(row.car_side_b_large),
                        car_back_standard: (Number(row.car_back_total) - (Number(row.car_back_light) + Number(row.car_back_large))),
                        car_back_light: Number(row.car_back_light),
                        car_back_large: Number(row.car_back_large),
                        people_5m_in_male_65over: Number(row.people_5m_in_male_65over),
                        people_5m_in_female_65over: Number(row.people_5m_in_female_65over),
                        people_5m_in_male_other: Number(row.people_5m_in_male_other),
                        people_5m_in_female_other: Number(row.people_5m_in_female_other),
                        people_5m_in_student: Number(row.people_5m_in_student),
                        people_5m_in_bicycle: Number(row.people_5m_in_bicycle),
                        people_5m_out_male_65over: Number(row.people_5m_out_male_65over),
                        people_5m_out_female_65over: Number(row.people_5m_out_female_65over),
                        people_5m_out_male_other: Number(row.people_5m_out_male_other),
                        people_5m_out_female_other: Number(row.people_5m_out_female_other),
                        people_5m_out_student: Number(row.people_5m_out_student),
                        people_5m_out_bicycle: Number(row.people_5m_out_bicycle),
                        //以下4つの項目は通行量テーブルにない
                        car_front_total: Number(row.car_front_total),
                        car_side_a_total: Number(row.car_side_a_total),
                        car_side_b_total: Number(row.car_side_b_total),
                        car_back_total: Number(row.car_back_total),
                    });
                }
            });
            state.obj = reduxReloadTrafficVolume;
        },
        //通行量 車通行量 人通行量 更新削除用
        setTrafficVolumeUpdateDelete: (state, action) => {
            state.updateList = action.payload;
        },
        //通行量 車通行量 人通行量 更新
        setTrafficVolumeUpdate: (state, action) => {
            const updateTraffic = {
                plan_area_id: action.payload.plan_area_id.toString(),
                branch: action.payload.branch.toString(),
                traffic_type: action.payload.traffic_type.toString(),
                traffic_type_seq: action.payload.traffic_type_seq.toString(),
                create_date: action.payload.create_date.toString(),
                update_date: action.payload.update_date.toString(),
                delete_flag: action.payload.delete_flag.toString(),
                counter_uuid: action.payload.counter_uuid.toString(),
                count_date: action.payload.count_date.toString(),
                count_time: action.payload.count_time.toString(),
                front_traffic_jam_time: action.payload.front_traffic_jam_time.toString(),
                side_a_traffic_jam_time: action.payload.side_a_traffic_jam_time.toString(),
                count_type: action.payload.count_type.toString(),
                car_front_standard: (Number(action.payload.car_front_total) - (Number(action.payload.car_front_light) + Number(action.payload.car_front_large))).toString(),
                car_front_light: action.payload.car_front_light.toString(),
                car_front_large: action.payload.car_front_large.toString(),
                car_side_a_standard: (Number(action.payload.car_side_a_total) - (Number(action.payload.car_side_a_light) + Number(action.payload.car_side_a_large))).toString(),
                car_side_a_light: action.payload.car_side_a_light.toString(),
                car_side_a_large: action.payload.car_side_a_large.toString(),
                car_side_b_standard: (Number(action.payload.car_side_b_total) - (Number(action.payload.car_side_b_light) + Number(action.payload.car_side_b_large))).toString(),
                car_side_b_light: action.payload.car_side_b_light.toString(),
                car_side_b_large: action.payload.car_side_b_large.toString(),
                car_back_standard: (Number(action.payload.car_back_total) - (Number(action.payload.car_back_light) + Number(action.payload.car_back_large))).toString(),
                car_back_light: action.payload.car_back_light.toString(),
                car_back_large: action.payload.car_back_large.toString(),
                people_5m_in_male_65over: action.payload.people_5m_in_male_65over.toString(),
                people_5m_in_female_65over: action.payload.people_5m_in_female_65over.toString(),
                people_5m_in_male_other: action.payload.people_5m_in_male_other.toString(),
                people_5m_in_female_other: action.payload.people_5m_in_female_other.toString(),
                people_5m_in_student: action.payload.people_5m_in_student.toString(),
                people_5m_in_bicycle: action.payload.people_5m_in_bicycle.toString(),
                people_5m_out_male_65over: action.payload.people_5m_out_male_65over.toString(),
                people_5m_out_female_65over: action.payload.people_5m_out_female_65over.toString(),
                people_5m_out_male_other: action.payload.people_5m_out_male_other.toString(),
                people_5m_out_female_other: action.payload.people_5m_out_female_other.toString(),
                people_5m_out_student: action.payload.people_5m_out_student.toString(),
                people_5m_out_bicycle: action.payload.people_5m_out_bicycle.toString(),
                //以下4つの項目は通行量テーブルにない
                car_front_total: action.payload.car_front_total.toString(),
                car_side_a_total: action.payload.car_side_a_total.toString(),
                car_side_b_total: action.payload.car_side_b_total.toString(),
                car_back_total: action.payload.car_back_total.toString(),
            };

            //traffic_typeとtraffic_type_seqが一致するのか確認
            const existingIndex = state.updateList.findIndex(
                (row) =>
                    row.traffic_type.toString() === updateTraffic.traffic_type.toString() &&
                    row.traffic_type_seq.toString() === updateTraffic.traffic_type_seq.toString()
            );

            if (existingIndex !== -1) {
                //既に存在する場合はリストを更新
                state.updateList[existingIndex] = updateTraffic;
            } else {
                //存在しなかればリストに新規追加
                state.updateList.push(updateTraffic);
            }
        },
        //通行量 車通量 人通行量から1つの要素を削除
        setTrafficVolumeSingleDelete: (state, action) => {
            //traffic_typeとtraffic_type_seqが一致する要素を削除
            state.updateList = state.updateList.filter(
                (row) =>
                    !(
                        row.traffic_type.toString() ===
                            action.payload.traffic_type.toString() &&
                        row.traffic_type_seq.toString() ===
                            action.payload.traffic_type_seq.toString()
                    )
            );
        },
        //通行量 車通量 人通行量からtraffic_typeを絞って要素を削除
        setTrafficVolumeDelete: (state, action) => {
            //traffic_typeが一致する要素を削除
            state.updateList = state.updateList.filter(
                (row) =>
                    !(
                        row.traffic_type.toString() === action.payload.toString()
                    )
            );
        },
        // 通行量 削除パラメータを追加
        setTrafficVolumeDeleteParam: (state, action) => {
            state.deleteParamterList.push(action.payload);
        },
        // 通行量 削除パラメータを初期化
        setTrafficVolumeClearDeleteParam: (state) => {
            state.deleteParamterList = initTrafficDeleteParamList;
        }
    },
});

export const {
    setTrafficVolume,
    setReduxReloadTrafficVolume,
    setReduxReloadDateTimeTrafficVolume,
    setTrafficVolumeUpdateDelete,
    setTrafficVolumeUpdate,
    setTrafficVolumeSingleDelete,
    setTrafficVolumeDelete,
    setTrafficVolumeDeleteParam,
    setTrafficVolumeClearDeleteParam
} = trafficVolumeSlice.actions;
export default trafficVolumeSlice.reducer;
